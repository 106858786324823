import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
  Stack,
  TextField,
} from "@mui/material";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import {payementStripe} from "../api/singleEvent";
import { useParams } from "react-router-dom";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function DialogPaye({
  open,
  setOpen,
  price,
  place,
  StopLoading,
  id
}) {
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    StopLoading()
    setOpen(false);
  };
  // const { id } = useParams();

  const payment = async () => {
    payementStripe(id)
      .then((res) => {
        console.log("The Url", res.data);
        window.location.replace(res.data.url);
      })
      .catch((err) => {
        console.log("Error paiementUrl", err);
      });
  };
  return (
    <>
      <Dialog
        onClose={handleClose}
        fullWidth={true}
        // aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="sm"
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Detail de reservation
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Stack direction={"column"} gap={2}>
            <Typography> Place reserver : {place}</Typography>
            <Typography> Montant total : {place * price} €</Typography>
            <Button
              type={"submit"}
              sx={{
                color: "white",
                bgcolor: "#ff4dd8",
                "&:hover": { bgcolor: "#d31644" },
                width: "fit-content",
              }}
              onClick={payment}
            >
              Payer
            </Button>
            <Typography color="warning">
              Les places reserver sont automatiquement annuller s'ils ne sont
              pas payer au bout de 24h
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Fermer</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
