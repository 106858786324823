// src/App.js
import React, { useEffect, useState } from "react";
import { Delete, Edit, CheckCircle } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Input,
} from "@mui/material";
import axios from "axios";
import { Margin } from "@mui/icons-material";
import "./categori.css";
import { CircularProgress } from "@mui/material";
const Categori = () => {
  const token = sessionStorage.getItem("token");
  const [load, setload] = useState(false);
  const [fermer, setferm] = useState(false);
  const [fermerc, setfermc] = useState(false);
  const messagexx = document.querySelector(".popx");
  const messagexxf = document.querySelector(".popxf");
  const [messagex, setmessagex] = useState("");
  const [messagexa, setmessagexa] = useState("");
  const [truee, settrue] = useState(false);
  const [falsee, setfalsee] = useState(false);
  const [categories, setCategories] = useState([]);
  const [name, setname] = useState("");
  const [description, setdescription] = useState("");
  const [namem, setnamem] = useState("");
  const [descriptionm, setdescriptionm] = useState("");
  const [idm, setidm] = useState("");
  const [imagem, setimg] = useState("");
  const fetchCathegory = async () => {
    try {
      const response = await axios.get("/categories");
      setCategories(response.data.category);
      // console.log(response.data.category);
    } catch (error) {
      console.error("Erreur de récupération des pays", error);
    }
  };
  useEffect(() => {
    fetchCathegory();
  }, []);
  const [valid, setvalid] = useState(false);
  const [idsup, setidsup] = useState();
  function handleDeletex(ideventx) {
    setvalid(true);
    setidsup(ideventx);
  }
  const handleDelete = async () => {
    const token = sessionStorage.getItem("token");
    setvalid(false);
    let ideventx = idsup;
    console.log(token);
    try {
      const res = await axios.delete(
        "https://www.api-macerise.masovia-madagascar.com/api/delete/category/" +
          ideventx,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(res);
      setname("");
      setdescription("");
      setmessagex("supprimé avec succès ");
      settrue(true);
      if (messagexx) {
        messagexx.style.display = "flex";
      }

      setTimeout(() => {
        if (messagexx) {
          messagexx.style.display = "none";
        }
        settrue(false);
      }, 6000);
    } catch (error) {
      console.error("Erreur lors de l'envoi des données à l'API:", error);
    }

    // Rafraîchir les événements après la suppression
    fetchCathegory();
  };
  const [selectedFilesplus, setSelectedFile] = useState([]);
  const [imagePreviewsplus, setImagePreview] = useState([]);
  let [image, setimage] = useState([]);
  let [imagez, setimagez] = useState(null);

  const handleFileChange = async (event) => {
    event.preventDefault();
    const id = sessionStorage.getItem("idsalon");
    setload(true);
    const file = event.target.files[0];

    if (file) {
      setSelectedFile(file);
      const fileURL = URL.createObjectURL(file);
      setImagePreview(fileURL); // Set the preview URL for the image

      // Préparez les données pour l'upload
      const images = new FormData();
      images.append("images", file); // 'image' doit correspondre à ce que l'API attend

      let token = sessionStorage.getItem("token");

      try {
        const response = await axios.post(
          `uploadImage_category/` + idm,
          images,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        console.log("Upload success:", response.data);
        // Peut-être mettre à jour l'état ici si nécessaire pour afficher la nouvelle image
        fetchCathegory();
        //setimg(images.name)
        setload(false);
        setmessagex("ajout avec succès ");
        settrue(true);
        if (messagexx) {
          messagexx.style.display = "flex";
        }

        setTimeout(() => {
          if (messagexx) {
            messagexx.style.display = "none";
          }
          settrue(false);
        }, 4000);
        setload(false);
      } catch (error) {
        console.error("Error uploading file:", error);
        // Gérer les erreurs de manière approprié
      }
    }
  };
  const [selectedFilesplusw, setSelectedFilesplus] = useState([]);
  const [imagePreviewsplusw, setImagePreviewsplus] = useState([]);

  const addcate = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    const file = image;
    // Ajoutez l'image et d'autres données nécessaires

    formData.append("name", name); // Ajoutez le nom
    formData.append("description", description); // Ajoutez la description

    // Préparer les données pour l'upload

    try {
      const response = await axios.post(
        "https://www.api-macerise.masovia-madagascar.com/api/add_category",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data", // Cela indique que vous envoyez des données de formulaire multipart
          },
        }
      );

      console.log("Upload success:", response);
      settrue(true);
      setferm(false);
      setname("");
      setdescription("");
      setmessagex("ajout avec succès");

      if (messagexx) {
        messagexx.style.display = "flex";
      }

      setTimeout(() => {
        if (messagexx) {
          messagexx.style.display = "none";
        }
        settrue(false);
      }, 4000);
    } catch (error) {
      console.error("Error uploading file:", error);
      // Gérer les erreurs de manière appropriée
      setmessagex("Erreur lors de l'ajout des images");
      settrue(true);
      if (messagexx) {
        messagexx.style.display = "flex";
      }

      setTimeout(() => {
        if (messagexx) {
          messagexx.style.display = "none";
        }
        settrue(false);
      }, 4000);
    }
    fetchCathegory();
    setname("");
    setdescription("");
  };
  ///////
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);

    try {
      const response = await axios.put(
        `https://www.api-macerise.masovia-madagascar.com/api/update/category/${idm}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`, // Ajoutez le token ici
          },
        }
      );
      console.log("Category updated successfully:", response.data);
    } catch (error) {
      console.error("Error updating category:", error);
    }
  };

  const shandleEdit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    const images = imagez;

    console.log(name, description, images);

    try {
      const response = await axios.put(
        "https://www.api-macerise.masovia-madagascar.com/api/update/category/" +
          idm,
        {
          name,
          description,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            //'Content-Type': 'multipart/form-data'
          },
        }
      );

      console.log("Upload success:", response);
      settrue(true);
      setferm(false);
      setfermc(false);
      setname("");
      setdescription("");
      setmessagex("modification avec succès");

      if (messagexx) {
        messagexx.style.display = "flex";
      }

      setTimeout(() => {
        if (messagexx) {
          messagexx.style.display = "none";
        }
        settrue(false);
      }, 4000);
    } catch (error) {
      console.error("Error uploading file:", error);
      setmessagex("Erreur lors de l'ajout ");
      settrue(true);
      if (messagexx) {
        messagexx.style.display = "flex";
      }

      setTimeout(() => {
        if (messagexx) {
          messagexx.style.display = "none";
        }
        settrue(false);
      }, 4000);
    }

    fetchCathegory();
  };

  /////////

  const handleEdit = async (id, nom, desc, image) => {
    setfermc(true);
    setname(nom);
    setdescription(desc);
    setidm(id);
    //console.log(typeof start, end)
    setimg(image);
  };

  return (
    <div className="categoriAdmin">
      {truee ? <div className="popx">{messagex}</div> : ""}
      {falsee ? <div className="popxf">{messagexa}</div> : ""}
      {load ? (
        <div className="loading">
          {" "}
          <CircularProgress
            sx={{
              position: "fixed",
              top: "100px",
              zIndex: "700",
            }}
          />
        </div>
      ) : (
        ""
      )}
      {fermer ? (
        <form /*onSubmit={handleSubmit}*/ className="form4mcategori">
          <div className="flexb">
            {" "}
            <h2>Ajouter Categorie</h2>
            <p className="fermercate" onClick={() => setferm(false)}>
              <CloseIcon />
            </p>
          </div>

          <hr />

          <TextField
            type="text"
            margin="dense"
            label="Entrer nom categori"
            fullWidth
            variant="outlined"
            value={name}
            onChange={(e) => setname(e.target.value)}
            required
          />

          <TextField
            label="description"
            multiline
            rows={4}
            variant="outlined"
            value={description}
            onChange={(e) => setdescription(e.target.value)}
            fullWidth
            margin="normal"
            required
          />

          <Button
            type="submit"
            variant="contained"
            className="buton"
            sx={{ background: "#F9194F", marginTop: "5px", width: "100%" }}
            onClick={addcate} // Appel de la fonction lors du clic
          >
            Enregistrer
          </Button>
        </form>
      ) : (
        ""
      )}
      {fermerc ? (
        <form onSubmit={shandleEdit} className="form4mcategori">
          <div className="flexb">
            {" "}
            <h2>Modifier Categori</h2>
            <p className="fermercate" onClick={() => setfermc(false)}>
              <CloseIcon />
            </p>
          </div>

          <hr />

          <TextField
            type="text"
            margin="dense"
            label="Entrer nom categori"
            fullWidth
            variant="outlined"
            value={name}
            onChange={(e) => setname(e.target.value)}
            required
          />

          <TextField
            label="description"
            multiline
            rows={10}
            variant="outlined"
            value={description}
            onChange={(e) => setdescription(e.target.value)}
            fullWidth
            margin="normal"
            required
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "5px",
            }}
          >
            <img
              style={{ width: "30px", height: "30px", borderRadius: "100%" }}
              src={`https://api-macerise.masovia-madagascar.com/storage/category_images/${imagem}`}
              alt=""
              // Remplacez par l'URL d'une image par défaut
            />
          </div>

          <label>
            <Input
              accept="image/*"
              id="file-upload"
              type="file"
              onChange={handleFileChange}
              sx={{ position: "absolute", width: "10px", opacity: "0" }}
            />

            <Button
              variant="contained"
              component="span"
              sx={{
                width: {
                  xs: "90vw",
                  sm: "calc(100vw - 299px)",
                  md: "calc(100vw - 299px)",
                },
                backgroundColor: "#F9194F",
                color: "white",
                "&:hover": {
                  backgroundColor: "#F9194F", // Couleur de fond lors du hover
                  color: "white", // Couleur du texte lors du hover
                },
              }}
            >
              <Typography variant="button" color="inherit">
                <AddIcon></AddIcon> image
              </Typography>
            </Button>
          </label>
          <Button
            type="submit"
            variant="contained"
            className="buton"
            sx={{ background: "#F9194F", marginTop: "5px", width: "100%" }}
            // Appel de la fonction lors du clic
          >
            Modifier
          </Button>
        </form>
      ) : (
        ""
      )}
      {valid ? (
        <div className="validation">
          <span className="spann">
            <p>Voulez-vous vraiment le supprimer? </p>
            <span>
              <button onClick={handleDelete}>OUI</button>
              <button onClick={() => setvalid(false)}>NON</button>
            </span>{" "}
          </span>{" "}
        </div>
      ) : (
        ""
      )}
      <div className="flexb">
        <h2>Listes des categoris</h2>
        <button onClick={() => setferm(true)} className="ajoutcategorie">
          {" "}
          <AddIcon></AddIcon> Ajouter
        </button>
      </div>

      <hr />
      <table className="categorii">
        <thead>
          <tr>
            <th>Nom</th>
            <th>Image</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {categories.length > 0
            ? categories.map((event) => (
                <tr key={event.id}>
                  <td>{event.name}</td>
                  <td>
                    <img
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "100%",
                      }}
                      src={`https://api-macerise.masovia-madagascar.com/storage/category_images/${event.images}`}
                      alt=""
                      // Remplacez par l'URL d'une image par défaut
                    />
                  </td>
                  <td>
                    {" "}
                    <IconButton
                      onClick={() =>
                        handleEdit(
                          event.id,
                          event.name,
                          event.description,
                          event.images
                        )
                      }
                    >
                      <Edit />{" "}
                    </IconButton>
                    <IconButton onClick={() => handleDeletex(event.id)}>
                      {" "}
                      <Delete />
                    </IconButton>
                  </td>
                </tr>
              ))
            : ""}
        </tbody>
      </table>
    </div>
  );
};

export default Categori;
