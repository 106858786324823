import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Box,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
export default function ModalPaye({ open, setOpen, unitPrice, places }) {
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"sm"}
        sx={{padding:"50px"}}
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const email = formJson.email;
            console.log(email);
            handleClose();
          },
        }}
      >
        <DialogTitle id="alert-dialog-title" sx={{pt:5}}>
          Detail de paiement
        </DialogTitle>
        <DialogContent sx={{ px: 5 }}>
          <DialogContentText id="alert-dialog-description">
            <TextField
              variant="outlined"
              color="primary"
              sx={{ mt: 2 }}
              label={"adress email"}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            ></TextField>
            <TextField
              variant="outlined"
              color="primary"
              sx={{ mt: 2 }}
              label={"numéro de carte de crédit"}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            ></TextField>
            <Grid
              container
              justifyContent={"space-between"}
              gridTemplateRows={"1fr 1fr"}
              gap={2}
            >
              <TextField
                variant="outlined"
                color="primary"
                sx={{ mt: 2, width: "48%" }}
                label={"date d'expiration"}
                InputLabelProps={{
                  shrink: true,
                }}
              ></TextField>
              <TextField
                variant="outlined"
                color="primary"
                sx={{ mt: 2, width: "48%" }}
                label={"CVV"}
                InputLabelProps={{
                  shrink: true,
                }}
              ></TextField>
            </Grid>
            <Stack pt={5} gap={2} direction={"column"} >
              <Stack justifyContent={"space-between"} direction={"row"}>
                <Typography>Achat</Typography> <Typography>{places} place{parseInt(places)>2?'s':''} </Typography>
              </Stack>
              <Stack justifyContent={"space-between"} direction={"row"}>
                <Typography>Prix unitaire</Typography> <Typography>{unitPrice} € </Typography>
              </Stack>
                <Divider></Divider>
              <Stack justifyContent={"space-between"} direction={"row"}>
                <Typography>Prix total</Typography> <Typography>{unitPrice*places} €</Typography>
              </Stack>
            </Stack>

          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{pb:5, px:5}}>
            <Button sx={{color:"#ff4dd8", borderColor:'#ff4dd8', '&:hover':{
              color:"#fff", bgcolor:'#ff4dd8', borderColor:"#ff4dd8"
            }}} variant="outlined" fullWidth>
              {" "}
              Effectuer le payement
            </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
