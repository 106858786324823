import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { Input } from "@mui/material";

export default function InputCell({ value }) {
  return (
    <>
      <TableCell align="center">
        {value}
      </TableCell>
    </>
  );
}
