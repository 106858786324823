// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fermm{
    position: absolute;
    right: 5px;
    color: red;
    top: 5px;
    height: 30px;
    width: 30px;
    border-radius: 100%;
    background-color: #8a757598;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    scale: 0.9;
}
.fermm:hover{
    scale: 1;
}
@media screen and (max-width:600px) {
.salonss table,
.salonss thead,
.salonss tbody,
.salonss th,
.salonss td,
.salonss tr {
    display: block;
}

.salonss th {
    position: absolute;
    top: -9999px;
    left: -9999px;

}

.salonss tr {
    margin-bottom: 10px;
}

.salonss td {
    text-align: right;
    position: relative;
    padding-left: 50%;
}

.salonss td::before {
    position: absolute;
    left: 0;
    width: 50%;
    padding-left: 10px;
    white-space: nowrap;
    text-align: left;
    font-weight: bold;

}

.salonss td:nth-of-type(1)::before {
    content: 'Nom :';
}

.salonss td:nth-of-type(2)::before {
    content: 'address :';
}
 
.salonss td:nth-of-type(3)::before {
  content: 'city :';
}

.salonss td:nth-of-type(4)::before {
    content: 'Actions :';
}
.salonss  {
    width: 90vw;
    border-collapse: collapse;
}
}`, "",{"version":3,"sources":["webpack://./src/component/Dashbord/salon/Salon.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,UAAU;IACV,UAAU;IACV,QAAQ;IACR,YAAY;IACZ,WAAW;IACX,mBAAmB;IACnB,2BAA2B;IAC3B,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;IACf,UAAU;AACd;AACA;IACI,QAAQ;AACZ;AACA;AACA;;;;;;IAMI,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,aAAa;;AAEjB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,OAAO;IACP,UAAU;IACV,kBAAkB;IAClB,mBAAmB;IACnB,gBAAgB;IAChB,iBAAiB;;AAErB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;IACI,oBAAoB;AACxB;AACA;IACI,WAAW;IACX,yBAAyB;AAC7B;AACA","sourcesContent":[".fermm{\n    position: absolute;\n    right: 5px;\n    color: red;\n    top: 5px;\n    height: 30px;\n    width: 30px;\n    border-radius: 100%;\n    background-color: #8a757598;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    cursor: pointer;\n    scale: 0.9;\n}\n.fermm:hover{\n    scale: 1;\n}\n@media screen and (max-width:600px) {\n.salonss table,\n.salonss thead,\n.salonss tbody,\n.salonss th,\n.salonss td,\n.salonss tr {\n    display: block;\n}\n\n.salonss th {\n    position: absolute;\n    top: -9999px;\n    left: -9999px;\n\n}\n\n.salonss tr {\n    margin-bottom: 10px;\n}\n\n.salonss td {\n    text-align: right;\n    position: relative;\n    padding-left: 50%;\n}\n\n.salonss td::before {\n    position: absolute;\n    left: 0;\n    width: 50%;\n    padding-left: 10px;\n    white-space: nowrap;\n    text-align: left;\n    font-weight: bold;\n\n}\n\n.salonss td:nth-of-type(1)::before {\n    content: 'Nom :';\n}\n\n.salonss td:nth-of-type(2)::before {\n    content: 'address :';\n}\n \n.salonss td:nth-of-type(3)::before {\n  content: 'city :';\n}\n\n.salonss td:nth-of-type(4)::before {\n    content: 'Actions :';\n}\n.salonss  {\n    width: 90vw;\n    border-collapse: collapse;\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
