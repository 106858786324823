// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/image/se-deconnecter.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../public/image/prestations-de-service.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../../public/image/calendrier.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../../../public/image/analyse-des-donnees.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("../../../public/image/reservation.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("../../../public/image/faq.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.power{
    width: 40px;
    height: 40px;
    margin-left: 20px;
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat;
    background-size: contain;
}
.list_rdv{
    width: 40px;
    height: 40px;
    margin-left: 20px;
    background: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) no-repeat;
    background-size: contain;
}
.calix{
    width: 40px;
    height: 40px;
    margin-left: 20px;
    background: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) no-repeat;
    background-size: contain;
}
.acc{
    width: 40px;
    height: 40px;
    margin-left: 20px;
    background: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) no-repeat;
    background-size: contain;  
}
.reservation{
    width: 40px;
    height: 40px;
    margin-left: 20px;
    background: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) no-repeat;
    background-size: contain;  
}
.faq{
    width: 40px;
    height: 40px;
    margin-left: 20px;
    background: url(${___CSS_LOADER_URL_REPLACEMENT_5___}) no-repeat;
    background-size: contain;  
}
.active .nav-text {
    font-weight:bold;
    font-size: 15px;
  }

`, "",{"version":3,"sources":["webpack://./src/component/Dashbord/Dashb.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,6DAAqE;IACrE,wBAAwB;AAC5B;AACA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,6DAA6E;IAC7E,wBAAwB;AAC5B;AACA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,6DAAiE;IACjE,wBAAwB;AAC5B;AACA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,6DAA0E;IAC1E,wBAAwB;AAC5B;AACA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,6DAAkE;IAClE,wBAAwB;AAC5B;AACA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,6DAA0D;IAC1D,wBAAwB;AAC5B;AACA;IACI,gBAAgB;IAChB,eAAe;EACjB","sourcesContent":[".power{\n    width: 40px;\n    height: 40px;\n    margin-left: 20px;\n    background: url('../../../public/image/se-deconnecter.png') no-repeat;\n    background-size: contain;\n}\n.list_rdv{\n    width: 40px;\n    height: 40px;\n    margin-left: 20px;\n    background: url('../../../public/image/prestations-de-service.png') no-repeat;\n    background-size: contain;\n}\n.calix{\n    width: 40px;\n    height: 40px;\n    margin-left: 20px;\n    background: url('../../../public/image/calendrier.png') no-repeat;\n    background-size: contain;\n}\n.acc{\n    width: 40px;\n    height: 40px;\n    margin-left: 20px;\n    background: url('../../../public/image/analyse-des-donnees.png') no-repeat;\n    background-size: contain;  \n}\n.reservation{\n    width: 40px;\n    height: 40px;\n    margin-left: 20px;\n    background: url('../../../public/image/reservation.png') no-repeat;\n    background-size: contain;  \n}\n.faq{\n    width: 40px;\n    height: 40px;\n    margin-left: 20px;\n    background: url('../../../public/image/faq.png') no-repeat;\n    background-size: contain;  \n}\n.active .nav-text {\n    font-weight:bold;\n    font-size: 15px;\n  }\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
