import React, { useEffect ,useRef} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Typography, Box, Container } from "@mui/material";
import { successPayement } from "../utils/payementApi";
import axios from "axios";

export default function SuccessEvent() {
  const navigate = useNavigate();
  const location = useLocation();
  const hasFetched = useRef(null);
  const searchParams = new URLSearchParams(location.search);
  const session_id = searchParams.get("session_id");
  const handleGoHome = () => {
    navigate("/dashboardPro"); // Redirige vers la page d'accueil
  }; 
 
  const succ = async () => {
    const token = sessionStorage.getItem('token');
    try {
      const response = await axios.get(`/event_payment/success/${session_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }, 
      });
      console.log('Réponse de succès:', response.data);
    } catch (error) {
      console.error('Erreur lors du succès:', error);
    }
  };

  useEffect(() => {
    if (session_id && hasFetched.current !== session_id) {
      // Marque cette session comme "appelée"
      hasFetched.current = session_id;
      succ();
    }
  }, [session_id]); 
  return (
    <Container maxWidth="sm" style={{ textAlign: "center" }}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Typography variant="h4" gutterBottom>
          Paiement réussi !
        </Typography>
        <Typography variant="body1" gutterBottom>
          Votre paiement a été traité avec succès. Merci pour votre achat.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/evenement")}
          sx={{
            backgroundColor: "#f9194f",
            "&:hover": { backgroundColor: "#d31644" },
            padding: "10px 20px",
            marginTop: "20px",
          }}
        >
          Retour à la list des evenements
        </Button>
      </Box>
    </Container>
  );
}
