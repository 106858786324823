import React, { useEffect } from 'react';
import MenuConnecter from '../outil/MenuConnecter';
import AuthConnect from '../outil/AuthConnect';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import Logo from '../outil/Logo';
import '../outil/Menu.css';

export default function NavPro() {

  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const windowWidth = window.innerWidth;

useEffect(()=>{
  if (windowWidth < 1000) {
    setIsMenuVisible(false)
  } else {
    setIsMenuVisible(true)
  }
},[])

  function toggleMenuVisibility() {
    setIsMenuVisible((prev) => !prev);
  }
  
  return (
    <header>
      <div className='simplemenu'>
        <Logo />
        {isMenuVisible && (
          <div className='menupro'>
            <MenuConnecter onClick={toggleMenuVisibility} />
            <AuthConnect setVisible={setIsMenuVisible} visible={isMenuVisible} />
          </div>
        )}
        <p className='fermermprox' onClick={toggleMenuVisibility}>
          {isMenuVisible ? <CloseIcon /> : <MenuIcon />}
        </p>
      </div>
    </header>
  );
}
