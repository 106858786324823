import React, { useState } from "react";
// import { useParams } from "react-router-dom";
import { Box, Button, Grid, Typography, Input, Dialog } from "@mui/material";
import axios from "axios";
import Login from "../Login/Login";

const Inscription5 = ({ data }) => {
  //   const{id} = useParams()
  const [selectedImages, setSelectedImages] = useState([]);
  const [previews, setPreviews] = useState([]);
  const [alert, setAlert] = useState(false);

  // Fonction pour gérer la sélection de plusieurs images
  const handleImageChange = (event) => {
    const files = Array.from(event.target.files); // Convertir en tableau
    setSelectedImages((prevImages) => [...prevImages, ...files]);

    // Créer des aperçus pour les nouvelles images sélectionnées
    const imagePreviews = files.map((file) => {
      const reader = new FileReader();
      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result); // Résoudre la promesse avec l'URL de l'image
        };
        reader.readAsDataURL(file);
      });
    });

    // Attendre que toutes les nouvelles images soient lues et les ajouter à l'état
    Promise.all(imagePreviews).then((newPreviews) => {
      setPreviews((prevPreviews) => [...prevPreviews, ...newPreviews]);
    });
  };

  //     console.log("Salon ID:", salonId);

  const handleUpload = async (e) => {
    e.preventDefault();
    const salonId = sessionStorage.getItem("salonId");

    if (!salonId) {
      console.log("Salon ID not found in sessionStorage.");
      return;
    }

    if (selectedImages.length > 0) {
      try {
        //const formData = new FormData(); // Créer une instance de FormData

        // Ajouter chaque fichier d'image à FormData sous le même nom 'images[]'
        // selectedImages.forEach((image) => {
        //   formData.append('images[]', image); // Utiliser 'images[]' comme clé
        // });

        // Envoyer la requête POST au backend avec les fichiers d'image
        const response = await axios.post(
          `/salons/${salonId}/add_images`,
          { image: selectedImages },
          {
            headers: {
              "Content-Type": "multipart/form-data", // Assurer l'envoi en tant que multipart/form-data
            },
          }
        );
        setAlert(true);
        data(1)
        console.log("Images uploaded successfully:", response.data);
      } catch (error) {
        console.log("Error uploading images:", error);
      }
    } else {
      console.log("No images selected.");
    }
  };

  return (
    <div
      className="inscription-container"
      style={{ width: "500px", position: "relative", top: "150px" }}
    >
      <Box sx={{ mb: 2 }}>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Photo du Salon
        </Typography>
        <Button variant="outlined" component="label" sx={{ mt: 2 }}>
          Ajouter Image
          <Input
            type="file"
            accept="image/*"
            // value={selectedImages}
            multiple
            onChange={handleImageChange}
            sx={{ display: "none" }}
          />
        </Button>
      </Box>

      {/* Aperçu des images */}
      {previews.length > 0 && (
        <Grid container spacing={2}>
          {previews.map((preview, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <img
                src={preview}
                alt={`Aperçu ${index}`}
                style={{
                  width: "100%",
                  height: "auto",
                  objectFit: "cover",
                  borderRadius: "8px",
                }}
              />
            </Grid>
          ))}
        </Grid>
      )}

      {/* Bouton de soumission */}
      <Button
        variant="contained"
        color="primary"
        onClick={handleUpload}
        disabled={selectedImages.length === 0}
        sx={{ mt: 2, background: "#F9194F" }}
      >
        Inscription salon
      </Button>
      {alert === true && (
        <Dialog open={alert} onClose={() => setAlert(false)}>
          {/* <DialogTitle>Vous n'êtes pas connecté</DialogTitle>
          <DialogContent>Veuillez vous connecter pour ajouter un commentaire.</DialogContent> */}
          <Login />
        </Dialog>
      )}
    </div>
  );
};

export default Inscription5;
