import { React, useRef, useState, useEffect } from "react";
import '../inscription/Inscription.css'
import { useNavigate } from "react-router-dom";
import { registerr } from "../../../authentification/Authentificationl";
import { role } from "../../../authentification/Authentificationl";
import { verifi_code } from "../../../authentification/Authentificationl";
import axios from "axios";
import { countries } from "../../../authentification/Authentificationl";
const Mot = ({ data }) => {
    const [email, setEmail] = useState('');
    const [load, setload] = useState(false);
    const [error, setError] = useState('');
    const [succ, setSucc] = useState('');
    const navigate = useNavigate();
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          const response = await axios.post("https://www.api-macerise.masovia-madagascar.com/api/forget-password"
            ,
            {
             email
            }
          );
          setSucc(response.data.message);
          setError('')
        } catch (err) {
          console.log(err.response.data.message)
         setError(err.response.data.message);
         setSucc('')
        }
      };
    function show() {
        data(1)
    }
    return (
        <>
            <div className="inscription-container">
                <div className="boxis-num-1">
                    <div className="boxis-num-1-1">Trouvez votre compte</div>
                    <div className="boxis-num-1-2">Entrez votre addresse e-mail.</div>
                </div>
                <div className="boxi-num-2">
                    <form onSubmit={handleSubmit}>
                        <input
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            type="email" className="boxis-num-2-1-S5" placeholder="Adresse email" />
                        <button className="boxi-num-3 btnload " type="submit">Envoyer</button>
                        <p className="error"> {error && <p>{error}</p>}</p>
                        <p className="succ"> {succ && <p>{succ}</p>}</p>
                    </form>
                </div>
                <div className="boxis-num-6">Vous avez déjà un compte ?<span className="boxis-style" onClick={show}> Se connecter </span>ou<span className="boxi-style" onClick={()=>data(2)}> S’inscrire</span></div>
            </div>
        </>
    )
}
export default Mot