import React, { useEffect } from "react";
import "./Home.css";
import Myprofil from "./Myprofil";
import { useState } from "react";
import Message from "./Message";
import Favoris from "./Favoris";
import Address from "./Address";
import Reservation from "./Reservation";
import HistoriquePaiement from "./HIistoriquePaiement";
import Securite from "./Securite";
import Parametre from "./Parametre";
import Footer from "./Footer";
import NavMain from "../Navigation/NavMain";
import Navclient from "../Navigation/Navclient";
import Service from "./Service";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import ReservationEvent from "./ReservationEvent";
import EventIcon from '@mui/icons-material/Event';
export default function Home() {
  let menus = document.querySelector(".left");
  let [val, setval] = useState(0);
  useEffect(() => {
    window.scroll({ top: 0 });
  }, []);
  const [afficherComposantA, setAfficherComposantA] = useState("profil");

  const windowWidth = window.innerWidth;

  if (windowWidth < 801) {
  } else {
  }
  const toggleComposantPro = () => {
    setAfficherComposantA("profil");
    if (windowWidth < 801) {
      if (menus) {
        menus.style.display = "none";
      }
      setval(0);
    } else {
    }
  };

  const toggleComposantfav = () => {
    setAfficherComposantA("favoris");
    if (windowWidth < 801) {
      if (menus) {
        menus.style.display = "none";
      }
      setval(0);
    } else {
    }
  };
  const toggleComposantMess = () => {
    setAfficherComposantA("message");
    if (windowWidth < 801) {
      if (menus) {
        menus.style.display = "none";
      }
      setval(0);
    } else {
    }
  };
  const toggleComposantReser = () => {
    setAfficherComposantA("reservation");
    if (windowWidth < 801) {
      if (menus) {
        menus.style.display = "none";
      }
      setval(0);
    } else {
    }
  };
  const toggleComposantReserEvent = () => {
    setAfficherComposantA("event");
    if (windowWidth < 801) {
      if (menus) {
        menus.style.display = "none";
      }
      setval(0);
    } else {
    }
  };
  const toggleComposantHist = () => {
    setAfficherComposantA("paiement");
    if (windowWidth < 801) {
      if (menus) {
        menus.style.display = "none";
      }
      setval(0);
    } else {
    }
  };
  const toggleComposantSecur = () => {
    setAfficherComposantA("securite");
    if (windowWidth < 801) {
      if (menus) {
        menus.style.display = "none";
      }
      setval(0);
    } else {
    }
  };
  const toggleComposantParam = () => {
    setAfficherComposantA("parametre");
    if (windowWidth < 801) {
      if (menus) {
        menus.style.display = "none";
      }
      setval(0);
    } else {
    }
  };
  const toggleComposantService = () => {
    setAfficherComposantA("service");
    if (windowWidth < 801) {
      if (menus) {
        menus.style.display = "none";
      }
      setval(0);
    } else {
    }
  };

  function showMe(e) {
    e.preventDefault();
    let menusx = document.querySelector(".left");

    if (menusx) {
      if (val == 0) {
        menusx.style.display = "block";
        setval(1);
      } else {
        menusx.style.display = "none";
        setval(0);
      }
    }
  }
  return (
    <>
      <Navclient />
      <div className="body">
        <span>
          <p className="color" onClick={(e) => showMe(e)}>
            <MenuIcon />
          </p>
          <div className="left">
            <p className="fermerm color">
              {" "}
              <CloseIcon onClick={(e) => showMe(e)} />
            </p>

            <p className="p">Mon compte</p>

            <ul>
              <li
                onClick={toggleComposantPro}
                className={afficherComposantA === "profil" ? "activePro" : ""}
              >
                <i className="ti-user"> </i> Profile
              </li>

              <li
                onClick={toggleComposantfav}
                className={afficherComposantA === "favoris" ? "activePro" : ""}
              >
                <i className="ti-heart"> </i> Vos favoris
              </li>
              <li
                onClick={toggleComposantMess}
                className={afficherComposantA === "message" ? "activePro" : ""}
              >
                <i className="ti-comments"> </i> Messages
              </li>
              <li
                onClick={toggleComposantReser}
                className={
                  afficherComposantA === "reservation" ? "activePro" : ""
                }
              >
                <i className="ti-calendar"></i> Vos réservations
              </li>

              <li
                onClick={toggleComposantReserEvent}
                className={
                  afficherComposantA === "event" ? "activePro" : ""
                }
              >
                <EventIcon style={{ marginRight: '8px' }} /> Evénements réservé
              </li>

              <li
                onClick={toggleComposantHist}
                className={afficherComposantA === "paiement" ? "activePro" : ""}
              >
                <i className="ti-time"> </i> Historique de paiements
              </li>
              <li
                onClick={toggleComposantSecur}
                className={afficherComposantA === "securite" ? "activePro" : ""}
              >
                <i className="ti-lock"> </i> Sécurité
              </li>

              {/* <li onClick={toggleComposantParam}
                className={afficherComposantA === 'parametre' ? 'activePro' : ''}
              ><i className="ti-settings"> </i> Paramètres</li> */}
            </ul>
          </div>
          {/* <button className="btn1">Se déconnecter</button> */}
        </span>

        {afficherComposantA === "profil" ? <Myprofil /> : ""}
        {afficherComposantA === "address" ? <Address /> : ""}
        {afficherComposantA === "favoris" ? <Favoris /> : ""}
        {afficherComposantA === "message" ? <Message /> : ""}
        {afficherComposantA === "reservation" ? <Reservation /> : ""}
        {afficherComposantA === "event" ? <ReservationEvent /> : ""}
        {afficherComposantA === "paiement" ? <HistoriquePaiement /> : ""}
        {afficherComposantA === "securite" ? <Securite /> : ""}
        {/* {afficherComposantA === 'parametre' ? <Parametre /> : ''} */}
        {afficherComposantA === "service" ? <Service /> : ""}
      </div>
      <Footer />
    </>
  );
}
