import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import DialogTitle from "@mui/material/DialogTitle";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Delete, Edit, CheckCircle } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import InputCell from "./component/cell";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddIcon from "@mui/icons-material/Add";
import DialogAjout from "./component/DialogAjout";
import DialogAjoutc from "./component/DialogAjoutc";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useEffect, useState } from "react";
import DialogDelete from "./component/dialogDelete";
import DialogDeletec from "./component/dialogDeletec";
import DialogEdit from "./component/DialogEdit";
import { listEmployer } from "../api/employer";
import { listEmployerc } from '../api/employer'
import { typographyTheme } from "../../event/utils/responsiveTypo";
import AvatarInputCell from "./component/avatarCell";
import { Toaster } from "sonner";

const headData = [
  // { id: "name", label: "Nom" },
  { id: "userInfo", label: "userInfo" },
  { id: "CIN", label: "CIN" },
  { id: "address", label: "Adresse" },
  { id: "ddn", label: "Date de naissance" },
  { id: "created_at", label: "date d'ajout", name: "created_at", type: "date" },
  { id: "actions", label: "Actions" },
];
const headDatac = [
  { id: "name", label: "Nom" },
  { id: "start_day", label: "Début" },
  { id: "end_day", label: "Fin" },
  { id: "nbr_jrs_total", label: "total jours" },
  { id: "actions", label: "Actions" },
];
export default function Employer() {
  const [OpenAdd, setOpenAdd] = useState(false);
  const [OpenAddc, setOpenAddc] = useState(false);
  const [OpenDel, setOpenDel] = useState(false);
  const [OpenDelc, setOpenDelc] = useState(false);
  const [OpenEdit, setOpenEdit] = useState(false);
  const [datas, setData] = useState([]);
  const [datasc, setDatac] = useState([]);
  const [itemDel, setItemDel] = useState({});
  const [itemDelc, setItemDelc] = useState({});
  const [itemEdit, setItemEdit] = useState({});
  const [submitChange, setSubmitChange] = useState(false);
  const [submitChangec, setSubmitChangec] = useState(false);
  const [yes, setyes] = useState(true);
  const [dataShow, setDataShow] = useState([]);
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const ids = sessionStorage.getItem("idsalon");
    listEmployer(ids)
      .then((res) => {
        setData(res.data["employees"]);
      })
      .catch((err) => console.log("erreur sur list emplyer", err));
  }, [submitChange]);
  useEffect(() => {
    const ids = sessionStorage.getItem("idsalon");
    const userId = sessionStorage.getItem("userId");
    listEmployerc(userId)
      .then((res) => {
        console.log(res.data)
        setDatac(res.data);
      })
      .catch((err) => console.log("erreur sur list emplyer", err));
  }, [submitChangec]);
  useEffect(() => {
    setDataShow(() =>
      datas.reduce((acc, init, index) => {
        const { name, lastname, created_at, ...rest } = init;
        acc.push({ ...rest, userInfo: `${name} ${lastname}`, created_at: new Date(created_at).toISOString().split('T')[0] });
        return acc;
      }, [])
    );
    console.log("DATA", datas);
  }, [datas]);

  const openDeleteModal = (id) => {
    setItemDel(() => datas.find((row) => row.id === id));
    setOpenDel(true);
  };
  const openDeleteModalc = (id) => {
  
    setItemDelc(() => datasc.conges.find((event) => event.id == id));
    setOpenDelc(true);
  };
  const openEditModal = (id) => {
    setItemEdit(() => datas.find((row, i) => row.id == id));
    setOpenEdit(true);
  };
  return (
    <Stack px={smDown ? 2 : 5} py={smDown ? 1 : 2}>
      <DialogTitle sx={{ m: 0, p: 2, color: { xs: '#F9194F', sm: 'black', md: 'black' }, fontSize: { xs: '15px', sm: '2rem', md: '2rem' }, marginTop: { xs: '45px', sm: '30px', md: '30px' } }} id="customized-dialog-title">
        Employés
      </DialogTitle>
      <Box>
        <Button
          variant="contained"
          sx={{

            width: { xs: '90vw', sm: 'auto', md: 'auto' },
            alignSelf: "flex-start",
            textTransform: "capitalize",
            bgcolor: "#F9194F",
            "&:hover": { bgcolor: "#000" },
          }}
          onClick={() => setOpenAdd(true)}
          startIcon={<AddCircleIcon />}
        >
          Ajouter un employer{" "}
        </Button>
        <Button
          variant="contained"
          sx={{

            marginTop: { xs: '10px', sm: '0px', md: '0px' },
            width: { xs: '90vw', sm: 'auto', md: 'auto' },
            marginLeft: { xs: '0px', sm: '10px', md: '10px' },
            alignSelf: "flex-start",
            textTransform: "capitalize",
            bgcolor: "#F9194F",
            "&:hover": { bgcolor: "#000" },
          }}
          onClick={() => setOpenAddc(true)}
          startIcon={<AddCircleIcon />}
        >
          Ajouter  Congés employés{" "}
        </Button>
      </Box>
      <Box>
        <Button
          variant="contained"
          sx={{
            marginTop: { xs: '10px', sm: '10px', md: '10px' },
            width: { xs: '43vw', sm: 'auto', md: 'auto' },
            alignSelf: "flex-start",
            textTransform: "capitalize",
            bgcolor: "#F9194F",
            "&:hover": { bgcolor: "#000" },
          }}
          onClick={() => setyes(true)}

        >
          Listes employés{" "}
        </Button>
        <Button
          variant="contained"
          sx={{

            marginTop: { xs: '10px', sm: '10px', md: '10px' },
            width: { xs: '43vw', sm: 'auto', md: 'auto' },
            marginLeft: { xs: '4vw', sm: '10px', md: '10px' },
            alignSelf: "flex-start",
            textTransform: "capitalize",
            bgcolor: "#F9194F",
            "&:hover": { bgcolor: "#000" },
          }}
          onClick={() => setyes(false)}

        >
          Listes  Congés {" "}
        </Button>
      </Box>
      {
        yes ? <>  <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          listes Employés
        </DialogTitle>
          <Paper sx={{ mt: 2, width: "100%", height: "auto", overflow: "" }}>
            <TableContainer sx={{}}>
              <Table
                stickyHeader
                size={mdDown ? "small" : "large"}
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow
                    sx={{ "& td, & th": { borderLeft: 0, borderRight: 0 } }}
                  >
                    {headData.map((column) =>
                      column.id === "userInfo" ? (
                        <TableCell></TableCell>
                      ) : (
                        <TableCell
                          key={column.id}
                          align={"center"}
                          sx={typographyTheme.body2}
                        // style={{ maxWidth: column.maxWidth }}
                        >
                          {column.label}
                        </TableCell>
                      )
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataShow.length > 0 &&
                    dataShow.map((row) => {
                      return (
                        <TableRow
                          hover
                          key={row.id}
                          sx={{ "& td, & th": { borderLeft: 0, borderRight: 0 } }}
                        >
                          {headData.map((column) => {
                            const value = row[column.id];
                            if (column.id === "actions")
                              return (
                                <TableCell align="center">
                                  <Stack
                                    direction={"row"}
                                    justifyContent="space-around"
                                    key={column}
                                    border={0}
                                  >
                                    <IconButton
                                      sx={{ "&:hover": { color: "red" } }}
                                    >
                                      <EditOutlinedIcon
                                        fontSize={mdDown ? "small" : "medium"}
                                        onClick={() => openEditModal(row.id)}
                                      />
                                    </IconButton>
                                    <IconButton
                                      fontSize={mdDown ? "small" : "medium"}
                                      onClick={() => openDeleteModal(row.id)}
                                      sx={{ "&:hover": { color: "red" } }}
                                    >
                                      <DeleteOutlineOutlinedIcon />
                                    </IconButton>
                                  </Stack>
                                </TableCell>
                              );
                            return (
                              <TableCell
                                align="center"
                                sx={{ ...typographyTheme.body2 }}
                              >
                                {column.id === "userInfo" ? (
                                  <Stack
                                    gap={2}
                                    direction={"row"}
                                    alignItems="center"
                                  >
                                    <AvatarInputCell
                                      data={row || null}
                                      setSubmit={setSubmitChange}
                                    />
                                    <span>{value}</span>
                                  </Stack>
                                ) : (
                                  <span>{value}</span>
                                )}
                                {/* <Stack
                               gap={2}
                               direction={"row"}
                               alignItems="center"
                             > */}
                                {/* {column.id === "userInfo" && (
                                 <AvatarInputCell data={row || null} />
                               )}{" "} */}

                                {/* </Stack> */}
                                {/* {column.id === "userInfo" ? <AvatarInputCell/>:''}{value} */}
                              </TableCell>
                            );
                          })}
                          {/* <ActionCell /> */}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper></> : <> <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            listes congés Employés
          </DialogTitle>
          <Paper sx={{ mt: 2, width: "100%", height: "auto", overflow: "" }}>
            <TableContainer sx={{}}>
              <Table
                stickyHeader
                size={mdDown ? "small" : "large"}
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow
                    sx={{ "& td, & th": { borderLeft: 0, borderRight: 0 } }}
                  >
                    {headDatac.map((column) =>
                      column.id === "userInfo" ? (
                        <TableCell></TableCell>
                      ) : (
                        <TableCell
                          key={column.id}
                          align={"center"}
                          sx={typographyTheme.body2}
                        // style={{ maxWidth: column.maxWidth }}
                        >
                          {column.label}
                        </TableCell>
                      )
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>


                  {datasc ?
                    datasc.conges.map((event) => (
                      <tr key={event.id}>
                        {datas && datas.map((em) => (
                          em.id == event.employe_id ? <td>{em.name}</td> : ""
                        ))}
                        <td>{event.start_day}</td>


                        <td>{event.end_day}</td>
                        <td>{event.nbr_jrs_total}</td>
                        <TableCell align="center" sx={{ padding: '0px', height: '50px', minWidth: { xs: '18vw', sm: '19vw', md: '19vw' }, maxWidth: { xs: '18vw', sm: '19vw', md: '19vw' } }}>

                          <IconButton
                            fontSize={mdDown ? "small" : "medium"}
                            onClick={() => openDeleteModalc(event.id)}
                            sx={{ "&:hover": { color: "red" } }}
                          >
                            <DeleteOutlineOutlinedIcon />
                          </IconButton>
                        </TableCell>
                      </tr>
                    ))
                    : ""}

                </TableBody>
              </Table>
            </TableContainer>
          </Paper></>
      }

      <DialogAjout
        open={OpenAdd}
        setOpen={setOpenAdd}
        setData={setData}
        setSubmitChange={setSubmitChange}
      />
      <DialogAjoutc
        open={OpenAddc}
        setOpen={setOpenAddc}
        setData={setDatac}
        setSubmitChange={setSubmitChangec}
      />
      <DialogDelete
        open={OpenDel}
        setOpen={setOpenDel}
        setData={setData}
        itemDel={itemDel}
        setSubmitChange={setSubmitChange}
      />
      <DialogDeletec
        open={OpenDelc}
        setOpen={setOpenDelc}
        setData={setDatac}
        itemDel={itemDelc}
        setSubmitChange={setSubmitChangec}
      />
      <DialogEdit
        open={OpenEdit}
        setOpen={setOpenEdit}
        setData={setData}
        data={itemEdit}
        setSubmitChange={setSubmitChange}
      />
      <Toaster position="top-center" richColors />
    </Stack>
  );
}
