import { React, useState, useEffect } from "react";
import "./TrouveProf.css";
import axios from "axios";
export default function TrouverProf() {
  const [villes, setVilles] = useState([]); // Initialisation avec un tableau vide

  const fetchvilles = async () => {
    try {
      const response = await axios.get("/villes/salons");
     // console.log("Données récupérées:", response.data.data); // Vérifiez ce qui est reçu ici
      if (Array.isArray(response.data.data)) {
        setVilles(response.data.data);
      } else {
        console.log("Les données reçues ne sont pas un tableau");
      }
    } catch (error) {
      console.log("Erreur de récupération:", error);
    }
  };

  useEffect(() => {
    fetchvilles();
  }, []);

  return (
      <div className="trouveProf">
      <h4>Trouvez votre Professionnel en Ile de France</h4>
      <div className="prof">
        {Array.isArray(villes) && villes.length > 0 ? (
          villes.map((vi, index) => (
            <div className="one" key={index}>
              <div className="city-info">
                <i className="ti-angle-right"></i>
                {vi.city} ({vi.number_of_salon})
              </div>
            </div>
          ))
        ) : (
        ''
        )}
      </div>
    </div>
  );
}
