import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { listEmployer } from "../../api/employer";
import {
    Box,
    FormControl,
    Input,
    InputAdornment,
    InputLabel,
    Stack,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { useParams } from "react-router-dom";
import { useFieldArray, useForm } from "react-hook-form";
import { ajoutEmployer } from "../../api/employer";
import { ajoutEmployerc } from "../../api/employer";
import { toast } from "sonner";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

export default function DialogAjoutc({
    open,
    setOpen,
    setData,
    setSubmitChange,
}) {
    const [datas, setDatac] = useState([]);
    const [employer, setamployer] = useState([]);
    const [employe_id, setselectc] = useState('');
    const [nbr_jrs_total, setnbr_jrs_total] = useState('');
    const listEmployerc = async () => {
        const ids = sessionStorage.getItem("idsalon");
        listEmployer(ids)
            .then((res) => {
                setDatac(res.data["employees"]);
            })
            .catch((err) => console.log("erreur sur list emplyer", err));
    }
    useEffect(() => {
        listEmployerc()
    }, []);
    const {
        handleSubmit,
        register,
        reset,
        getValues,
        formState: { errors },
    } = useForm({
        defaultValues: {},
    });
    const fields = [
        { label: "Début", name: "start_day", type: "date" },
        { label: "Fin", name: "end_day", type: "date" },

    ];
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        reset();
    };
    const onSubmit = (data) => {
        const date1 = new Date(data.start_day);
        const date2 = new Date(data.end_day);

        const differenceInTime = date2 - date1;
        const differenceInDays = differenceInTime / (1000 * 3600 * 24);
        console.log(differenceInDays);
        const ids = sessionStorage.getItem("idsalon");
        const dataEdited = { ...data, employe_id, nbr_jrs_total: differenceInDays };
        console.log("Real Data", dataEdited);
        ajoutEmployerc(dataEdited)
            .then((res) => {
                console.log(res.data);
                setSubmitChange((v) => !v);
                handleClose()
                toast.success("Ajout Congé avec succes", {
                    position: "top-center",
                });
                handleClose();
            })
            .catch((err) => {

                if (err.response) {
                    if (err.response.data.error) {
                        toast.error(err.response.data.error, {
                            position: "top-center",
                        });
                    } else if (err.response.data.message) {
                        toast.error(err.response.data.message, {
                            position: "top-center",
                        });
                    }

                } else {
                    toast.error("Erreur lors de l'ajout ", {
                        position: "top-center",
                    });
                }
                console.log("Erreur", err);
            });
        setData((prev) => [...prev, data]);
    };
    return (
        <>
            <Dialog
                onClose={handleClose}
                fullWidth={true}
                // aria-labelledby="customized-dialog-title"
                open={open}
                maxWidth="sm"
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Ajouter congé
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={(theme) => ({
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Stack
                        component={"form"}
                        onSubmit={handleSubmit(onSubmit)}
                        direction={"column"}
                        gap={2}
                    >

                        <FormControl fullWidth>
                            <InputLabel id="category-select-label">Employé</InputLabel>
                            <Select
                                required
                                labelId="category-select-label"
                                id="category-select"
                                value={employe_id}
                                onChange={(e) => {
                                    setselectc(e.target.value);

                                }}
                                label="Category"

                            // onChange={handleChange}
                            >

                                {datas && datas.map((cat) => (
                                    <MenuItem key={cat.id} value={cat.id}>
                                        {cat.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {fields.map((field, i) => {
                            return (

                                <TextField
                                    required
                                    key={i}
                                    {...register(field.name, { rules: "this field is required" })}
                                    label={field.label}
                                    InputLabelProps={{
                                        shrink: field.name === "start_day" | field.name === "end_day" ? true : undefined,
                                    }}
                                    type={field.type || null}
                                />
                            );
                        })}

                        <Button
                            sx={{
                                color: "white",
                                bgcolor: "#F9194F",
                                "&:hover": {
                                    bgcolor: "#000",
                                    color: "#fff",
                                    border: "none",
                                },

                                // width: "fit-content",
                            }}
                            type="submit"
                        >
                            Valider
                        </Button>
                    </Stack>
                </DialogContent>
                {/* <DialogActions>
          <Button onClick={handleClose}>Fermer</Button>
        </DialogActions> */}
            </Dialog>
        </>
    );
}
