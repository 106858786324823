import React, { useState,useEffect,useRef  } from "react";
// import { useNavigate } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Typography, Box, Container } from "@mui/material";
import axios from "axios";


const Success = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const sessionId = searchParams.get("session_id");
  const hasFetched = useRef(null); // Ref pour surveiller chaque sessionId
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate("/dashboardPro"); // Redirige vers la page d'accueil
  };
// test  
  const succ = async () => {
    const token = sessionStorage.getItem('token');
    try {
      const response = await axios.get(`/payment_tarif/success/${sessionId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }, 
      });
      console.log('Réponse de succès:', response.data);
    } catch (error) {
      console.error('Erreur lors du succès:', error);
    }
  };

  useEffect(() => {
    if (sessionId && hasFetched.current !== sessionId) {
      // Marque cette session comme "appelée"
      hasFetched.current = sessionId;
      succ();
    }
  }, [sessionId]);  
 
  return (
    <Container maxWidth="sm" style={{ textAlign: "center" }}>
      <Box 
        display="flex" 
        flexDirection="column" 
        justifyContent="center" 
        alignItems="center" 
        height="100vh"
      >
        <Typography variant="h4" gutterBottom>
          Paiement réussi !
        </Typography>
        <Typography variant="body1" gutterBottom>
          Votre paiement a été traité avec succès. Merci pour votre achat.
        </Typography>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={handleGoHome}
          sx={{
            backgroundColor: "#f9194f",
            '&:hover': { backgroundColor: "#d31644" },
            padding: "10px 20px",
            marginTop: "20px"
          }}
        >
          Retour à l'accueil
        </Button>
      </Box>
    </Container>
  );
};

export default Success;
