import React, { useState } from 'react'
import './QuestionFre.css'
import axios from 'axios'
import { useEffect } from 'react'
export default function QuestionFre() {
  const [val, setshow] = useState(-1)

  const [truee, settrue] = useState(false)
  const [validation, setvalidation] = useState(false)
  const [falsee, setfalsee] = useState(false)
  const [messagex, setmessagex] = useState('');
  const [messagexa, setmessagexa] = useState('');
  const [events, setEvents] = useState([]);
  const userId = sessionStorage.getItem('userId');
  const token = sessionStorage.getItem('token')
  const messagexx = document.querySelector('.popx');
  const messagexxf = document.querySelector('.popxf');

  const fetchEvents = async () => {
    try {
      const response = await axios.get('https://www.api-macerise.masovia-madagascar.com/api/questions');
      setEvents(response.data.questions);
      // console.log(response.data.questions)
    } catch (error) {
      console.log(error)
    }

  };

  useEffect(() => {
    fetchEvents();
  }, []);


  let temp = val;
  function showRepose(valc) {

    if (valc === temp) {
      setshow(-1)
    } else {
      setshow(valc)
    }


  }


  return (
    <div className='questions'>
      <h4>Les questions fréquents</h4>
      <div className='quest'>
        {events ? events.map((event, key) => (
          <div className='centr' key={key}>
            <div className='plusdetaille' onClick={() => showRepose(event.id)}>
              <div className='question'  ><p>{event.content}</p> {val !== event.id ? <i className='ti-arrow-right'></i> : <i className='ti-arrow-down'></i>}  </div>

              {val !== event.id ? null :
                <div className='reponse'>
                  {event.responses.map((event, key) => (

                    <p key={key}> {event.content}</p>
                  ))}


                </div>}


            </div>
            <hr></hr>
          </div>
        )) : ''}






      </div>
    </div>
  )
}
