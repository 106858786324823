import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import './Secondary.css';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";


const SecondaryButton = ({ children, size, sx ,tel,affiche}) => {
 
function gomess(){

  CloseModal()
  affiche(true)

}
  const [contact,setContact] = useState(false)
 
const openModal =()=>{

  if(sessionStorage.getItem('token')){
    setContact(true)
   }else{
     const login=document.querySelector('.login')
     login.style.display='flex';
   }
}
const CloseModal =()=>{
  setContact(false)
}
useEffect(()=>{
 // console.log(tel)
},[])
  return (
    <>
      <Button
      elevation={0}
      variant="contained"
      size={size}
      sx={{
        ...sx,
        background: "#ebebeb",
        color: "#555555",
        fontWeight: "bold",
        boxShadow: "none",
        "&:hover": {
          backgroundColor: "#ebebeb",
          boxShadow: "none",
        },
        textTransform: "none",
      }}
      onClick={openModal}
    >
      {children}
      
    </Button>

   <Dialog open={contact} onClose={CloseModal}>
  <DialogTitle sx={{textAlign:'center',backgroundColor:'#fb174e',color:'white'}}>Contact l'agent</DialogTitle>
  <DialogContent>
    <Typography sx={{textAlign:'center'}} variant="h6">
    {tel}  
    </Typography>
    <Button onClick={()=>gomess()}> Envoyer un message</Button>
  </DialogContent>
 </Dialog>


    </>
  );
};
export default SecondaryButton;
