import { useEffect, useState } from "react";
import ServiceMass from "../outil/serviMass/ServiceMass";
import Admasseur from "./Admasseur";
import Calendrier from "./Calendrier/Calendrier";
import Evenent from "./Evenent";
import Questions from "./FAQ/Questions";
import Masse from "./Masse/Masse";
import NewProfil from "./newProfil";
import ProfilMass from "./proflMasseur/ProfilMass";
import Salon from "./salon/Salon";
import TableRdv from "./TableRdv/TableRdv";
import { useNavigate } from "react-router-dom";
import { logout } from "../../authentification/Authentificationl";
import Abonnement from "./abonnement/Abonnement";
import Categori from "./categori/Categori";
import Transaction from "./Transaction/Transaction";

export default function AffichageDash({affichage, setAffichage}) {
  const role = sessionStorage.getItem("Role");
  // console.log(role);

  const [modal, setModal] = useState(false);
  const [token, Settoken] = useState(false);
  const navigate = useNavigate();

  function deconecter() {
    setModal(true);
  }
  const handledeconnection = () => {
    logout();
    navigate("/tarif");
  };
  const handleCloseDel = () => {
    setModal(false);
  }

  useEffect(() => {
    if (role === "admin") {
      setAffichage("dashboard");
    } else {
      setAffichage("site");
    }
  }, []);
  return (
    <>
      {affichage === "dashboard" && <NewProfil />}
      {affichage === "abonnement" && <Abonnement />}
      {affichage === "catégories" && <Categori />}
      {affichage === "service" && <ServiceMass />}
      {affichage === "événements" && <Evenent />}
      {affichage === "questions" && <Questions />}
      {affichage === "masseurs" && <Admasseur />}
      {affichage === "message" && <Masse />}
      {affichage === "profil" && <ProfilMass />}
      {affichage === "site" && <Salon />}
      {affichage === "transaction" && <Transaction />}
    </>
  );
}
