import React, { useState } from "react";
import "./Etablissement.css";
import { NavLink } from "react-router-dom";
import { salons } from "../../authentification/Authentificationl";
import { useEffect } from "react";
import { Rating } from '@mui/material';
export default function Etablissement({ fsalonId, loginv }) {
  function idsal(val) {
    fsalonId(val);
  }

  const [salonliste, setsalon] = useState([]);

  const handlsalon = async () => {
    try {
      const userData = await salons();
      setsalon(userData);
      //   console.log('salon listee', userData);
      // console.log(userData);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    //  console.log(sessionStorage.getItem('token'),sessionStorage.getItem('token'))

    handlsalon();
  }, []);

  return (
    <div className="Etablissement">
      <h4>Etablissement à la une</h4>
      <div className="EtablissementImg">
        {salonliste.salons
          ? salonliste.salons.map((salon, key) => {
            const start = salon.notes_count / 15
            return (
              <div className="card" key={salon.id}>
                <img src={`https://api-macerise.masovia-madagascar.com/storage/salon_images/${salon.images}`} alt='image' ></img>
                <div className="description">
                  <p className="titre">{salon.name}</p>
                  <div className="avis" style={{ display: 'flex', flexDirection: 'row' ,paddingLeft:'10px'}}>
                    <Rating
                      sx={{ fontSize: '20px' }}
                      name="salon-rating"
                      value={start} // This is the rating value (e.g., 3.5 or 4)
                      readOnly // This makes the stars non-editable, but visible as "active"
                      precision={0.5} // Allows for half-star ratings
                    />
                    <p style={{ fontSize: '15px', marginLeft: '5px' }}>{salon.notes_count} avis</p>
                  </div>
                  <p className="payse">{salon.city}</p>
                  <p className="expert">Chez votre expert</p>
                  <hr></hr>

                  <NavLink
                    className="plusdetaille"
                    to={`/detailsalon/${salon.id}`}
                  >
                    <p>Je veux en savoir plus</p>
                    <i className="ti-angle-down"></i>
                  </NavLink>
                </div>
              </div>
            );
          })
          : ""}
      </div>
    </div>
  );
}
