import React, { useEffect, useState } from "react";
import "./inscription4.css";
import { TextField, Typography, Card, CardContent, Grid } from "@mui/material";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import axios from "axios";

const Inscription4 = ({data}) => {
  const [checked, setChecked] = useState({});
  const [timeData, setTimeData] = useState({});
  const [day, setDay] = useState([]);

  const handleChange = (event, dayId) => {
    setChecked({
      ...checked,
      [dayId]: event.target.checked,
    });
  };

  const handleTimeChange = (event, dayId, type) => {
    setTimeData((prevTimeData) => ({
      ...prevTimeData,
      [dayId]: {
        ...prevTimeData[dayId],
        [type]: event.target.value,
      },
    }));
  };

  const fetchDay = async () => {
    try {
      const response = await axios.get("/days");
      setDay(response.data.days);
    } catch {
      console.log("erreur");
    }
  };

  useEffect(() => {
    fetchDay();
  }, []);
   const handleSubmit = async (e) => {
    e.preventDefault();

    const token = sessionStorage.getItem("token");
    const salonId = sessionStorage.getItem("salonId"); // Récupérer l'ID du salon

    if (!salonId) {
      console.error("Le champ salonId est requis.");
      return;
    }

    const formatTime = (time) => {
      const [hours, minutes] = time.split(":");
      return `${hours.padStart(2, "0")}:${minutes.padStart(2, "0")}:00`;
    };

    const dayIds = Object.keys(timeData);
    const startTimes = dayIds.map((dayId) =>
      formatTime(timeData[dayId]?.start || "00:00")
    );
    const endTimes = dayIds.map((dayId) =>
      formatTime(timeData[dayId]?.end || "00:00")
    );

    const statuses = dayIds.some((dayId) => checked[dayId]) ? "ouvert" : "fermé";

    if (dayIds.length === 0) {
      console.error("Le champ day_id est requis.");
      return;
    }

    try {
      const response = await axios.post("/add_time_in_register_salon", {
        salon_id: salonId,
        day_id: dayIds,
        start_time: startTimes,
        end_time: endTimes,
        status: statuses, // Doit être un tableau de chaînes de caractères
      });
      data(5)
      console.log(response.data);
    } catch (error) {
      console.error(
        "Erreur",
        error.response ? error.response.data : error.message
      );
    }
    // data(5)
  };

  return (
    <div className="inscription-container" style={{width:'500px'}}>
      <div className="boxis-num-1">
        <div className="boxis-num-1-1">Bienvenue sur Macerise</div>
        <div className="boxis-num-1-2">Horaire de la semaine.</div>
      </div>
      <div className="boxi-num-2">
        <form onSubmit={handleSubmit}>
          <Grid container spacing={1} sx={{ marginTop: "-10px" }}>
            {day.map((item) => (
              <Grid item xs={12} sm={6} md={4} key={item.id}>
                <Card>
                  <CardContent>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name={item.name}
                            checked={checked[item.id] || false}
                            onChange={(e) => handleChange(e, item.id)}
                            sx={{ color: "black" }}
                          />
                        }
                        label={
                          <Typography sx={{ color: "black" }}>
                            {item.name}
                          </Typography>
                        }
                      />
                    </FormGroup>

                    <Typography
                      variant="subtitle1"
                      sx={{ color: "black", marginTop: "-10px" }}
                    >
                      Ouverture
                    </Typography>
                    <TextField
                      id={`open-time-${item.name}`}
                      label="Heure"
                      type="time"
                      defaultValue="00:00"
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ step: 300 }}
                      onChange={(e) => handleTimeChange(e, item.id, "start")}
                      sx={{ width: "120px", color: "black", marginTop: "10px" }}
                    />

                    <Typography
                      variant="subtitle1"
                      sx={{ color: "black", marginTop: "10px" }}
                    >
                      Fermeture
                    </Typography>
                    <TextField
                      id={`close-time-${item.name}`}
                      label="Heure"
                      type="time"
                      defaultValue="00:00"
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ step: 300 }}
                      onChange={(e) => handleTimeChange(e, item.id, "end")}
                      sx={{ width: "120px", color: "black", marginTop: "10px" }}
                    />
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>

          <button className="boxi-num-3 btnload" type="submit">
            Suivant
          </button>
          <p className="error"></p>
          <p className="succ"></p>
        </form>
      </div>
      <div className="boxis-num-4">...</div>
      <div className="boxis-num-5">...</div>
      <div className="boxis-num-6" style={{ marginTop: "-120px" }}>
        Vous avez déjà un compte ?{" "}
        <span className="boxis-style"> Se connecter</span>
      </div>
    </div>
  );
};

export default Inscription4;
