import { React, useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    Button,
    IconButton,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
} from "@mui/material";
import './RsetMot.css'
import axios from "axios";
const ResetMot = ({ data }) => {
    const location = useLocation();

    // Utiliser URLSearchParams pour obtenir le token à partir de la query string
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');

    const [password, setpassword] = useState('');
    const [password_confirmation, setpassword_confirmation] = useState('');
    const [load, setload] = useState(false);
    const [error, setError] = useState('');
    const [succ, setSucc] = useState('');
    const [messa, setmessa] = useState('');
    const navigate = useNavigate();
    const handleSubmit = async (e) => {
        e.preventDefault();
        // let token = sessionStorage.getItem('token')
        try {
            const response = await axios.post("https://www.api-macerise.masovia-madagascar.com/api/reset-password"
                ,
                {
                    token, password, password_confirmation
                }

            );
            setSucc(response.data.message);

            setError('')
            // navigate('/')

            const login = document.querySelector('.login')


            setTimeout(() => {
                if (login) {
                    login.scroll({ top: 0 }); // Faire défiler vers le bas
                    login.style.display = 'flex';

                    window.scroll({ top: 0 });
                }
            }, 2000);

        } catch (err) {
            console.log(err)

            setSucc('')
        }
    };
    function show() {
        data(1)
    }
    function fermer() {
        navigate('/')
    }
    useEffect(() => {
        if (password !== password_confirmation) {
            setmessa('password different de password_confirmation')
        } else {
            setmessa('')
        }
    })
    function naviger(){
         navigate('/log')
         
   
       
    }
    return (
        <>
            <div className="centermot">

                <div className="boxis-num-1">
                    <p className='fermerx' onClick={() => fermer()}> x</p>
                    <div className="boxis-num-1-1">Réinitialiser le mot de passe</div>

                </div>
                <div className="boxi-num-2">
                    <form onSubmit={handleSubmit}>

                        <TextField
                            type="password"
                            margin="dense"
                            label="Nouveau mot de passe"
                            fullWidth
                            variant="outlined"
                            value={password}
                            onChange={(e) => setpassword(e.target.value)}
                        />
                        <TextField
                            type="password"
                            margin="dense"
                            label="Confirmation mot de passe"
                            fullWidth
                            variant="outlined"
                            value={password_confirmation}
                            onChange={(e) => setpassword_confirmation(e.target.value)}
                        />

                        <button
                            disabled={password !== password_confirmation}
                            className="boxi-num-3 btnload"
                            type="submit"
                            style={{ opacity: password !== password_confirmation ? 0.5 : 1 }}

                        >
                            {password !== password_confirmation ? '...' : "Modifier"}
                        </button>

                        <div style={{ color: 'red', padding: '5px' }}>{messa}</div>
                        <p className="error"> {error && <p>{error}</p>}</p>
                        <p className="succ"> {succ && <p>{succ} </p>}</p>
                        {succ &&  <p style={{color:'blue',textDecoration:'underline'}} onClick={()=>naviger()}>SE CONNECTER </p>}
                    </form>
                </div>

            </div>
        </>
    )
}
export default ResetMot