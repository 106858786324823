import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const ReservationEvent = () => {
  const [data, setData] = useState([]);

  const fetchData = async () => {
    const token = sessionStorage.getItem("token");
    try {
      const response = await axios.get(`/appointevent_list`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setData(response.data.appointments);
      console.log("test:", response.data);
    } catch {
      console.log("erreur");
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Container sx={{marginLeft:{xs:'-10px',sm:'0px',md:'0px'}}}>
    <TableContainer component={Paper} style={{ overflowX: 'auto' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Lieu</TableCell>
            <TableCell>Événement</TableCell>
            <TableCell>Place</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data &&
            data.map((index) => (
              <TableRow key={index.id}>
                <TableCell>{index.evenment.date}</TableCell>
                <TableCell>{index.evenment.lieu}</TableCell>
                <TableCell>{index.evenment.name}</TableCell>
                <TableCell>{index.nombre_place_reserve}</TableCell>
               
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  </Container>
  );
};
export default ReservationEvent;
