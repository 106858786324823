import axios from "axios";

/**
 *
 * @param {*} session_id id du reservation
 * @returns
 */
export async function successPayement(session_id) {
    const token = sessionStorage.getItem("token");
    try {
      const response = await axios.get(
        `https://api-macerise.masovia-madagascar.com/api/event_payment/success/${session_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
  