import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  Container,
  DialogContent,
  Dialog,
  DialogTitle,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import axios from "axios";
import { format } from "date-fns";

const CongeSal = () => {
  const [modalAjout, setModalAjout] = useState(false);
  const [formData, setFormData] = useState({
    startDate: null,
    endDate: null,
    reason: "",
  });
  const [data, setData] = useState([]);
  const [modalDel,setModalDel] = useState(false)
  const [congeId,setCongeId]= useState('')

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleDateChange = (field, date) => {
    const formattedDate = format(date, "yyyy-MM-dd"); // Convertit en format YYYY-MM-DD
    setFormData((prevData) => ({
      ...prevData,
      [field]: formattedDate,
    }));
  };

  const handleSubmit = async (e) => {
    const token = sessionStorage.getItem("token");
    const salonId = sessionStorage.getItem("idsalon");
    const start_day = formData.startDate;
    const end_day = formData.endDate;
    const motifs = formData.reason;
    e.preventDefault();
    try {
      const response = await axios.post(
        `add_conge_salon/${salonId}`,
        {
          start_day,
          end_day,
          motifs,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setFormData({ startDate: null, endDate: null, reason: "" });
      setModalAjout(false)
      fetchData();
    } catch {
      console.log("erreur d envoie");
    }
    // Logique de soumission du formulaire
    console.log("Form Data Submitted: ", formData);
  };
  const handelAjout = () => {
    setModalAjout(true);
  };
  const handelAjoutClose = () => {
    setModalAjout(false);
  };
  // Recuperation des donners

  const fetchData = async () => {
    const salonId = sessionStorage.getItem("idsalon");
    try {
      const response = await axios.get(`/salon/conge/${salonId}`);
      console.log("les conger:", response.data.conges);
      setData(response.data.conges);
    } catch {
      console.log("erreur de recuperation");
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  // Suppression des donner 
  const handelDel=(id)=>{
    setModalDel(true)
    setCongeId(id)
  }

  const handelSup = async(e) =>{
  e.preventDefault()
  const token = sessionStorage.getItem('token')
  try{
    const response = await axios.delete(`/delete/conge_salon/${congeId}`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
    })
    setModalDel(false)
    fetchData();
  }
  catch{
    console.log('erreur de suppression')
  }
  }
  return (
    <Container sx={{ marginTop: "100px" }}>
      <Box>
        <Button variant="contained" onClick={handelAjout} sx={{background:'#f9194f'}}>
          Ajouter
        </Button>
      </Box>
      <Table sx={{marginTop:'10px'}}>
        <TableHead>
          <TableRow>
            <TableCell>Date de début</TableCell>
            <TableCell>Date de Fin</TableCell>
            <TableCell>Motifs</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        {data && data.map((index, key) => (
          <>
            <TableBody>
              <TableRow key={key.id}>
                <TableCell>{index.start_day}</TableCell>
                <TableCell>{index.end_day}</TableCell>
                <TableCell>{index.motifs}</TableCell>
                <TableCell sx={{ display: "flex", justifyContent: "center" }}>
                  <Button variant="contained" onClick={()=>{handelDel(index.id)}} sx={{background:'#f9194f'}}>Supprimer</Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </>
        ))}
      </Table>
      <Dialog open={modalAjout} onClose={handelAjoutClose}>
        <DialogTitle>
          <Typography variant="h5" align="center" gutterBottom>
            Demande de Congé de Salon
          </Typography>
          <Typography
            sx={{
              marginLeft: "480px",
              marginTop: "-45px",
              fontSize: "25px",
              cursor: "pointer",
            }}
            onClick={handelAjoutClose}
          >
            x
          </Typography>
        </DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box
              p={3}
              maxWidth={500}
              mx="auto"
              mt={5}
              boxShadow={3}
              borderRadius={2}
            >
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <DatePicker
                      label="Date de début"
                      value={formData.startDate}
                      inputFormat="dd/MM/yy"
                      onChange={(date) => handleDateChange("startDate", date)}
                      renderInput={(params) => (
                        <TextField {...params} fullWidth />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DatePicker
                      label="Date de fin"
                      value={formData.endDate}
                      inputFormat="dd/MM/yy" // Affichage en dd/MM/yy
                      onChange={(date) => handleDateChange("endDate", date)}
                      renderInput={(params) => (
                        <TextField {...params} fullWidth />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Motif"
                      name="reason"
                      value={formData.reason}
                      onChange={handleInputChange}
                      fullWidth
                      multiline
                      rows={4}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                    >
                      Soumettre
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </LocalizationProvider>
        </DialogContent>
      </Dialog>
      <Dialog open={modalDel} onClose={()=>{setModalDel(false)}}>
            <DialogTitle>
              <Typography>Êtes-vous sûr de vouloir supprimer ?</Typography>
            </DialogTitle>
            <DialogContent>
                  <Button variant="contained" onClick={handelSup} sx={{background:'#f9194f'}}>OUI</Button>
                  <Button variant="contained" onClick={()=>{setModalDel(false)}} sx={{background:'#f9194f',marginLeft:'140px'}}>NON</Button>
            </DialogContent>

      </Dialog>
    </Container>
  );
};

export default CongeSal;
