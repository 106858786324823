import React, { useEffect, useState } from "react";
import { format } from 'date-fns';
import './call.css'
import NavMain from "../Navigation/NavMain";
import Footer from "../outil/Footer";
import { useRef } from "react";
import axios from "axios";
import { useParams } from 'react-router-dom';
import moment from 'moment';
const Call = ({ service, showcalendrier }) => {


    const { id } = useParams(); // Récupérer l'ID de l'URL

    let starttime = 0;
    let endtime = 0;
    /*  let dateinit = new Date();*/
    //   const initdate=  `${dateinit.getFullYear()}-${dateinit.getMonth()+1}-${dateinit.getDate()}`;
    //console.log(initdate)
    const date = useRef('')
    const start_time = useRef('')



    /* let dateString = date;
     let dater = moment(dateString, 'YYYY-M-D');
     useEffect(() => {
        date.current=dateinit;
     },[])
 */

    // Fonction pour formater la chaîne de caractères
    function formatTime(time) {
        const [hours, minutes] = time.split(':');
        // Ajoute un zéro devant les heures ou les minutes si nécessaire
        const formattedHours = String(hours).padStart(2, '0');
        const formattedMinutes = String(minutes).padStart(2, '0');
        return `${formattedHours}:${formattedMinutes}`;
    };
    /*   let g= formatTime(start_time.current);
   useEffect(() => {
      
       start_time.current=g

   })*/
    const [mess, setmess] = useState('');
    const [errkely, seterr] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedOptione, setSelectedOptione] = useState('');
    const [selectprice, setprice] = useState('');
    const [servid, setserid] = useState('');
    const [selectdur, setdur] = useState('');
    const [idemployer, setidemployer] = useState('');
    // Step 2: Create a function to handle changes to the radio button selection
    const handleChange = (event) => {

        setSelectedOption(event.target.value);
    };
    const handleChangee = (event) => {

        setidemployer(event.target.value);
    };
    const cli = (event) => {

        setprice(event.target.className);
        setdur(event.target.role);
        setserid(event.target.id);
    };
    const [tabprix, settabprix] = useState('')
    const [tabduration, settabdur] = useState('')

    function goo(val1) {
        alert(val1)
    }

    const [client_id, setidsalon] = useState('')
    const [servicem, setservmass] = useState([])
    const [agenda, setagenda] = useState([])
    const [employe, setemploye] = useState([])
    const [masseur_id, setmasseur_id] = useState('')


    let jourpartt = useRef(1);


    const employer = async () => {
        const token = sessionStorage.getItem('token')
        //   let id_users = sessionStorage.getItem('userId');
        try {
            const response = await axios.get(`https://www.api-macerise.masovia-madagascar.com/api/salon/${id}/employees`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            //  setsalonid(response.data.salons);

            setemploye(response.data.employees)
            console.log(response.data.employees)

        } catch (err) {
            console.log(err)

        }
    };

    useEffect(() => {
        employer()
    }, [])
    const fagenda = async () => {
        const token = sessionStorage.getItem('token')
        //   let id_users = sessionStorage.getItem('userId');
        try {
            const response = await axios.get('https://www.api-macerise.masovia-madagascar.com/api/days/salons/' + id, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            //  setsalonid(response.data.salons);

            setagenda(response.data.data)
            //   console.log(response.data.data)

        } catch (err) {
            console.log(err)

        }
    };

    const message = document.querySelector('.mess');
    const errorr = document.querySelector('.err');

    const fetchreservation = async () => {
        console.log(date.current)
        console.log(start_time)

        const token = sessionStorage.getItem('token')
        if (date.current !== null & date.current !== 'undefined' & date.current !== ' ' & start_time.current !== null & start_time.current !== 'undefined' & start_time.current !== '' & servid !== null & servid !== 'undefined' & servid !== '' & idemployer !== null & idemployer !== 'undefined' & idemployer !== '') {


            try {
                const response = await axios.post(
                    `https://www.api-macerise.masovia-madagascar.com/api/services/${servid}/${id}/${idemployer}/appointments`,
                    {
                        date: date.current,
                        start_time: start_time.current,
                    },
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        },
                    }
                );


                if (errorr) {
                    errorr.style.display = 'none';
                }

                setmess(response.data.service.name + ' date:  ' + response.data[0].date + ' heurs:' + response.data[0].start_time);
                showcalendrier(false, response.data.service.name + ' date:  ' + response.data[0].date + ' heurs:' + response.data[0].start_time)
                if (message) {
                    message.style.display = 'flex';
                }
                setTimeout(() => {


                    if (message) {
                        message.style.display = 'none';
                    }



                }, 9000);



            } catch (err) {
                //  console.log(err.response.data.error );



                //  setmess(err.response.data.error );

                if (message) {
                    message.style.display = 'none';
                }
                if (err.response.data.error) {
                    seterr(err.response.data.error)
                }
                seterr('pas de connexion')
                if (errorr) {
                    errorr.style.display = 'flex';
                }


                setTimeout(() => {



                    if (errorr) {
                        errorr.style.display = 'none';
                    }


                }, 3500);
            }

        } else if (date.current === null || date.current === undefined || date.current === '') {
            if (message) {
                message.style.display = 'none';
            }
            if (errorr) {
                errorr.style.display = 'flex';
            }

            seterr("choisir une date ?")


            setTimeout(() => {



                if (errorr) {
                    errorr.style.display = 'none';
                }


            }, 3500);

        } else if (start_time.current === null || start_time.current === undefined || start_time.current === '') {

            if (message) {
                message.style.display = 'none';
            }
            if (errorr) {
                errorr.style.display = 'flex';
            }

            seterr(" choisir une heure ?")


            setTimeout(() => {



                if (errorr) {
                    errorr.style.display = 'none';
                }


            }, 3500);
        } else if (selectedOption === null || selectedOption === 'undefined' || selectedOption === '') {
            if (message) {
                message.style.display = 'none';
            }
            if (errorr) {
                errorr.style.display = 'flex';
            }

            seterr(" choisir un service")


            setTimeout(() => {



                if (errorr) {
                    errorr.style.display = 'none';
                }


            }, 3500);
        }
        else if (idemployer === null || idemployer === 'undefined' || idemployer === '') {
            if (message) {
                message.style.display = 'none';
            }
            if (errorr) {
                errorr.style.display = 'flex';
            }

            seterr(" choisir un(e) employé(e)")


            setTimeout(() => {



                if (errorr) {
                    errorr.style.display = 'none';
                }


            }, 3500);
        }
        console.log(selectedOption)
        console.log(servid)

    };
    const fetchServices = async () => {
        try {
            const response = await axios.get(`https://www.api-macerise.masovia-madagascar.com/api/salon/${id}/all_services`
            );

            setservmass(response.data)
            //    console.log(response)

        } catch (err) {
            //  console.log(err)
        }
    };

    function go() {

        //  console.log(start_time);
        //   console.log(date)
    }
    useEffect(() => {

        setidsalon(sessionStorage.getItem('iduser'))
        //    let  day=  formatDate("Aujourd'hui 26 oct 2024");
        //   console.log(day)    
        ////////////////////////////////////////////////////////////
    }, []);

    useEffect(() => {
        if (service) {
            setmasseur_id(service.salon.id)
        }


        fetchServices()
        fagenda()

        /*   if (agenda.length > 0) {
   
   
               starttime = parseInt(agenda[0].start_time.substr(1, 2))
               //   console.log(starttime)
   
   
               endtime = parseInt(agenda[0].end_time.substr(0, 2))
           }*/

    }, [])



    /*
     
     function matin() {
         setjourpartt(1)
     }
     function aprem() {
         setjourpartt(2)
     }
     function soire() {
         setjourpartt(3)
     }*/




    const cardss = useRef(null);
    const btnleft = useRef(null);
    const btnrightt = useRef(null);
    let nombreimg = 150;
    let scrol = nombreimg - 5;
    let i = 0;



    useEffect(() => {

        if (scrol > 0) {
            btnrightt.current.style.cursor = 'pointer'

        }


    }, []);


    function tranRight() {
        if (scrol > 0) {
            btnleft.current.style.opacity = '1'
            btnleft.current.style.zIndex = '1'
            btnleft.current.style.cursor = 'pointer'
            btnrightt.current.style.cursor = 'pointer'
            btnrightt.current.style.zIndex = '1'
            i = i + 50;
            cardss.current.style.transform = 'translateX(-' + i + 'vw)';

            scrol--

        }
        if (scrol === 0) {
            btnrightt.current.style.opacity = '0.1'
            btnrightt.current.style.cursor = 'default'
            btnrightt.current.style.zIndex = '0'
        }

    } function tranLeft() {
        if (i > 0) {
            btnrightt.current.style.cursor = 'pointer'
            btnrightt.current.style.opacity = '1'
            btnrightt.current.style.zIndex = '1'
            i -= 50
            cardss.current.style.transform = 'translateX(-' + i + 'vw)'
        }
        if (i === 0) {
            scrol = nombreimg - 5;
            btnleft.current.style.opacity = '0.1'
            btnleft.current.style.cursor = 'default'
            btnleft.current.style.zIndex = '1'
        }
    }


    //date d'aujour d'huit
    let datee = new Date();
    //jours
    let jourx = datee.getDate();
    //mois
    let mois = datee.getMonth();


    let annee = datee.getFullYear();
    /* if (mois > 1) {
         //année
         annee = datee.getFullYear() + 1;
     }*/

    //semain
    let datejjx = datee.getDay() - 1;
    //tableau mois
    const Mois = ["Janvier", "Février", "mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Decembre"];
    //tableau semanis
    const moisb = {
        Janvier: 1,
        Février: 2,
        mars: 3,
        Avril: 4,
        Mai: 5,
        Juin: 6,
        Juillet: 7,
        Août: 8,
        Septembre: 9,
        Octobre: 10,
        Novembre: 11,
        Decembre: 12,
    };


    const Jsemain = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"];
    const Jsemainc = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];
    //tableau les fins du mois
    let finmois = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    function ann(ans) {
        let anneec = ans;
        if (anneec % 4 === 0 & anneec != 1900) {
            finmois[1] = 29;
        } else {
            finmois[1] = 28;
        }
    }
    ann(annee);
    //tableau initial
    let tab = [];

    const [datenow, setDatenow] = useState('');
    const [moisnow, setmois] = useState('');
    const [semain, setsemain] = useState('');
    const days = Array.from({ length: 31 }, (_, i) => i + 1);
    let val = datejjx;
    let y = 0;
    let u = 1;
    let finM = finmois[mois]

    for (let k = datenow - 1, i = 0; k < 200, i < 200; k++, i++) {

        if (k > finM) {
            finM = finmois[(mois + u) % 12]
            k = 1;
            u++;

        }
        if (k === finM) {
            y++
        }
        if ((mois + y) % 12 === 0) {


        }

        tab.push(
            {
                'semaint': Jsemain[(val + i) % 7] + ' ',
                'jourt': days[(k) % finM] + '  ',
                'moist': Mois[(mois + y) % 12],
            })


    }
    tab[0]['semaint'] = "Aujourd'hui"








    // Créer un tableau de références  date haut


    const buttonRefs = useRef([]);

    // États pour gérer l'affichage des horaires
    const [tabmatin, setTabmatin] = useState([]);
    const [tabmidi, setTabmidi] = useState([]);
    const [tabsoir, setTabsoir] = useState([]);
    /* const tabmatin = useRef([]);
     const tabmidi = useRef([]);
     const tabsoir = useRef([]);*/
    const tabmatoinRef = useRef([]);
    let sema = useRef('')
    const [valb, setval] = useState('')


    const handleClickkl = () => {
        let moist = mois;
        let jour = semain
        for (let index = 0; index < agenda.length; index++) {
            if (agenda[index].name === Jsemainc[datejjx]) {
                //  console.log(agenda[index].times[0].start_time)
                //  console.log(agenda[index].times[0].end_time)
                //tableau heurs matin

                // let tabmatoin = [];
                let debuth = (agenda[index].times[0].start_time).substr(0, 2);
                //  console.log(debuth)
                //   console.log('ok')
                let debutminute = 0
                let finh = (agenda[index].times[0].end_time).substr(0, 2);
                let ecar = 30
                let idm = 900

                // console.log(finh)
                // Pour mettre à jour la référence
                const updateTabmatoin = (newValue) => {
                    tabmatoinRef.current = newValue;
                };
                for (let indexh = debuth; indexh < finh; indexh++) {

                    for (let index = debutminute; index < 60; index = index + ecar) {

                        updateTabmatoin([...tabmatoinRef.current, {
                            'heurs': indexh,
                            'minutes': index,
                            'id': idm
                        }]); // Ajoute newValue à tabmatoinRef


                        idm++
                    }

                }


            }



            const matin = [];
            const midi = [];
            const soir = [];

            tabmatoinRef.current.forEach(({ heurs, minutes, id }) => {
                if (heurs < 12) {
                    matin.push({ heurs, minutes, id });
                } else if (heurs >= 12 && heurs < 18) {
                    midi.push({ heurs, minutes, id });
                } else {
                    soir.push({ heurs, minutes, id });
                }
            });

            setTabmatin(matin);
            setTabmidi(midi);
            setTabsoir(soir);

            /*   console.log("Tab Matin:", tabmatin);
                console.log("Tab Midi:", tabmidi);
                console.log("Tab Soir:", tabsoir);*/



            //  console.log(sema.current)
            // setval(sema.current)
            //  console.log(mois+1)
            //  console.log(moisb[moist])
            if (moisb[moist] < mois + 1) {
                //année
                annee = datee.getFullYear() + 1;
            }
            date.current = (`${jour}-${moisb[moist]}-${annee}`)
            //  console.log(date.current)
            for (let i = 0; i < tab.length; i++) {

                buttonRefs.current[i].style.backgroundColor = 'white'; // Change la couleur de fond
                buttonRefs.current[i].style.color = 'black';


            }
            if (buttonRefs.current[index]) {
                buttonRefs.current[index].style.backgroundColor = '#F9194F'; // Change la couleur de fond
                buttonRefs.current[index].style.color = 'white';
            }
            //  alert(`Vous avez cliqué sur le bouton ${index + 1}`);




        }

        //  console.log(tabmatoinRef.current)
        // Fonction pour trier les heures


        const matin = [];
        const midi = [];
        const soir = [];

        tabmatoinRef.current.forEach(({ heurs, minutes, id }) => {
            if (heurs < 12) {
                matin.push({ heurs, minutes, id });
            } else if (heurs >= 12 && heurs < 18) {
                midi.push({ heurs, minutes, id });
            } else {
                soir.push({ heurs, minutes, id });
            }
        });

        setTabmatin(matin);
        setTabmidi(midi);
        setTabsoir(soir);

    }
    useEffect(() => {

        // handleClickkl()


    }, [agenda])
    const handleClickk = (index, moist, jour, semain) => {
        console.log(moist, jour)
        tabmatoinRef.current = ([]);
        tabmatin.current = ([]);
        tabmidi.current = ([]);
        tabsoir.current = ([]);
        // daty.current = `2024-${moisb[mois]}-${jour}`;
        // semainz = semain

        if (semain === "Aujourd'hui") {
            sema.current = Jsemainc[datejjx + 1];
        }
        else {
            sema.current = semain
        }
        // console.log(Jsemainc[6])
        // console.log(semain)
        console.log(sema.current)
        for (let index = 0; index < agenda.length; index++) {
            //   console.log(agenda[index].name)
            if (sema.current) {
                if (agenda[index].name === (sema.current).replace(/\s+/g, '')) {
                    //  console.log(agenda[index].times[0].start_time)
                    //  console.log(agenda[index].times[0].end_time)
                    //tableau heurs matin

                    // let tabmatoin = [];
                    let debuth = (agenda[index].times[0].start_time).substr(0, 2);

                    //   console.log(debuth)

                    let debutminute = 0
                    let finh = (agenda[index].times[0].end_time).substr(0, 2);
                    let ecar = 30
                    let idm = 50

                    //  console.log(finh)
                    // Pour mettre à jour la référence
                    const updateTabmatoin = (newValue) => {
                        tabmatoinRef.current = newValue;
                    };
                    for (let indexh = debuth; indexh < finh; indexh++) {

                        for (let index = debutminute; index < 60; index = index + ecar) {

                            updateTabmatoin([...tabmatoinRef.current, {
                                'heurs': indexh,
                                'minutes': index,
                                'id': idm
                            }]); // Ajoute newValue à tabmatoinRef


                            idm++
                        }

                    }


                }

            }


        }

        //  console.log(tabmatoinRef.current)
        // Fonction pour trier les heures


        const matin = [];
        const midi = [];
        const soir = [];

        tabmatoinRef.current.forEach(({ heurs, minutes, id }) => {
            if (heurs < 12) {
                matin.push({ heurs, minutes, id });
            } else if (heurs >= 12 && heurs < 18) {
                midi.push({ heurs, minutes, id });
            } else {
                soir.push({ heurs, minutes, id });
            }
        });

        setTabmatin(matin);
        setTabmidi(midi);
        setTabsoir(soir);

        /*  console.log("Tab Matin:", tabmatin.current);
          console.log("Tab Midi:", tabmidi.current);
          console.log("Tab Soir:", tabsoir.current);*/



        //  console.log(sema.current)
        // setval(sema.current)
        //  console.log(mois+1)
        //  console.log(moisb[moist])
        if (moisb[moist] < mois + 1) {
            //année
            annee = datee.getFullYear() + 1;
        }
        let j = jour.replace(/\s+/g, '')
        date.current = (`${j}-${moisb[moist]}-${annee}`)
        //  console.log(date.current)
        for (let i = 0; i < tab.length; i++) {

            buttonRefs.current[i].style.backgroundColor = 'white'; // Change la couleur de fond
            buttonRefs.current[i].style.color = 'black';


        }
        if (buttonRefs.current[index]) {
            buttonRefs.current[index].style.backgroundColor = '#F9194F'; // Change la couleur de fond
            buttonRefs.current[index].style.color = 'white';
        }
        //  alert(`Vous avez cliqué sur le bouton ${index + 1}`);

    };

    // Créer un tableau de références  matin apremidi soire
    const matinRefs = useRef([]);
    const matin = useRef();
    const aprem = useRef();
    const soir = useRef();


    const handleClickkk = (index) => {

        if (index === 0) {
            matin.current.style.display = 'flex';
            aprem.current.style.display = 'none';
            soir.current.style.display = 'none';
        } else if (index === 1) {
            matin.current.style.display = 'none';
            aprem.current.style.display = 'flex';
            soir.current.style.display = 'none';
        } else {
            soir.current.style.display = 'flex';
            aprem.current.style.display = 'none';
            matin.current.style.display = 'none';
        }

        for (let i = 0; i < 3; i++) {

            matinRefs.current[i].style.backgroundColor = 'white'; // Change la couleur de fond
            matinRefs.current[i].style.color = 'black';


        }
        if (matinRefs.current[index]) {
            matinRefs.current[index].style.backgroundColor = 'rgba(128, 128, 128, 0.24)'; // Change la couleur de fond
            matinRefs.current[index].style.color = 'black';
        }
        //  alert(`Vous avez cliqué sur le bouton ${index + 1}`);

    };


    // Créer un tableau de références  heurs
    const heurRefs = useRef([]);



    const handleClickh = (index, heur, minute) => {
        // formatTime(start_time.current);
        start_time.current = formatTime(`${heur}:${minute}`)
        console.log(start_time.current)
        for (let i = 0; i < 100; i++) {
            if (heurRefs.current[i]) {
                heurRefs.current[i].style.backgroundColor = 'white'; // Change la couleur de fond
                heurRefs.current[i].style.color = 'black';
            }



        }
        if (heurRefs.current[index]) {
            heurRefs.current[index].style.backgroundColor = '#F9194F'; // Change la couleur de fond
            heurRefs.current[index].style.color = 'white';
        }
        //  alert(`Vous avez cliqué sur le bouton ${index + 1}`);

    };



    useEffect(() => {
        setDatenow(jourx)
        setmois(Mois[mois])
        setsemain(Jsemain[datejjx])
    }, [cardss]);
    const joursema = {
        Lundi: 1,
        Mardi: 2,
        Mercredi: 3,
        Jeudi: 4,
        Vendredi: 5,
        Samedi: 6,
        Dimanche: 7
    }
    /*   useEffect(() => {
           console.log(joursema[(valb).replace(/\s+/g, '')])
       console.log(valb)
       }, [valb]);*/

    return (
        <>

            <div className="bodibody">
                <div className="boxe-prime">
                    <div className="text-date-1">{moisnow}{valb}</div>
                    <div className="text-date-2">Sélectionne ta date de disponibilité </div>
                    <div className="calendre">
                        <div className="btnright" ref={btnrightt} onClick={tranRight}> &gt; </div>
                        <div className="btnleft" ref={btnleft} onClick={tranLeft}> &lt; </div>
                        <div className="text-date-3 " ref={cardss}>

                            {

                                tab.map((item, key) => {

                                    return (
                                        <div key={key}
                                            ref={el => buttonRefs.current[key] = el}
                                            onClick={() => handleClickk(key, item.moist, item.jourt, item.semaint)}
                                            className="text-date-3-10 cardt" >
                                            <div className="d-date">{item.semaint}</div>
                                            <div className="n-date">{item.jourt}</div>
                                            <div className="m-date">{item.moist}</div>

                                        </div>

                                    )
                                })
                            }


                        </div>
                    </div>
                    <div className="text-date-4">Dis-nous quelles plages horaires te conviennent</div>
                    <div className="text-date-5">
                        <div className="text-date-5-1 ">
                            <div className="text-date-5-1-1 datepart" ref={el => matinRefs.current[0] = el} onClick={() => handleClickkk(0)}>Le Matin</div>
                            <div className="text-date-5-1-2 datepart" ref={el => matinRefs.current[1] = el} onClick={() => handleClickkk(1)}>L’ après midi</div>
                            <div className="text-date-5-1-3 datepart" ref={el => matinRefs.current[2] = el} onClick={() => handleClickkk(2)}>Le soir</div>
                        </div>
                    </div>


                    <div className="text-date-6 matin" ref={matin}>
                        {

                            tabmatin.map((item, key) => {

                                return (
                                    <div key={key}
                                        ref={el => heurRefs.current[item.id] = el}
                                        onClick={() => handleClickh(item.id, item.heurs, item.minutes)}
                                        className="text-date-6-1">{item.heurs}:{item.minutes} AM</div>

                                )
                            })
                        }
                    </div>

                    <div className="text-date-6 midi" ref={aprem}>
                        {

                            tabmidi.map((item, key) => {

                                return (
                                    <div key={key}
                                        ref={el => heurRefs.current[item.id] = el}
                                        onClick={() => handleClickh(item.id, item.heurs, item.minutes)}
                                        className="text-date-6-1">{item.heurs}:{item.minutes} PM</div>

                                )
                            })
                        }
                    </div>

                    <div className="text-date-6 soir" ref={soir}>
                        {

                            tabsoir.map((item, key) => {

                                return (
                                    <div key={key}
                                        ref={el => heurRefs.current[item.id] = el}
                                        onClick={() => handleClickh(item.id, item.heurs, item.minutes)}
                                        className="text-date-6-1">{item.heurs}:{item.minutes} PM</div>

                                )
                            })
                        }
                    </div>
                    <div className="text-date-7">
                        <div className="text-date-7-1">
                            <div className="text-date-7-1-1">Nos employées</div>
                            {employe.length > 0 ?
                                employe.map((ser, key) => {
                                    return (<div key={key} className="servicemr" style={{ marginBottom: '5px' }}>
                                          <img style={{width:'40px',height:'40px',borderRadius:'100%'}} src={`https://api-macerise.masovia-madagascar.com/storage/employee_image/${ser.image}`} alt="" srcset="" />
                                        <input style={{ marginLeft: '10px' }} type="radio" name="radioo" id={ser.id} value={ser.id}
                                            checked={idemployer == ser.id}
                                            onChange={handleChangee}
                                        // onClick={cli}
                                        /><label style={{ marginLeft: '10px',margin:'0' }} htmlFor={ser.id}>{ser.name}</label>
                                      
                                    </div>)
                                    //<p key={key}>{ser.name} </p>
                                })
                                : '...'}
                        </div>
                    </div>
                    <div className="text-date-7">
                        <div className="text-date-7-1">

                            <div className="text-date-7-1-1">Nos services</div>
                            {servicem.services ?
                                servicem.services.map((ser, key) => {
                                    return (<div key={key} className="servicem">
                                        <input type="radio" name="radio" id={ser.id} role={ser.duration} className={ser.price} value={ser.name}
                                            checked={selectedOption === ser.name}
                                            onChange={handleChange}
                                            onClick={cli}
                                        /><label style={{ marginLeft: '10px' }} htmlFor={ser.id}>{ser.name}</label>
                                        <div className="text-date-7-1-2" >€{ser.price}</div>
                                        <div className="text-date-7-1-3">{ser.duration}</div>
                                    </div>)
                                    //<p key={key}>{ser.name} </p>
                                })
                                : '...'}


                        </div>
                        {/*client_id },{masseur_id*/}
                        <div className="line"></div>
                        {/*agenda[0].start_time +'dfdfd'*/}

                        <div className="text-date-7-2"><span className="bold">Professionnel: </span> {service.salon.name}</div>
                    </div>
                    <div className="text-date-8"><div className="text-date-8-1"><span className="bold">Total:</span> </div><div className="text-date-8-2" >€{selectprice ? selectprice : 0}</div><div className="text-date-8-3">{selectdur}</div></div>


                    <div className="text-date-9" onClick={fetchreservation} >Reserver</div>
                    <div className="text-date-99" onClick={() => showcalendrier(false, '')} >Retour</div>
                    {mess ? <p className="mess">{mess}</p> : ''}

                    {errkely ? <p className="err">{errkely}</p> : ''}

                </div>
            </div>

        </>
    )
}
export default Call