import React from 'react'
import './Filtre.css'
import { NavLink } from 'react-router-dom'
import { useState, useEffect } from "react";
import { categories } from '../../authentification/Authentificationl';
import FilterListIcon from '@mui/icons-material/FilterList';
export default function Filtre() {
  const [catego, setcatego] = useState([]);
  const [afficherComposantA, setAfficherComposantA] = useState('');
  const categorie = async () => {
    try {
      const userData = await categories();
      setcatego(userData);
//  console.log(userData)
      // Vous pouvez rediriger l'utilisateur ou rafraîchir le state ici
    } catch (error) {
      console.log(error)

    }
  };
  useEffect(() => {
    categorie();
    // console.log(catego)
  }, [])


 

  return (
    <>
      <div className='scrolll'>
        <div className='filtre'>
          <button className='border poab'> <FilterListIcon /> Filtres</button>

          {
            catego.category ?
              catego.category.map((cat, key) => {
                return (

                  <button     
                   
                  style={afficherComposantA === cat.id ? { border: '1px solid #F9194F' } : {}}

                
                     onClick={()=>setAfficherComposantA(cat.id)}>
                    <NavLink
                      key={key}
                   
                      to={"/filtre/"+ cat.id +" ?categories=" + cat.id}
                 
               
                    >

                      {cat.name}

                    </NavLink>

                  </button>
                )
              })
              :  <button style={{color:'white'}} >  .....</button>
          }

        </div>
      </div>
    </>
  )
}
