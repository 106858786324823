import React from "react";
import axios from "axios";
import { Typography, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@mui/material';
import BuildIcon from "@mui/icons-material/Build";
// import "./HIistoriquePaiement.css";
import { useState, useEffect } from "react";
export default function HIistorique_paiement() {
  const [events, setEvents] = useState([]);
  let [fav, setfav] = useState([]);

  const fetchquestion = async () => {
    const token = sessionStorage.getItem("token");
    try {
      const response = await axios.get(
        `/payement-history`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // setfav(response.data.favorites_client);
       setEvents(response.data.payments);
      console.log(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchquestion();
  }, []);
  return (
    <div style={{ padding: '16px' }}>
    <Typography variant="h6" gutterBottom>
      Historique de paiement
    </Typography>

    <Paper elevation={3}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Account</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Date de paiement</TableCell>
            <TableCell>Methode de paiement</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {events && events.map((event) => (
            <TableRow key={event.id}>
              <TableCell style={{ backgroundColor: "rgba(128, 128, 128, 0.384)" }}>
                {event.amount}
              </TableCell>
              <TableCell>{event.status}</TableCell>
              <TableCell>{event.payment_date}</TableCell>
              <TableCell>{event.payment_method}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  </div>
  );
}
