import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  Typography,
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Divider,
  Grid,
} from "@mui/material";

const Transaction = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fonction pour récupérer les transactions
  const fetchTransactions = async () => {
    const token = sessionStorage.getItem("token");
    try {
      const response = await axios.get(`/payement-history`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }); // Remplacez par votre endpoint API réel
      setTransactions(response.data.payments);
      console.log("tran:", response.data);
    } catch (error) {
      console.error("Erreur de récupération des transactions", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTransactions();
  }, []);

  return (
    <Container sx={{ mt: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Historique des Transactions
      </Typography>

      {loading ? (
        <Box display="flex" justifyContent="center" mt={4}>
          <CircularProgress />
        </Box>
      ) : (
        <List>
          {transactions.map((transaction) => (
            <React.Fragment key={transaction.id}>
              <ListItem>
                <Grid container spacing={0}>
                  <Grid item xs={12} sm={6} md={3}>
                    <ListItemText primary={`Tarifs : $${transaction.amount}`} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <ListItemText primary={`Statut : ${transaction.status}`} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <ListItemText
                      primary={`Mode de paiement: ${transaction.payment_method}`}
                    />
                  </Grid>
                  {/* <Grid item xs={12} sm={6} md={3} >
                  <ListItemText  primary={`Date de paiement : ${transaction.payment_date}`} />
                  
                </Grid> */}
                  <Grid item xs={12} sm={6} md={3}>
                    <ListItemText
                      primary={`Date de paiement : ${transaction.payment_date}`} // Ajustez la taille de la police ici
                    />
                  </Grid>
                </Grid>
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>
      )}
    </Container>
  );
};

export default Transaction;
