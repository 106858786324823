import { useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

const DemoChart = ({ monthly }) => {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  const categories = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  const [chartState, setChartState] = useState({
    options: {
      chart: {
        type: "bar",
      },
      xaxis: {
        categories: categories,
      },
      colors: ["#f91944"],
      title: {
        text: "Monthly",
        floating: true,
        offsetY: 330,
        align: "center",
        style: {
          color: "#444",
          fontSize: "18px",
        },
      },
      // Ajout de la réactivité ici
      responsive: [
        {
          breakpoint: 1000, // Pour les écrans de moins de 1000px
          options: {
            chart: {
              height: 200, // Hauteur plus petite pour petits écrans
              width: 400,
            },
            xaxis: {
              labels: {
                style: {
                  color: "#444",
                  fontSize: "10px", // Réduction de la taille des labels
                },
              },
            },
            title: {
              offsetY: 240,
              style: {
                color: "#444",
                fontSize: "14px", // Réduction de la taille du titre
              },
            },
          },
        },
        {
          breakpoint: 600, 
          options: {
            chart: {
              height: 200, // Encore plus petit pour les très petits écrans
              width: 350,
            },
            xaxis: {
              labels: {
                style: {
                  fontSize: "8px", // Réduction encore plus importante de la taille des labels
                },
              },
            },
            title: {
              offsetY: 200,
              style: {
                fontSize: "12px", // Réduction de la taille du titre
              },
            },
          },
        },
      ],
    },
    series: [
      {
        name: "Monthly",
        data: Array(12).fill(0),
      },
    ],
  });

  useEffect(() => {
    if (monthly) {
      const updatedData = [
        monthly?.["janvier"] || 0,
        monthly?.["fevrier"] || 0,
        monthly?.["mars"] || 0,
        monthly?.["avril"] || 0,
        monthly?.["mai"] || 0,
        monthly?.["juin"] || 0,
        monthly?.["juillet"] || 0,
        monthly?.["août"] || 0,
        monthly?.["septembre"] || 0,
        monthly?.["octobre"] || 0,
        monthly?.["novembre"] || 0,
        monthly?.["decembre"] || 0,
      ];

      // Mettre à jour l'état du graphique avec les nouvelles données
      setChartState((prevState) => ({
        ...prevState,
        series: [
          {
            ...prevState.series[0],
            data: updatedData,
          },
        ],
      }));
    }
  }, [monthly]);

  return (
    <div className="app">
      <div className="row">
        <div className="mixed-chart">
          <Chart
            options={chartState.options}
            series={chartState.series}
            type="bar"
            width="750"
            height="400"
          />
        </div>
      </div>
    </div>
  );
};

export default DemoChart;
