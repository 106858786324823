import {
  Box,
  Typography,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Rating,
} from "@mui/material";
import { React, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import clientImg from "../../static/images/client.png";
import CustomParagraph from "../../CustomParagraph/CustomParagraph";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ReplyIcon from "@mui/icons-material/Reply";
import RatingStars from "./RatingStars/RatingStars";
import axios from "axios";
import "./rev.css";
// import { useParams } from "react-router-dom";
import Login from "../../../Compo/Login/Login";

const CarouselContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: 5,
}));

const FlexArea = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: 5,
}));

const Reviews = ({ data }) => {
  const { id } = useParams();
  const [openModal, setOpenModal] = useState(false);
  const [modalReplies, setModalReplies] = useState([]);
  const [tab, settab] = useState([]);
  const [r_name, setname] = useState('');
  const [r_image, setimage] = useState('');
  const [selectedComment, setSelectedComment] = useState(null);
  const [reply, setReply] = useState("");
  const [commentaire, setCommentaire] = useState([]);
  const [commentairex, setCommentairex] = useState([]);
  const [commenta, setCommenta] = useState("");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false); // Modal state for delete confirmation
  const [commentIdToDelete, setCommentIdToDelete] = useState(null); // Track comment to delete
  const [commentIdAffiche, setCommentIdAffiche] = useState(null);
  const [commot, setcommot] = useState([]);
  const [upModal, setUpModal] = useState(false);
  const [modif, setModif] = useState({
    idCom: "",
    commentaires: "",
  });
  const login = document.querySelector('.login')
  const [alert, setAlert] = useState(false);
  // const token = sessionStorage.getItem("token");
  const [tokens, setTokens] = useState("");
  function showLogin() {
    // Setlogin(true)
    if (login) {
      login.style.display = 'flex';
    }

  }
  // Function to add a new comment
  const handleAddComment = async (e) => {
    e.preventDefault();
    const user_id = sessionStorage.getItem("userId"); // Récupération du userId depuis le sessionStorage
    const token = sessionStorage.getItem("token"); // Récupération du token depuis le sessionStorage

    // Vérification si user_id ou token sont manquants
    if (!user_id || !token) {
      showLogin()
      // Affiche le modal si user_id ou token est null
    } else {
      try {
        const response = await axios.post(
          `/salons/${id}/commenter`,
          { comment: commenta },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        fetchCom(); // Actualisation des commentaires après ajout
      } catch (error) {
        console.error(
          "Erreur lors de l'ajout du commentaire:",
          error.response ? error.response.data : error.message
        );
      }
// jiji
      setCommenta(""); // Réinitialise le champ du commentaire après soumission
    }
  };

  const [commentIdRep, setCommentIdRep] = useState("");
  // Open replies modal for a specific comment

  const handleOpenReplies = (commentID, comment, name, img) => {/////////////////////////
    setSelectedComment(comment);

    setname(name)
    setimage(img)
    console.log(name, img)
    setCommentIdAffiche((prevId) => (prevId === id ? null : id));
    setModalReplies(comment.reponses || []);
    setCommentIdRep(commentID);///
    setOpenModal(true);
    const boxx = document.getElementsByClassName('.boxt')
   
    if (boxx) {
     
      boxx.scrollTop = boxx.scrollHeight; // Faire défiler vers le bas
    }
  };

  const handleClose = () => {
    setOpenModal(false);
    setname('')
    setimage('')
    settab('')

  };

  const handleAddReply = async (e) => {
    e.preventDefault();

    const user_id = sessionStorage.getItem("userId");
    const token = sessionStorage.getItem("token");
    const reponse = reply;
    if (!user_id || !token) {
      showLogin()
      setOpenModal(false)
      // Affiche le modal si user_id ou token est null
    } else {
      try {
        const response = await axios.post(
          `commentaires/${commentIdRep}/repondre`,
          { reponse },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        // Ajoute la nouvelle réponse localement dans modalReplies
        setModalReplies((prevReplies) => [...prevReplies, response.data]);
        settab((prevReplies) => [...prevReplies, reply]);
        // Vide le champ de texte
        setReply("");

        // Mets à jour la liste complète des commentaires et réponses
        fetchCom();
        const boxx = document.querySelector('.boxt')
        if (boxx) {
          boxx.scrollTop = boxx.scrollHeight; // Faire défiler vers le bas
        }
        console.log("Chrs:", response.data);
        // setOpenModal(true);
      } catch (error) {

        console.error(
          "Erreur lors de l'ajout de la réponse:",
          error.response ? error.response.data : error.message
        );
      }
    }

    // handleClose();
  };
  const [rep, setRep] = useState([]);

  const fetchCom = async () => {
    const tokenss = sessionStorage.getItem("userId");
    try {
      const response = await axios.get(`/salons/${id}/commentaires`);

      // Set the comments data
      setCommentaire(response.data);
      setCommentairex(response.data);

      console.log('commentaire nicl:', response.data)

      // Directly use response.data to get all replies
      const allReplies = response.data.flatMap(
        (comment) => comment.reponses || []
      );
      // console.log('dadatoa')
      setRep(allReplies);
      setTokens(tokenss);

      // console.log("Commentaires:", response.data);
      // console.log("Réponses:", allReplies);
    } catch (error) {
      console.error("Erreur lors de la récupération des commentaires:", error);
    }
  };

  useEffect(() => {
    fetchCom();
    // handleAddReply();
    if (commentaire) {
      console.log(commentaire)
      console.log(commentairex)
    }

  }, []);

  // Function to open delete confirmation modal
  const handleOpenDeleteModal = (commentId) => {
    setCommentIdToDelete(commentId);
    setDeleteModalOpen(true);
  };

  // Function to close delete confirmation modal
  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
    setCommentIdToDelete(null);
  };

  // Delete function
  const handleDeleteComment = async (e) => {
    e.preventDefault();
    try {
      const token = sessionStorage.getItem("token");
      if (!token) {
        console.error("Token is missing. Unable to authenticate request.");
        return;
      }
      await axios.delete(`/delete_coms/${commentIdToDelete}`, {
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token in headers for authentication
        },
      });

      // Update state by filtering out the deleted comment
      setCommentaire((prevCommentaires) =>
        prevCommentaires.filter((comment) => comment.id !== commentIdToDelete)
      );

      handleCloseDeleteModal(); // Close the modal after successful deletion
    } catch (error) {
      console.error(
        "Erreur lors de la suppression du commentaire:",
        error.response ? error.response.data : error.message
      );
    }
  };

  // UPDATE

  const handleOpenUpdateModal = (idcom, comment) => {
    setModif((prev) => ({ ...prev, idCom: idcom, commentaires: comment }));
    setUpModal(true);
  };
  const handleCloseUp = () => {
    setUpModal(false);
  };
  const handelUpdateCom = async (e) => {
    e.preventDefault();
    const token = sessionStorage.getItem("token");
    const comment = modif.commentaires;
    const idCom = modif.idCom;
    try {
      const response = await axios.put(
        `update_comment/${id}/${idCom}`,
        {
          comment,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      fetchCom();
      setUpModal(false);
    } catch {
      console.log("erreur de modification");
    }
  };
  //
  const [rating, setRating] = useState("5"); // Gère l'état de la note des étoiles
  //const { id } = useParams(); // Récupère l'ID du salon à partir des paramètres d'URL
  const [note, setNote] = useState([]); // Gère les notes récupérées depuis l'API
  const [userId, setUserId] = useState(sessionStorage.getItem("userId")); // Récupère l'ID de l'utilisateur actuel

  // Fonction de gestion du changement de note (quand l'utilisateur clique sur les étoiles)
  const handleChange = (e, newValue) => {
    // e.preventDefault()
    setRating(newValue); // Met à jour l'état de la note
    handelSatrt(newValue); // Passe la nouvelle note directement à la fonction
  };

  // Fonction pour envoyer la note au backend
  const handelSatrt = async (newValue) => {
    const token = sessionStorage.getItem("token");
    try {
      const response = await axios.post(
        `/create_notes/${id}`,
        { note: rating }, // Envoie la nouvelle note
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      // Une fois la note ajoutée avec succès, récupère les notes mises à jour
      fetchRating();
    } catch {
      console.log("Erreur lors de l'ajout de la note");
    }
  };

  // Fonction pour récupérer les notes du salon
  const fetchRating = async () => {
    const token = sessionStorage.getItem("token");
    try {
      const response = await axios.get(`/notes_salon/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setNote(response.data.notes); // Met à jour l'état avec les notes récupérées
      console.log("notes:", response.data);
    } catch {
      console.log("Erreur lors de la récupération des notes");
    }
  };

  // Appel à l'API pour récupérer les notes au montage du composant
  useEffect(() => {
    fetchRating();
    console.log("userId:", userId);
  }, []);

  return (
    <CarouselContainer>
      <Box sx={{ width: "50%" }}>
        {commentaire.map((comment) => {
          // Formattage de la date
          const formattedDate = new Date(comment.created_at).toLocaleDateString(
            "fr-FR",
            {
              year: "numeric",
              month: "long",
              day: "numeric",
            }
          );

          // Utilisation de la note spécifique au commentaire
          const commentaireNote = note.find(
            (rati) => rati.user_id === comment.user_id
          ) || { note: 0 }; // Trouve la note correspondant à l'utilisateur

          return (
            <Box key={comment.id} sx={{ mb: 3 }}>
              <FlexArea>
                <img src={`https://api-macerise.masovia-madagascar.com/storage/images/${comment.user.image}`} alt="Client" loading="lazy" style={{ borderRadius: '50px', width: '40px', height: '40px', marginTop: "-15px" }} />
                <Box>
                  <FlexArea>
                    <Typography sx={{ fontWeight: "bold" }} variant="h6">
                      {comment.user.name}
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      {/* Affiche uniquement la note de l'utilisateur actuel */}
                      {commentaireNote && (
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                          <Rating
                            name="simple-controlled"
                            value={commentaireNote.note} // Affiche la note de ce commentaire
                            onChange={handleChange}
                            precision={0.5} // Permet des notes avec demi-étoiles
                            max={5} // Définit le nombre d'étoiles maximum à 5
                            readOnly={userId !== comment.user_id} // Lecture seule si ce n'est pas l'utilisateur actuel
                          />
                          <Typography
                            variant="body1"
                            sx={{ marginLeft: 1, marginTop: "2px" }}
                          >
                            {commentaireNote.note} Notes
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </FlexArea>
                  <CustomParagraph>{comment.comment}</CustomParagraph>
                  <Typography
                    sx={{ fontSize: "8px", color: "gray" }}
                    variant="h6"
                  >
                    {formattedDate}
                  </Typography>
                </Box>
              </FlexArea>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <IconButton sx={{ fontSize: "15px", color: "white" }}>
                  <ReplyIcon />
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color: "gray",
                      marginLeft: "-20px",
                    }}
                    onClick={() => handleOpenReplies(comment.id, comment, comment.user.name, comment.user.image)}////////////
                  >
                    Répondre ({comment.reponses?.length || 0})
                  </Typography>
                </IconButton>
                {tokens === comment.user_id && (
                  <>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: "gray",
                        marginLeft: "10px",
                        marginTop: "12px",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        handleOpenUpdateModal(comment.id, comment.comment)
                      }
                    >
                      Modifier
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: "#F9194F",
                        marginLeft: "10px",
                        marginTop: "12px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleOpenDeleteModal(comment.id)}
                    >
                      Supprimer
                    </Typography>
                  </>
                )}
              </Box>
            </Box>
          );
        })}

        {/* <RatingStars value={5} /> */}
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <TextField
            label="Commentaire"
            value={commenta}
            onChange={(e) => setCommenta(e.target.value)}
            fullWidth
            sx={{ mb: 2, width: { xs: '200px', sm: '300px', md: '300px' }, marginTop: "-20px" }}
          />
          <Button
            variant="contained"
            sx={{
              color: "white",
              background: "#F9194F",
              width: { xs: '80px', sm: '100px', md: '120px' },
              height: "55px",
              marginLeft: "5px",
              marginTop: "-20px",
              borderRadius: "10px",
            }}
            onClick={handleAddComment}
          >
            <Typography sx={{ fontSize: { xs: '7px', sm: '8px', md: '10px' } }}>
              Ajouter commentaire
            </Typography>
          </Button>
        </Box>

        <Dialog open={openModal} onClose={handleClose} maxWidth="md" fullWidth>
          <DialogTitle>
            <Box sx={{
              position: 'absolute',
              right: { xs: '16px', md: '24px' }, // Positionnement adaptatif
              top: { xs: '-5px', md: '-5px' },   // Positionnement adaptatif
              cursor: 'pointer',
              fontSize: '24px',                  // Taille de la croix
              zIndex: 1,                         // S'assurer qu'il est au-dessus du contenu
            }} onClick={handleClose}>x</Box>
            <Typography>Réponses au commentaire{commentaire.length > 0 ? commentaire.reponses : ''}</Typography> </DialogTitle>

          <DialogContent
            className="boxt"
            sx={{
              flex: "1 1 auto",
              overflowY: "auto",
              paddingBottom: '80px',
              
            }}
          >
            <Box
              sx={{
                padding: 2,
                border: "1px solid #ccc",
                borderRadius: "8px",
                mb: -10,

                marginBottom: '0px'
              }}
            >
              {selectedComment ? ( // Affiche seulement le commentaire sélectionné
                <Box >
                  <Box sx={{ display: "flex", flexDirection: "row", mb: 2 }}>
                    <img
                      className="pthA"
                      src={`https://api-macerise.masovia-madagascar.com/storage/images/${selectedComment.user.image}`}
                      alt="Client"
                      loading="lazy"
                      style={{ borderRadius: '50px', width: '40px', height: '40px', marginTop: "10px" }}
                    />
                    <Typography
                      sx={{
                        mt: 1,
                        display: "inline-block",
                        backgroundColor: "#efeded",
                        padding: "8px",
                        borderRadius: "4px",
                        color: "black",
                      }}
                    >
                      <Typography sx={{ fontWeight: "900" }}>
                        {selectedComment.user.name}
                      </Typography>
                      {selectedComment.comment}
                    </Typography>
                  </Box>

                  {/* Affichage des réponses associées */}
                  {selectedComment.reponses &&
                    selectedComment.reponses.length > 0 ? (
                    selectedComment.reponses.map((re) => (
                      <Box
                        key={re.id}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: "25px",
                          // mb: 1,
                        }}
                      >
                        <img
                          className="pth"
                          src={`https://api-macerise.masovia-madagascar.com/storage/images/${re.user.image}`}
                          alt="Client"
                          loading="lazy"
                          style={{ borderRadius: '50px', width: '30px', height: '30px', marginTop: "10px" }}
                        />
                        <Box sx={{ ml: 2 }}>
                          <Typography
                            sx={{
                              mt: 1,
                              display: "inline-block",
                              backgroundColor: "#efeded",
                              padding: "8px",
                              borderRadius: "4px",
                              color: "black",
                            }}
                          >
                            <Typography sx={{ fontWeight: "900" }}>
                              {re.user.name}
                            </Typography>
                            {re.reponse}
                          </Typography>
                        </Box>
                      </Box>
                    ))
                  ) : (
                    <Typography>Aucune réponse pour le moment.</Typography>
                  )}
                </Box>
              ) : (
                <Typography>Aucune réponse pour le moment.</Typography>
              )}
              {
                tab ?
                  tab.map((re) => (
                    <Box
                      key={re.id}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        marginLeft: "25px",
                        // mb: 1,
                      }}
                    >
                      <img
                        className="pth"
                        src={`https://api-macerise.masovia-madagascar.com/storage/images/${r_image}`}
                        alt="Client"
                        loading="lazy"
                        style={{ borderRadius: '50px', width: '30px', height: '30px', marginTop: "10px" }}
                      />
                      <Box sx={{ ml: 2 }}>
                        <Typography
                          sx={{
                            mt: 1,
                            display: "inline-block",
                            backgroundColor: "#efeded",
                            padding: "8px",
                            borderRadius: "4px",
                            color: "black",
                          }}
                        >
                          <Typography sx={{ fontWeight: "900" }}>
                            {r_name}
                          </Typography>
                          {re}
                        </Typography>
                      </Box>
                    </Box>)) : ''}
            </Box>
          </DialogContent>

          {/* Formulaire pour ajouter une réponse */}
          <DialogContent
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              flexShrink: 0,
            }}
          >
            <form onSubmit={handleAddReply}>
              <Box
                sx={{
                  padding: 0,
                  border: "1px solid #ccc",
                  borderRadius: "8px",
                  backgroundColor: "#f9f9f9",
                  width: "100%",
                }}
              >
                <TextField
                  label="Ajouter une réponse"
                  value={reply}
                  onChange={(e) => setReply(e.target.value)}
                  fullWidth
                  sx={{ mt: 0 }}
                />

                <Button
                  sx={{
                    mt: 1,
                    color: "white",
                    background: "#F9194F",
                    width: "150px",
                    fontSize: "10px",
                    marginLeft: "5px",
                    borderRadius: "10px",
                  }}
                  variant="contained"
                  // onClick={}
                  type="submit"
                >
                  Ajouter Réponse
                </Button>
              </Box>
            </form>
          </DialogContent>
        </Dialog>

        {/* modal update */}
        <Dialog open={upModal} onClose={handleCloseUp} maxWidth="md" fullWidth>
          <Box sx={{
            position: 'absolute',
            right: { xs: '16px', md: '24px' }, // Positionnement adaptatif
            top: { xs: '-5px', md: '-5px' },   // Positionnement adaptatif
            cursor: 'pointer',
            fontSize: '24px',                  // Taille de la croix
            zIndex: 1,
            // S'assurer qu'il est au-dessus du contenu
          }} onClick={handleCloseUp}>x</Box>
          <DialogTitle>Modification de votre commentaire</DialogTitle>

          {/* Formulaire pour ajouter une réponse */}
          <DialogContent
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              flexShrink: 0,
            }}
          >
            <form onSubmit={handelUpdateCom}>
              <Box
                sx={{
                  padding: 0,
                  border: "1px solid #ccc",
                  borderRadius: "8px",
                  backgroundColor: "#f9f9f9",
                  width: "100%",
                }}
              >
                <TextField
                  label="Ajouter une réponse"
                  fullWidth
                  sx={{ mt: 0 }}
                  value={modif.commentaires}
                  onChange={(e) => {
                    setModif((prev) => ({
                      ...prev,
                      commentaires: e.target.value,
                    }));
                  }}
                />

                <Button
                  sx={{
                    mt: 1,
                    color: "white",
                    background: "#F9194F",
                    width: "150px",
                    fontSize: "10px",
                    marginLeft: "5px",
                    borderRadius: "10px",
                  }}
                  variant="contained"
                  // onClick={}
                  type="submit"
                >
                  Modifier
                </Button>
              </Box>
            </form>
          </DialogContent>
        </Dialog>

        {/* Delete Confirmation Modal */}
        <Dialog open={deleteModalOpen} onClose={handleCloseDeleteModal}>
          <Box sx={{
            position: 'absolute',
            right: { xs: '16px', md: '24px' }, // Positionnement adaptatif
            top: { xs: '-5px', md: '-5px' },   // Positionnement adaptatif
            cursor: 'pointer',
            fontSize: '24px',                  // Taille de la croix
            zIndex: 1,                         // S'assurer qu'il est au-dessus du contenu
          }} onClick={handleCloseDeleteModal}>x</Box>
          <DialogTitle>Confirmer la suppression</DialogTitle>
          <DialogContent>
            <Typography>
              Êtes-vous sûr de vouloir supprimer ce commentaire ?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteModal} color="primary">
              Annuler
            </Button>
            <Button
              onClick={handleDeleteComment}
              sx={{ color: "white", background: "#F9194F" }}
              variant="contained"
            >
              Supprimer
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      <div className="login">
        <Login dat={1} />
      </div>
    </CarouselContainer>
  );
};

export default Reviews;
