import { Card, CardMedia, Stack, Typography, Button ,Box} from "@mui/material";
import Cardo from "./Cardo";
import "./Card.css";
import axios from "axios";
import NavMain from "../Navigation/NavMain";
import Footer from "../outil/Footer";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { NavLink } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
const Cardi = ({ categoId }) => {
  const { id } = useParams(); // Récupérer l'ID de l'URL

  const [category, setcategori] = useState([]);
  const [servicescategory, setservicescategory] = useState([]);
  const [error, setError] = useState("");
  const [name, setname] = useState("");
  const [tab, settab] = useState();
  const fetchServices = async () => {
    try {
      const response = await axios.get(
        `https://www.api-macerise.masovia-madagascar.com/api/category/${id}`
      );
      setcategori(response.data);

      setname(response.data.category.name)
    } catch (err) {
      setError(err);
    } finally {
    }
  };
  useEffect(() => {
    fetchServices();


    // setname(cate)
    window.scroll({ top: 0 });
  }, []);
  return (
    <Box sx={{ background: 'rgba(214, 208, 208, 0.151)', marginTop: '-80px', padding: { xs: '20px', md: '40px' } }}>
      <Stack spacing={5}>

        <NavMain />
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={4}
          sx={{ height: 'auto', minHeight: '400px' }}
        >
          {/* Left blurred card */}
          <Card
            className="cardbody"
            sx={{
              width: { xs: '100%', md: '45%' },
              height: '500px',
              position: { xs: 'relative', md: 'absolute' },
              filter: 'blur(1px)',
              left: { xs: '0', md: '20px' }
            }}
          />

          {/* Right content stack */}
          <Stack
            spacing={2}
            sx={{
              width: { xs: '100%', md: '45%' },
              position: { xs: 'relative', md: 'relative' },
              left: { md: '50%' },
              marginTop: { xs: '20px', md: '0' }
            }}
          >
            <Stack sx={{ textAlign: "center" }} className="text-typo">
              <Typography variant="h5">
                {category.category ? category.category.name : ""}
                {category.category ? <img style={{ width: '30px', height: '30px', borderRadius: '100%' }} src={`https://api-macerise.masovia-madagascar.com/category_images/${category.category.images}`}/>: ""}
              </Typography>
            </Stack>

            <Stack sx={{ position: "relative", top: "-30px" }}>
              <Stack sx={{ width: "100%" }} className="text-typo">
                <Typography variant="h6" sx={{ width: "100%", margin: { xs: "15px", md: "25px" } }}>
                  {category.category ? category.category.description : ""}
                </Typography>
              </Stack>
              <NavLink to={`/filtr/${id}/${name}`}>
                <Button
                  elevation={2}
                  variant="contained"
                  sx={{
                    background: "#F9194F",
                    ":hover": { background: "red" },
                    width: { xs: "100%", md: "300px" },
                    paddingTop: "10px",
                    marginLeft: { xs: "0", md: "25px" },
                    marginBottom: "10px",
                  }}
                >
                  Voir plus
                </Button>
              </NavLink>
            </Stack>

            <Stack
              sx={{
                width: { xs: "100%", md: "75%" },
                marginTop: "10px",
                position: "relative",
              }}
            >
              <Typography variant="h5" sx={{ marginLeft: { xs: "0", md: "25px" } }}>
                Listes des {category.category ? category.category.name : ""}
              </Typography>
              <Stack sx={{ marginTop: "10px", marginLeft: { xs: "0", md: "25px" } }}>
                {category.category_services
                  ? category.category_services.map((cate) => (
                    <Accordion key={cate.id}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>{cate.name}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>{cate.description}</Typography>
                      </AccordionDetails>
                    </Accordion>
                  ))
                  : ""}
              </Stack>
            </Stack>
          </Stack>
        </Stack>

        <Footer />
      </Stack>
    </Box>
  );
};
export default Cardi;
