import { Box, Skeleton } from "@mui/material";

export default function SkeletonCardSlide() {
  return (
    <Box sx={{ marginLeft: 1 }}>
      <Skeleton
        variant="rectangular"
        width={"100%"}
        height={"88svh"}
      ></Skeleton>
    </Box>
  );
}
