import { Card, CardMedia, Stack, Typography, Button, Box, styled } from "@mui/material";
import Cardo from "./Cardo";
import "./servicecat.css";
import axios from "axios";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import NavMain from "../Navigation/NavMain";
import Footer from "../outil/Footer";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { NavLink } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

const CustomButton = styled(Button)(({ theme }) => ({

    textTransform: 'capitalize',
    backgroundColor: '#fff',
    color: '#000',
    ':hover': {
        transform: 'scale(1.05)',
        backgroundColor: '#fff',
        cursor: 'pointer'
    }
}));

const ServiceCat = ({ categoId }) => {
    const navigate = useNavigate();
    function Retoure() {
        navigate('/')
    }
    const { id } = useParams(); // Récupérer l'ID de l'URL

    const [category, setcategori] = useState([]);
    const [servicescategory, setservicescategory] = useState([]);
    const [error, setError] = useState("");
    const [name, setname] = useState("");
    const [tab, settab] = useState();
    const fetchServices = async () => {
        try {
            const response = await axios.get(
                `https://www.api-macerise.masovia-madagascar.com/api/category/${id}`
            );
            setcategori(response.data);

            setname(response.data.category.name)
        } catch (err) {
            setError(err);
        } finally {
        }
    };
    useEffect(() => {
        fetchServices();


        // setname(cate)
        window.scroll({ top: 0 });
    }, []);
    return (
        <>
            <NavMain />
            <Box sx={{ position: 'fixed', top: 80, left: 30 ,zIndex:'10'}}>
              
                <CustomButton onClick={() => Retoure()} startIcon={<ArrowBackIcon />}></CustomButton>
            </Box>
            <div className="detailcategori" style={{ minHeight: '42vw', padding: '2.5vw', maxWidth: '100vw', overflow: 'hidden' }}>

                <div className="flexcat">
                    <div className="catimager">
                        {category.category ? <img src={`https://api-macerise.masovia-madagascar.com/storage/category_images/${category.category.images}`} /> : ""}
                    </div>

                    <div className="descate">
                        <h2> {category.category ? category.category.name : ""}</h2>
                        <p >
                            {category.category ? category.category.description : ""}
                        </p>
                        {category.category ?
                            <NavLink to={`/filtr/${id}/${name}`}>
                                <button

                                >
                                    DECOUVRIR
                                </button>
                            </NavLink>
                            : ""}
                    </div>
                </div>


            </div>
            <div className="listetitre">
                {category.category ? '  Listes ' + category.category.name : ""}
            </div>
            <div className="listedetss">
                {category.category_services
                    ? category.category_services.map((cate) => (
                        <Accordion key={cate.id} >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>{cate.name}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>{cate.description}</Typography>
                            </AccordionDetails>
                        </Accordion>
                    ))
                    : ""}
            </div>

            <Footer />
        </>
    );
};
export default ServiceCat;
