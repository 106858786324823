import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
  Stack,
  TextField,
} from "@mui/material";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { editEmployer } from "../../api/employer";
import { toast } from "sonner";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function DialogEdit({ open, setOpen, data, setSubmitChange }) {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: data["name"],
      lastname: data["lastname"],
      ddn: data["ddn"],
      cin: data["CIN"],
    },
  });
  //Pour rafrachaire les default value
  useEffect(() => {
    if (data) {
      reset({
        name: data["name"],
        lastname: data["lastname"],
        address: data["address"],
        ddn: data["ddn"],
        CIN: data["CIN"],
      });
    }
  }, [data, reset]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // useEffect(() => {
  //   console.log("Finder", data);
  // }, []);
  const fields = [
    { label: "nom", name: "name", type: "text" },
    { label: "prénom", name: "lastname", type: "text" },
    { label: "date de naisssance", name: "ddn", type: "date" },
    { label: "adresse", name: "address", type: "text" },
    { label: "CIN", name: "CIN", type: "text" },
  ];

  const onSubmit = (dataField) => {
    const ids = sessionStorage.getItem("idsalon");
    console.log("Real dataField", dataField);
    const salonIdData = { ...dataField, salon_id: ids };
    editEmployer(data["id"], salonIdData)
      .then((res) => {
        console.log(res.data);
        setSubmitChange((v) => !v);
        toast.success("Employer mise à jour avec succes", { position: "top-center" });
        handleClose();
      })
      .catch((err) => {
        toast.error("Erreur lors de la modification de l'employer", { position: "top-center" });
        console.log("Toast", err);
      });
    // setData((prev) => [...prev, data]);
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        fullWidth={true}
        // aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="sm"
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Modifier
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Stack
            component={"form"}
            onSubmit={handleSubmit(onSubmit)}
            direction={"column"}
            gap={2}
          >
            {fields.map((field, i) => {
              return (
                <TextField
                  key={i}
                  {...register(field.name, { rules: "this field is required" })}
                  label={field.label}
                  type={field.type || null}
                />
              );
            })}
            <Button
              sx={{
                color: "#fff",
                bgcolor: "#F9194F",
                "&:hover": {
                  bgcolor: "#000",
                  color: "#fff",
                  border: "none",
                },
              }}
              type="submit"
            >
              Valider
            </Button>
          </Stack>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose}>Fermer</Button>
        </DialogActions> */}
      </Dialog>
    </>
  );
}
