import React from 'react';
import { Container, Typography, Box, Button } from '@mui/material';
import BuildIcon from '@mui/icons-material/Build';
import NavPro from '../Navigation/NavPro';
// import NavPro from '../../Navigation/NavPro'
export default function Ressource() {
  return (
    <>
      <NavPro />
      <Container maxWidth="md" style={styles.container}>

        <Box textAlign="center" mt={5}>
          <BuildIcon style={styles.icon} fontSize="large" />
          <Typography variant="h3" style={styles.title}>
            Page en construction
          </Typography>
          <Typography variant="body1" style={styles.message}>
            Nous travaillons actuellement sur cette page. Merci de revenir plus tard !
          </Typography>
        </Box>
      </Container>
    </>

  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    textAlign: 'center',
    marginTop: '-130px'
    // backgroundColor: '#f7f7f7',
  },
  icon: {
    fontSize: '5rem',
    color: '#F9194F', // Utilisation de la couleur que tu aimes
  },
  title: {
    margin: '1rem 0',
    color: '#333',
  },
  message: {
    marginBottom: '2rem',
    color: '#666',
  },
  button: {
    marginTop: '2rem',
  },
};
