import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { FaUserCog } from "react-icons/fa";
import { daytoString, MonthToString } from "../../event/utils/converDate";
import { typographyTheme } from "../../event/utils/responsiveTypo";
import { useEffect } from "react";
import { futureListEvent } from "../../event/utils/EventListapi";
import { useNavigate } from "react-router-dom";
export default function CardVp({ data }) {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const changePage = ()=>{
    navigate("/evenement/" + data.id)
  }
  return (
    <>
      <Stack
        boxShadow={"0px 0px 10px rgba(0, 0, 0, 0.2)"}
        direction={"row"}
        justifyContent={"space-between"}
        sx={{
          "&:hover": {
            cursor: "pointer",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.4)",
          },
        }}
        onClick={changePage}
      >
        <Stack p={2} gap={1}>
          <Typography sx={typographyTheme.h5}>{data.organisateur}</Typography>
          <Box display="flex" alignItems="center" gap={2}>
            <FaUserCog />
            <Typography sx={typographyTheme.h6}>{data.name}</Typography>
          </Box>
          <Typography sx={typographyTheme.body1}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi, non
            sint officiis
          </Typography>
        </Stack>
        <Stack
          direction={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          bgcolor={"#ff4dd8"}
          color={"white"}
          px={smDown ? 0 : 2}
          minWidth={"75px"}
        >
          <Typography fontWeight={600}>
            {daytoString(new Date(data.date).getDay())}{" "}
          </Typography>
          <Typography fontWeight={600} sx={typographyTheme.h4}>
            {new Date(data.date).getDay()}
          </Typography>

          <Typography
            fontWeight={600}
            sx={typographyTheme.body1}
            textAlign={"center"}
          >
            {`${MonthToString(new Date(data.date).getMonth())}`}{" "}
            {new Date(data.date).getFullYear()}
          </Typography>
        </Stack>
      </Stack>
    </>
  );
}
