import React, { useEffect } from 'react'
import './TempleteFiltre.css'
import { Box, colors, Container, Grid, Typography } from "@mui/material";
import Footer from '../outil/Footer'
import { useParams, NavLink } from 'react-router-dom'
import axios from 'axios'
import { useState } from 'react'
import NavFiltre from '../Navigation/NavFiltre'
import NavMain from '../Navigation/NavMain';
import { useLocation } from 'react-router-dom';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { CircularProgress } from '@mui/material';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const HorizontalLine = () => {
     return (
          <Box
               sx={{
                    width: "100%",
                    height: "1px",
                    backgroundColor: "divider",
                    mt: 3,
                    mb: 3,
               }}
          />
     );
};

export default function Categoriefiltre() {


     const mapContainerStyle = {
          height: "400px",
          width: "800px"
     };

     const center = {
          lat: 48.8566, // Paris
          lng: 2.3522
     };
     const [loading, setLoading] = useState(true);
     const { name, id} = useParams();

     const [results, setResults] = useState([]);
     const [filtrecon, setfiltre] = useState([]);
     const [error, setError] = useState('');

   


     const filtrecat = async () => {

          try {
               const response = await axios.get('https://www.api-macerise.masovia-madagascar.com/api/salons/filter/' + id);
               setfiltre(response.data.data);
               setResults('')
               setError('');
               console.log(response.data.data)
          } catch (error) {
               console.log(error.response.data.message)
               setError(error.response.data.message);
               setfiltre('');
          }

     };
     useEffect(() => {
          if (id !== null) {
               filtrecat()
              
          }
  
     }, [id])

   
    


  





    
     useEffect(() => {
          window.scroll({ top: 0 })


     }, [])
     const [tabimage, settab] = useState([]);
     const [firstimg, setfirst] = useState('');
     const [second, settow] = useState('');
     const [free, setfree] = useState('');
     const [imageData, setImageData] = useState([]); // État pour les images

     const gallery = async (id) => {
       


          try {
               const response = await axios.get( `https://www.api-macerise.masovia-madagascar.com/api/salons/${id}/pictures`
               );
               setImageData(prevData => ({ ...prevData, [id]: response.data }));
          
          } catch (err) {
               console.error('Error occurred:', err);
          }
        
     };
     const handleImageLoad = () => {
         
        }
     return (
          <>
                <NavMain  />
               <div className='TempleteFiltre' onLoad={handleImageLoad}>

                    <p><i className='ti-home'></i>  Categorie : {name} </p>
                    <div className='redddd'>{error}</div>
                    {

                         filtrecon.length > 0 ?
                              filtrecon.map((salon, key) => {
                                   { gallery(salon.id) }
                                   const images = imageData[salon.id] || [];
                                   return (

                                        <div className='cardsFiltre' key={key}>

                                             <div className='leftFiltre'>
                                                  <h4>{salon.name}</h4>

                                                  <div className='imgFiltre'>
                                                       {images.length > 0 && (
                                                            <>
                                                                 <img src={`https://api-macerise.masovia-madagascar.com/storage/${images[0]?.image}`} alt='' className='oneImg'     />
                                                                 <img src={`https://api-macerise.masovia-madagascar.com/storage/${images[1]?.image}`} alt='' className='towImg' />
                                                                 <img src={`https://api-macerise.masovia-madagascar.com/storage/${images[2]?.image}`} alt='' className='freeImg' />
                                                            </>
                                                       )}
                                                  </div>
                                                  <div className='descriptionfiltre'>
                                                       <p className='titre'>{salon.name}</p>
                                                       <p className='avis'><i className='ti-star'></i><i className='ti-star'></i><i className='ti-star'></i><i className='ti-star'></i><i className='ti-star'></i> {salon.notes_count} avis</p>
                                                       <p className='payse'>{salon.city} </p>
                                                       <p className='expert'><i className='ti-home'></i> Chez votre expert</p>

                                                  </div>
                                                  <div className='filtrejour'>
                                                       <div className='filtresem1'>
                                                            <h4>MATIN</h4>
                                                            <button className='select'>Mar 23</button>
                                                            <button className='select'>Mer 24</button>
                                                            <button className='select'>Jeu 25</button>
                                                            <button className='select'>Ven 26</button>
                                                       </div>
                                                       <div className='filtresem1 '>
                                                            <h4>APRES-MIDI</h4>
                                                            <button className='select'>Mar 23</button>

                                                            <button className='select'>Jeu 25</button>
                                                            <button className='select'>Ven 26</button>
                                                            <button className='select'>Ven 26</button>
                                                       </div>
                                                       <div className='filtresem1 '>
                                                            <h4>SOIR</h4>

                                                            <button className='select'>Jeu 25</button>
                                                            <button className='select'>Ven 26</button>
                                                       </div>
                                                  </div>

                                                  <NavLink
                                                       className='plusdetaille'
                                                       to={`/detailsalon/${salon.id}`}

                                                  >


                                                       <p>

                                                            Je veux en savoir plus

                                                       </p>
                                                       <i className='ti-angle-down'></i>


                                                  </NavLink>






                                             </div>

                                        </div>



                                   )
                              })
                              : '....'
                    }
                  
                    

                    <div className='rightFiltre'>
                         <iframe
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.9503398796586!2d-73.98531003867917!3d40.7594371793141!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c259a3f711cf39%3A0x6c38ba916571686d!2sEmpire%20State%20Building!5e0!3m2!1sen!2sus!4v1624681500506!5m2!1sen!2sus"
                              width="100%"
                              height="500px"
                              frameBorder="0"
                              style={{ border: 0 }}
                              allowFullScreen
                              loading="lazy"
                              referrerPolicy="no-referrer-when-downgrade"
                         />
                    </div>
                  

               </div>
               <Footer />
          </>    
     )
}
