// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Muli&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Quicksand&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*{
  padding: 0;
  margin: 0;
  font-family: 'Inter', sans-serif;

}
body{
  max-width: 100vw;
 /* overflow-x: hidden;*/
}
button:hover{
  scale: 0.99;
  cursor: pointer;
  opacity: 0.9;
}
img{
  object-fit: cover;
}
p{
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;EACE,UAAU;EACV,SAAS;EACT,gCAAgC;;AAElC;AACA;EACE,gBAAgB;CACjB,uBAAuB;AACxB;AACA;EACE,WAAW;EACX,eAAe;EACf,YAAY;AACd;AACA;EACE,iBAAiB;AACnB;AACA;EACE,SAAS;AACX","sourcesContent":["@import url('https://fonts.googleapis.com/css?family=Muli&display=swap');\n@import url('https://fonts.googleapis.com/css?family=Quicksand&display=swap');\n*{\n  padding: 0;\n  margin: 0;\n  font-family: 'Inter', sans-serif;\n\n}\nbody{\n  max-width: 100vw;\n /* overflow-x: hidden;*/\n}\nbutton:hover{\n  scale: 0.99;\n  cursor: pointer;\n  opacity: 0.9;\n}\nimg{\n  object-fit: cover;\n}\np{\n  margin: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
