import axios from "axios";

export async function getOneEvent({ id }) {
  try {
    const response = await axios.get(
      `https://api-macerise.masovia-madagascar.com/api/evenment/${id}`
    );
    return response;
  } catch (error) {
    throw error;
  }
}

/**
 *
 * @param {*} evenementId
 * @param {*} nbPlace
 * @returns messageObject
 */
export async function ReserverPlace(evenementId, nbPlace) {
  const token = sessionStorage.getItem("token");
  try {
    const response = await axios.post(
      `https://api-macerise.masovia-madagascar.com/api/event_appointment/${evenementId}`,
      {
        nombrePlaceReserve: nbPlace,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
}
/**
 *
 * @param {*} id id du reservation
 * @returns
 */
export async function payementStripe(id) {
  const token = sessionStorage.getItem("token");
  try {
    const response = await axios.post(
      `https://api-macerise.masovia-madagascar.com/api/event_payment/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
}
