import React, { useState, useEffect } from 'react';
import { salon_recent } from '../../authentification/Authentificationl';
import './NouveauPro.css';
import axios from 'axios';
import { NavLink } from 'react-router-dom';

export default function NouveauPro() {
    const [salon, setSalon] = useState([]);
    const [followingStatus, setFollowingStatus] = useState({});
    const [voire, setVoir] = useState([]);
    const token = sessionStorage.getItem('token');
    const idUser = sessionStorage.getItem('userId');

    const fetchSalons = async () => {
        try {
            const userData = await salon_recent();
            setSalon(userData.salons);
             console.log(userData)
        } catch (error) {
            console.log(error);
        }
    };

    const toggleFollow = async (id) => {
        try {
            await axios.post(
                `https://www.api-macerise.masovia-madagascar.com/api/toggle-follow/${id}`,
                {},
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                }
            );

            // Inversez le statut de suivi
            setFollowingStatus(prev => ({
                ...prev,
                [id]: !prev[id],
            }));

        } catch (err) {
            console.error('Erreur lors de la requête');
        }
    };

    const fetchFollowing = async () => {
        try {
            const response = await axios.get(
                `https://www.api-macerise.masovia-madagascar.com/api/client_following`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                }
            );
            setVoir(response.data);

            const followingMap = {};
            response.data.forEach(voir => {
                followingMap[voir.pivot.masseur_id] = true;
            });
            setFollowingStatus(followingMap);

        } catch (err) {
            console.error('Erreur lors de la requête');
        }
    };

    useEffect(() => {
        fetchFollowing();
        fetchSalons();
    }, []);

    return (
        <div className='NeauveauPro'>
            <h4> Nouveaux Pro</h4>
            <div className='NeauveauProImg'>
                {salon.length > 0 ? salon.map(event => (
                    <div className='card' key={event.id}>
                        <div className='suivre' onClick={() => toggleFollow(event.id)}>
                            {followingStatus[event.id] ? <p>Suivi(e)</p> : <p>+ Suivre</p>}
                        </div>
                        <NavLink to={`/detailsalon/${event.id}`}>
                            <div className='logoPro'>
                                <p><img  src={`https://api-macerise.masovia-madagascar.com/storage/salon_logo/${event.logo}`} alt='' /></p>
                            </div>
                            <img src={`https://api-macerise.masovia-madagascar.com/storage/salon_images/${event.images}`} alt='image' className='font' />
                            <div className='description'>
                                <p className='titre'>{event.name}</p>
                            </div>
                        </NavLink>
                    </div>
                )) : ''}
            </div>
        </div>
    );
}
