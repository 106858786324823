import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
  Stack,
  TextField,
} from "@mui/material";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { useParams } from "react-router-dom";
import { useFieldArray, useForm } from "react-hook-form";
import { ajoutEmployer } from "../../api/employer";
import { toast } from "sonner";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function DialogAjout({
  open,
  setOpen,
  setData,
  setSubmitChange,
}) {
  const {
    handleSubmit,
    register,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });
  const fields = [
    { label: "nom", name: "name", type: "text" },
    { label: "prénom", name: "lastname", type: "text" },
    { label: "date de naisssance", name: "ddn", type: "date" },
    { label: "adresse", name: "address", type: "text" },
    { label: "CIN", name: "CIN", type: "text" },
  ];
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    reset();
  };
  const onSubmit = (data) => {
    console.log(data);
    const ids = sessionStorage.getItem("idsalon");
    const dataEdited = { ...data, salon_id: ids };
    console.log("Real Data", dataEdited);
    ajoutEmployer(dataEdited)
      .then((res) => {
        console.log(res.data);
        setSubmitChange((v) => !v);
        handleClose()
        toast.success("Employer ajouter avec succes", {
          position: "top-center",
        });
        handleClose();
      })
      .catch((err) => {
        toast.error("Erreur lors de l'ajout de l'employer", {
          position: "top-center",
        });
        console.log("Erreur", err);
      });
    // setData((prev) => [...prev, data]);
  };
  return (
    <>
      <Dialog
        onClose={handleClose}
        fullWidth={true}
        // aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="sm"
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Ajouter un employer
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Stack
            component={"form"}
            onSubmit={handleSubmit(onSubmit)}
            direction={"column"}
            gap={2}
          >
            {fields.map((field, i) => {
              return (
                <TextField
                  key={i}
                  {...register(field.name, { rules: "this field is required" })}
                  label={field.label}
                  InputLabelProps={{
                    shrink: field.name === "ddn" ? true : undefined,
                  }}
                  type={field.type || null}
                />
              );
            })}

            <Button
              sx={{
                color: "white",
                bgcolor: "#F9194F",
                "&:hover": {
                  bgcolor: "#000",
                  color: "#fff",
                  border: "none",
                },

                // width: "fit-content",
              }}
              type="submit"
            >
              Valider
            </Button>
          </Stack>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose}>Fermer</Button>
        </DialogActions> */}
      </Dialog>
    </>
  );
}
