export const typographyTheme = {
  h1: {
    fontSize: '48px',
    lineHeight: '56px', // Ligne légèrement plus grande pour un meilleur espacement
    '@media (max-width:600px)': {
      fontSize: '32px',
      lineHeight: '38px',
    },
  },
  h2: {
    fontSize: '36px',
    lineHeight: '42px',
    '@media (max-width:600px)': {
      fontSize: '28px',
      lineHeight: '34px',
    },
  },
  h3: {
    fontSize: '30px',
    lineHeight: '36px',
    '@media (max-width:600px)': {
      fontSize: '24px',
      lineHeight: '30px',
    },
  },
  h4: {
    fontSize: '24px',
    lineHeight: '30px',
    '@media (max-width:600px)': {
      fontSize: '20px',
      lineHeight: '26px',
    },
  },
  h5: {
    fontSize: '20px',
    lineHeight: '26px',
    '@media (max-width:600px)': {
      fontSize: '18px',
      lineHeight: '24px',
    },
  },
  h6: {
    fontSize: '16px',
    lineHeight: '22px',
    '@media (max-width:600px)': {
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
  body1: {
    fontSize: '16px',
    lineHeight: '24px', // Bonne lisibilité pour les paragraphes
    '@media (max-width:600px)': {
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
  body2: {
    fontSize: '14px',
    lineHeight: '20px',
    '@media (max-width:600px)': {
      fontSize: '12px',
      lineHeight: '18px',
    },
  },
  caption: {
    fontSize: '12px',
    lineHeight: '16px',
  },
};