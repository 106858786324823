import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { logout } from "../../authentification/Authentificationl";
import "./Dashb.css";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
} from "@mui/material";
import ProfilDash from "./ProfilDash";
import Calendrier from "./Calendrier/Calendrier";
import TableRdv from "./TableRdv/TableRdv";
import ServiceMass from "../outil/serviMass/ServiceMass";
import Questions from "./FAQ/Questions";
import Evenent from "./Evenent";
import Admasseur from "./Admasseur";
import Masse from "./Masse/Masse";
import ProfilMass from "./proflMasseur/ProfilMass";
import Salon from './salon/Salon'
import Abonnement from './abonnement/Abonnement'
// import Salon from "./salon/Salon";
import NewProfil from "./newProfil";
// import Repon from "./FAQ/Repon";
const Dashbord = () => {
  const role = sessionStorage.getItem("Role");
  console.log(role);
  // Function to toggle the sidebar (assumes you have this function defined elsewhere)
  const toggelSidebar = () => {
    // Logic to toggle the sidebar
  };

  const [modal, setModal] = useState(false);
  const [token, Settoken] = useState(false);
  const navigate = useNavigate();

  function deconecter() {
    setModal(true);
  }
  const handledeconnection = () => {
    logout();
    navigate("/tarif");
  };
  const handleCloseDel = () => {
    setModal(false);
  };
  const [affichage, setAffichage] = useState()
  useEffect(()=>{
    role === "admin" ? setAffichage("profil") : setAffichage(["calendrier", "serviceMassage", "tableau","masse",'abonnement'])
  }, [])
  const toogleComposantProf = () => {
    setAffichage("profil");
  };
  const toogleComposantCal = () => {
    setAffichage("calendrier");
  };
  const toogleComposantTab = () => {
    setAffichage("tableau");
  };
  const toogleComposantServM = () => {
    setAffichage("serviceMassage");
  };
  const toogleComposantquestion = () => {
    setAffichage("question");
  };
  const toogleComposantSalon = () => {
    setAffichage("salon");
  };
  const toogleComposantEvent = () => {
    setAffichage("evenent");
  };
  const toogleComposantMassList = () => {
    setAffichage("masseur");
  };
  const toogleComposantMass = () => {
    setAffichage("masse");
  };
  const toogleComposantPrMass = () => {
    setAffichage("profilMasseur");
  };
  const toogleComposantAbonne = () => {
    setAffichage("abonnement");
  };

  useEffect(() => {
    if (role === 'admin') {
      setAffichage('profil')
    } else {
      setAffichage('salon')
    }
  }, [])
  return (
    <>
      <div className="app-admin-wrap layout-sidebar-compact sidebar-dark-purple sidenav-open clearfix">
        <div className="side-content-wrap">
          <Box
            className="sidebar-left-secondary rtl-ps-none"
            data-perfect-scrollbar
            data-suppress-scroll-x="true"

          >
            <i className="sidebar-close i-Close" onClick={toggelSidebar}></i>
            <header>
              <Box
                className="sidebar-left-secondary rtl-ps-none"
                data-perfect-scrollbar
                data-suppress-scroll-x="true"
                sx={{ width: { xs: '10px', sm: '0px', md: '0px' },height:'100%' }}
              
              >
                <img
                  style={{
                    width: "200px",
                    height: "160px",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    position: "relative",
                    backgroundSize: "center",
                    top: "-40px",
                  }}
                  src="logomacerise.png"
                  alt="Logo"
                />
                <ul
                  className="navigation-left"
                  style={{
                    background: "#F9194F",
                    display: "flex",
                    flexDirection: "column",
                    gap: "50px",
                    paddingTop: "50px",
                    marginTop: "-100px",
                    listStyleType: "none",
                    height: "100%",
                  }}
                >
                  {/* Admin Links */}
                  {role === "admin" && (
                    <>


                      <li
                        className={`nav-item ${affichage === "profil" ? "active" : ""
                          }`}
                      >
                        <div
                          className="nav-item-hold acc"
                          onClick={toogleComposantProf}
                        >
                          <div
                            className="nav-text"
                            style={{
                              marginLeft: "50px",
                              color: "white",
                              cursor: "pointer",
                              paddingTop: "10px",
                            }}
                          >
                            Dashboard
                          </div>
                        </div>
                        <div className="triangle"></div>
                      </li>
                      <li
                        className={`nav-item ${
                          affichage === "masseur" ? "active" : ""
                        }`}
                        style={{
                          color: "white",
                          cursor: "pointer",
                          paddingTop: "10px",
                        }}
                      >
                        <div
                          className="nav-item-hold list_rdv"
                          onClick={toogleComposantMassList}
                        >
                          <span
                            className="nav-text"
                            style={{ marginLeft: "50px", color: "white" }}
                          >
                            Masseur
                          </span>
                        </div>
                        <div className="triangle"></div>
                      </li>
                      <li
                        className={`nav-item ${
                          affichage === "evenent" ? "active" : ""
                        }`}
                        style={{
                          color: "white",
                          cursor: "pointer",
                          paddingTop: "10px",
                        }}
                      >
                        <div
                          className="nav-item-hold list_rdv"
                          onClick={toogleComposantEvent}
                        >
                          <span
                            className="nav-text"
                            style={{ marginLeft: "50px", color: "white" }}
                          >
                            Evenement
                          </span>
                        </div>
                        <div className="triangle"></div>
                      </li>
                      <li
                        className={`nav-item ${
                          affichage === "question" ? "active" : ""
                        }`}
                        style={{
                          color: "white",
                          cursor: "pointer",
                          paddingTop: "10px",
                        }}
                      >
                        <div
                          className="nav-item-hold list_rdv"
                          onClick={toogleComposantquestion}
                        >
                          <span
                            className="nav-text"
                            style={{ marginLeft: "50px", color: "white" }}
                          >
                            FAQ
                          </span>
                        </div>
                        <div className="triangle"></div>
                      </li>
                    </>
                  )}

                  {/* Masseur Links */}
                  {role !== "admin" && (
                    <>
                      <li
                        className={`nav-item ${affichage === "salon" ? "active" : ""
                          }`}
                      >
                        <div
                          className="nav-item-hold list_rdv"
                          onClick={toogleComposantSalon}
                          style={{ width: "100%", cursor: "pointer" }}
                        >
                          <span
                            className="nav-text"
                            style={{ marginLeft: "50px", color: "white" }}
                          >
                            Salon
                          </span>
                        </div>
                        <div className="triangle"></div>
                      </li>
                      <li
                        className={`nav-item ${affichage === "calendrier" ? "active" : ""
                          }`}
                      >
                        <div
                          className="nav-item-hold calix"
                          onClick={toogleComposantCal}
                        >
                          <div
                            className="nav-text"
                            style={{
                              marginLeft: "50px",
                              color: "white",
                              cursor: "pointer",
                              paddingTop: "10px",
                            }}
                          >
                            Calendrier
                          </div>
                        </div>
                        <div className="triangle"></div>
                      </li>
                      <li
                        className={`nav-item ${affichage === "tableau" ? "active" : ""
                          }`}
                      >
                        <div
                          className="nav-item-hold reservation"
                          onClick={toogleComposantTab}
                          style={{ width: "100%", cursor: "pointer" }}
                        >
                          <div
                            className="nav-text"
                            style={{
                              marginLeft: "50px",
                              color: "white",
                              cursor: "pointer",
                              paddingTop: "10px",
                            }}
                          >
                            Vos reservations
                          </div>
                        </div>
                        <div className="triangle"></div>
                      </li>

                      <li
                        className={`nav-item ${affichage === "abonnement" ? "active" : ""
                          }`}
                      >
                        <div
                          className="nav-item-hold reservation"
                          onClick={toogleComposantAbonne}
                          style={{ width: "100%", cursor: "pointer" }}
                        >
                          <div
                            className="nav-text"
                            style={{
                              marginLeft: "50px",
                              color: "white",
                              cursor: "pointer",
                              paddingTop: "10px",
                            }}
                          >
                            Abonnement
                          </div>
                        </div>
                        <div className="triangle"></div>
                      </li>

                      <li
                        className={`nav-item ${
                          affichage === "profilMasseur" ? "active" : ""
                        }`}
                      >
                        <div
                          className="nav-item-hold reservation"
                          onClick={toogleComposantPrMass}
                          style={{ width: "100%", cursor: "pointer" }}
                        >
                          <div
                            className="nav-text"
                            style={{
                              marginLeft: "50px",
                              color: "white",
                              cursor: "pointer",
                              paddingTop: "10px",
                            }}
                          >
                            Profil
                          </div>
                        </div>
                        <div className="triangle"></div>
                      </li>

                      <li
                        className={`nav-item ${affichage === "serviceMassage" ? "active" : ""
                          }`}
                      >
                        <div
                          className="nav-item-hold list_rdv"
                          onClick={toogleComposantServM}
                          style={{ width: "100%", cursor: "pointer" }}
                        >
                          <span
                            className="nav-text"
                            style={{ marginLeft: "50px", color: "white" }}
                          >
                            Vos services
                          </span>
                        </div>
                        <div className="triangle"></div>
                      </li>

                      {/* <li
                        className={`nav-item ${affichage === "masse" ? "active" : ""
                          }`}
                      >
                        <div
                          className="nav-item-hold list_rdv"
                          onClick={toogleComposantMass}
                          style={{ width: "100%", cursor: "pointer" }}
                        >
                          <span
                            className="nav-text"
                            style={{ marginLeft: "50px", color: "white" }}
                          >
                            Message
                          </span>
                        </div>
                        <div className="triangle"></div>
                      </li> */}
                    </>
                  )}

                  <li className="nav-item" data-item="others">
                    <div
                      onClick={deconecter}
                      className="nav-item-hold power"
                      href="#"
                    >
                      <div
                        className="nav-text"
                        style={{
                          marginLeft: "50px",
                          color: "white",
                          cursor: "pointer",
                          paddingTop: "10px",
                        }}
                      >
                        Exit
                      </div>
                    </div>
                    <div className="triangle"></div>
                  </li>
                </ul>
              </Box>
            </header>
            <div className="submenu-area" data-parent="dashboard"></div>
          </Box>
          <Box
            sx={{
              // background: "red",
              width: {
                xs: "100%", // 100% de largeur sur les petits écrans (mobile)
                sm: "90%", // 90% sur les écrans un peu plus grands (tablette)
                md: "84%", // 84% sur les écrans moyens et grands (desktop)
              },
              height: "100%",
              marginLeft: {
                xs: "100px", // Aucun margin-left sur les petits écrans
                sm: "100px", // Ajustement sur les écrans moyens
                md: "240px", // 240px sur les grands écrans
              },
            }}
          >
            {/* Conditional Rendering for Components */}
            {affichage === "profil" && <NewProfil />}
            {affichage === "calendrier" && <Calendrier />}
            {affichage === "tableau" && <TableRdv />}
            {affichage === "serviceMassage" && <ServiceMass />}
            {affichage === "evenent" && <Evenent />}
            {affichage === "question" && <Questions />}
            {affichage === "masseur" && <Admasseur />}
            {affichage === "masse" && <Masse />}
            {affichage === "profilMasseur" && <ProfilMass />}
            {affichage === "salon" && <Salon />}
            {affichage === 'abonnement' && <Abonnement/>}
          </Box>
        </div>

        {/* Logout Modal */}
        <Dialog open={modal} onClose={handleCloseDel}>
          <DialogTitle>Deconnection</DialogTitle>
          <DialogContent>
            <Typography variant="h6">
              Voulez-vous vraiment vous déconnecter ?
            </Typography>
            <Button
              variant="contained"
              className="buton"
              sx={{ background: "#F9194F" }}
              onClick={handledeconnection}
            >
              Oui
            </Button>
            <Button
              onClick={handleCloseDel}
              variant="contained"
              className="buton"
              sx={{ background: "#F9194F", marginLeft: "300px" }}
            >
              Non
            </Button>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default Dashbord;
