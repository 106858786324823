import {React,useEffect,useState } from "react";
import "../suitTarif/Offres.css";
const Offres = () => {
  const [animate,setAnimate] = useState(1);
  const handleButtonClick= (buttonId) => {
    setAnimate(buttonId);
    // setAnimate(!isButtonActive)
  };
  
  
   
  return (
    <>
      
    </>
  );
};
export default Offres;
 