import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import {
  Container,
  IconButton,
  Input,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import { Stack } from "@mui/material";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { setProfil } from "../../api/employer";

export default function AvatarInputCell({ data, setSubmit }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        badgeContent={
          <IconButton onClick={() => setOpen(true)}>
            <EditOutlinedIcon fontSize="small" />
          </IconButton>
        }
      >
        <Avatar
          sx={{
            width: {
              xs: 24,
              sm: 24,
              sm: 48,
            },
            height: {
              xs: 24,
              sm: 24,
              sm: 48,
            },
          }}
          src={`https://api-macerise.masovia-madagascar.com/storage/employee_image/${data.image}`}
        />
      </Badge>
      <ModalAvatar
        open={open}
        setOpen={setOpen}
        data={data}
        setSubmit={setSubmit}
      />
    </>
  );
}

export function ModalAvatar({ open, setOpen, data, setSubmit }) {
  const fileInputRef = useRef(null);
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const [image, setImage] = useState(null);
  const [file, setFile] = useState(null);

  useEffect(() => {
    setImage(data.image);
  }, []);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result); // Met à jour l'état avec l'image choisie
      };
      reader.readAsDataURL(file); // Lit le fichier en tant que URL de données
      setFile(file);
    }
  };
  const handleEditClick = () => {
    fileInputRef.current.click(); // Simule un clic sur le champ de fichier
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const submit = () => {
    const formData = new FormData();
    formData.append("image", file);
    console.log("Data en forme", formData);

    setProfil(data.id, formData)
      .then((res) => {
        setSubmit((v) => !v);
        handleClose();
        console.log("res Json", res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Dialog
      onClose={handleClose}
      fullWidth={true}
      // aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth="sm"
    >
      <DialogTitle>Ajouter un profil</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={(theme) => ({
          position: "absolute",
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Stack justifyContent={"center"} alignItems={"center"}>
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={
              <IconButton onClick={() => handleEditClick()}>
                <AddAPhotoIcon
                  fontSize={mdDown ? "small" : "large"}
                  color="black"
                />
              </IconButton>
            }
          >
            <Avatar sx={{ width: "25vw", height: "25vw" }} src={image} />
          </Badge>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            accept="image/*"
            onChange={handleImageChange}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={submit}
          sx={{
            color: "#F9194F",
            bgcolor: "#fff"
          }}
        >
          Enregistrer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
