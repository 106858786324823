import NewProfil from "./newProfil";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import TableChartIcon from "@mui/icons-material/TableChart";
import EventIcon from "@mui/icons-material/Event";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import PeopleIcon from "@mui/icons-material/People";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import HomeIcon from "@mui/icons-material/Home";
import Calendrier from "./Calendrier/Calendrier";
import Evenent from "./Evenent";
import Questions from "./FAQ/Questions";
import Admasseur from "./Admasseur";
import Masse from "./Masse/Masse";
import ProfilMass from "./proflMasseur/ProfilMass";
import Salon from "./salon/Salon";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import TableRdv from "./TableRdv/TableRdv";
import Abonnement from './abonnement/Abonnement'
import Categori from "./categori/Categori";
import { History, AttachMoney } from "@mui/icons-material";
import Transaction from "./Transaction/Transaction";
export const routesUrlAdmin = [
  {
    name: "dashboard",
    component: <NewProfil />,
    icon: <AccountCircleIcon />,
    url: "/profil",
    active: false,
  },

  {
    name: "masseurs",
    component: <Admasseur />,
    icon: <PeopleIcon />,
    url: "/masseur",
    active: false,
  },

  {
    name: "événements",
    component: <Evenent />,
    icon: <EventIcon />,
    url: "/evenent",
    active: false,
  },
  { 
    name: "catégories",
    component: <Categori />,
    icon: <QuestionAnswerIcon />,
    url: "/categori",
    active: false,
  },
  {
    name: "questions",
    component: <Questions />,
    icon: <QuestionAnswerIcon />,
    url: "/question",
    active: false,
  }, 
  {
    name: "deconnexion",
    icon: <LogoutOutlinedIcon />,
    url: "/masse",
  },
];

export const routesUrlMasseur = [
  {
    name: "site",
    component: <Salon />,
    icon: <HomeIcon />,
    url: "/salon",
    active: false,
  },
  {
    name: "abonnement",
    component: <Abonnement />,
    icon: <CalendarTodayIcon />,
    url: "/abonnement",
    active: false,
  },
  {
    name: "transaction",
    component: <Transaction/>,
    icon: <AttachMoney />,
    url: "/transaction",
    active: false,
  },
 
  {
    name: "profil",
    component: <ProfilMass />,
    icon: <AssignmentIndIcon />,
    url: "/profilMasseur",
    active: false,
  },

  {
    name: "message",
    component: <Masse />,
    icon: <HomeIcon />,
    url: "/masse",
    active: false,
  },
  {
    name: "deconnexion",
    icon: <LogoutOutlinedIcon />,
    url: "/masse",
  },
];
