import { Box, Skeleton } from "@mui/material";

export default function SkeletonCardEvent() {
  return (
    // <Box sx={{ marginLeft: 1 }}>
      <Skeleton
        variant="rectangular"
        maxWidth={"700px"}
        minWidth={"300px"}
        height={"300px"}
        sx={{
          width: {
            xs: "600px",
            sm: "100%",
            md: "100%",
            lg: "48%",
          },
        }}
      ></Skeleton>
    // </Box>
  );
}
