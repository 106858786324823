// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `   @media (max-width: 600px) {

       .right table,
       .right thead,
       .right tbody,
       .right th,
       .right td,
       .right tr {
           display: block;
       }

       .right th {
           position: absolute;
           top: -9999px;
           left: -9999px;
       }

       .right tr {
           margin-bottom: 10px;
       }

       .right td {
           text-align: right;
           position: relative;
           padding-left: 50%;
       }

       .right td::before {
           position: absolute;
           left: 0;
           width: 50%;
           padding-left: 10px;
           white-space: nowrap;
           text-align: left;
           font-weight: bold;

       }

       .right td:nth-of-type(1)::before {
           content: 'Dates :';
       }

       .right td:nth-of-type(2)::before {
           content: 'Heurs :';
       }

       .right td:nth-of-type(3)::before {
           content: 'Status :';
       }

       .right td:nth-of-type(4)::before {
           content: 'Services :';
       }

       .right td:nth-of-type(5)::before {
           content: 'Duration :';
       }

       .right td:nth-of-type(6)::before {
           content: 'Price :';
       }
   }`, "",{"version":3,"sources":["webpack://./src/component/outil/Reservation.css"],"names":[],"mappings":"GAAG;;OAEI;;;;;;WAMI,cAAc;OAClB;;OAEA;WACI,kBAAkB;WAClB,YAAY;WACZ,aAAa;OACjB;;OAEA;WACI,mBAAmB;OACvB;;OAEA;WACI,iBAAiB;WACjB,kBAAkB;WAClB,iBAAiB;OACrB;;OAEA;WACI,kBAAkB;WAClB,OAAO;WACP,UAAU;WACV,kBAAkB;WAClB,mBAAmB;WACnB,gBAAgB;WAChB,iBAAiB;;OAErB;;OAEA;WACI,kBAAkB;OACtB;;OAEA;WACI,kBAAkB;OACtB;;OAEA;WACI,mBAAmB;OACvB;;OAEA;WACI,qBAAqB;OACzB;;OAEA;WACI,qBAAqB;OACzB;;OAEA;WACI,kBAAkB;OACtB;GACJ","sourcesContent":["   @media (max-width: 600px) {\n\n       .right table,\n       .right thead,\n       .right tbody,\n       .right th,\n       .right td,\n       .right tr {\n           display: block;\n       }\n\n       .right th {\n           position: absolute;\n           top: -9999px;\n           left: -9999px;\n       }\n\n       .right tr {\n           margin-bottom: 10px;\n       }\n\n       .right td {\n           text-align: right;\n           position: relative;\n           padding-left: 50%;\n       }\n\n       .right td::before {\n           position: absolute;\n           left: 0;\n           width: 50%;\n           padding-left: 10px;\n           white-space: nowrap;\n           text-align: left;\n           font-weight: bold;\n\n       }\n\n       .right td:nth-of-type(1)::before {\n           content: 'Dates :';\n       }\n\n       .right td:nth-of-type(2)::before {\n           content: 'Heurs :';\n       }\n\n       .right td:nth-of-type(3)::before {\n           content: 'Status :';\n       }\n\n       .right td:nth-of-type(4)::before {\n           content: 'Services :';\n       }\n\n       .right td:nth-of-type(5)::before {\n           content: 'Duration :';\n       }\n\n       .right td:nth-of-type(6)::before {\n           content: 'Price :';\n       }\n   }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
