
import { useEffect, useRef, useState } from 'react'
import './Recommander.css'
import { recommendes } from '../../authentification/Authentificationl';
import { NavLink } from 'react-router-dom';
export default function Recommander() {
  const [salon, setsalon] = useState([]);
  const [lengths, setlength] = useState([]);
  const funsalon = async () => {


    try {
      const userData = await recommendes();

      setsalon(userData);
      setlength(salon)
      //  console.log('Connexion réussie', userData );      
      // Vous pouvez rediriger l'utilisateur ou rafraîchir le state ici
      //  console.log(userData.salons.length)
    } catch (error) {
      console.log(error)

    }
  };
  const cards = useRef(null);
  const btnleft = useRef(null);
  const btnright = useRef(null);

  let scrol = 0;
  let i = 0;
  useEffect(() => {
    funsalon();
  }, [])
  useEffect(() => {

    if (scrol > 0) {
      btnright.current.style.cursor = 'pointer'
    }


  }, [scrol]);


  const windowWidth = window.innerWidth;


  if (windowWidth < 900) {
    if (salon.salons) {
      scrol = salon.salons.length - 1;
    }
  } else {
    if (salon.salons) {
      scrol = salon.salons.length - 5;
    }
  }

  function tranRight() {

    if (scrol > 0) {
      btnleft.current.style.opacity = '1'
      btnleft.current.style.cursor = 'pointer'
      btnright.current.style.cursor = 'pointer'
      if (windowWidth < 900) {
        i = i + 95;
      } else {
        i = i + 19.5;
      }
      cards.current.style.transform = 'translateX(-' + i + 'vw)';

      scrol--

    }
    if (scrol === 0) {
      btnright.current.style.opacity = '0.1'
      btnright.current.style.cursor = 'default'
    }
    //console.log('i'+i)
    // console.log(' scrol' + scrol)
  }
  function tranLeft() {
    if (i > 0) {
      btnright.current.style.cursor = 'pointer'
      btnright.current.style.opacity = '1'
      if (windowWidth < 900) {
        i -= 95
      } else {
        i -= 19.5;
      }
      cards.current.style.transform = 'translateX(-' + i + 'vw)'
      scrol++
    }
    if (i === 0) {
      if (windowWidth < 900) {
        if (salon.salons) {
          scrol = salon.salons.length - 1;
        }
      } else {
        if (salon.salons) {
          scrol = salon.salons.length - 5;
        }
      };
      btnleft.current.style.opacity = '0.1'
      btnleft.current.style.cursor = 'default'
    }
    //console.log('i'+i)
    //console.log(' scrol' + scrol)
  }


  const mapp = [1, 2, 3, 4, 5];
  //console.log(mapp)

  return (
    <div className='Recommander'>

      <h4>Recommandés pour vous</h4>

      <div className='RecommanderImg ' >
        <button ref={btnleft} className='moins left' onClick={tranLeft}><i className='ti-angle-left'></i></button>
        <button ref={btnright} className='plus right' onClick={tranRight}><i className='ti-angle-right'></i></button>
        <div className='cards' ref={cards}>

          {

            salon.salons ?

              salon.salons.map((salon, key) => {
                return (
                  <NavLink
                    key={key}
                    to={`/detailsalon/${salon.id}`}>
                    <img src={`https://api-macerise.masovia-madagascar.com/storage/salon_images/${salon.images}`} alt='image' key={key} ></img>


                  </NavLink>
                )
              })


              : ''
          }
          {!salon.salons ?
            mapp.map((m) => {
              return (<div key={m} className='img'></div>)
            })




            : ''


          }
        </div>
      </div>
    </div>
  )
}
