import React from 'react'
import axios from 'axios';
import { useEffect, useState } from 'react';
import './Follow.css'
export default function Followers() {
  const [favorisx, setfavoris] = useState([]);
  const favoris = async () => {
    const id = sessionStorage.getItem("idsalon");
    let token = sessionStorage.getItem('token');
    try {
      const res = await axios.get(
        `https://www.api-macerise.masovia-madagascar.com/api/followers/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(res.data);
      setfavoris(res.data)
    } catch (error) {
      console.error("Erreur lors de l'envoi des données à l'API:", error);
    }
  };
  useEffect(() => {
    favoris()

  }, [])
  return (
    <div style={{ padding: '50px 10px' }} className='follow'>
      <h1 className='h1' style={{ padding: ' 10px 0' }}>Listes Des followers</h1>
      <hr style={{ marginBottom: '15px' }} />
      <table>
        <thead>
          <tr>

            <th>Nom</th>
            <th>Tel</th>
            <th>City</th>
            <th>Email</th>
          </tr>
        </thead>
        <tbody>
          {favorisx.length>0?
           favorisx.map((event) => (
              <tr key={event.id}>
                <td>{event.name}</td>
                <td>{event.tel}</td>
                <td>{event.city}</td>
                <td>{event.email}</td>
              </tr>
            ))
            : ""}
        </tbody>
      </table>
      {favorisx.length > 0
        ? ''
        : <div style={{
          display: 'flex',
          alignItems: 'center', justifyContent: 'center', width: '100vw', height: '60vh', fontSize: '25px', fontWeight: '900',
        }}>0 follower</div>}
    </div>
  )
}
