import { React, useRef, useState, useEffect } from "react";
import "./Inscription.css";
import { useNavigate } from "react-router-dom";
import { registerr } from "../../../authentification/Authentificationl";
import { role } from "../../../authentification/Authentificationl";
import { verifi_code } from "../../../authentification/Authentificationl";
import axios from "axios";
import { countries } from "../../../authentification/Authentificationl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
// import "@fortawesome/fontawesome-free/css/all.css";
const Inscription = ({ data }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [address, setAddress] = useState("");
  const [city, setcity] = useState("");
  const [date_de_naissance, setDate_de_naissance] = useState("");
  const [password, setPassword] = useState("");
  const [image, setImage] = useState("");
  const [role_id, setRole] = useState("");
  const [listrole_id, setlistRole] = useState();
  const [phone_verification_code, setPhoneVerificationCode] = useState("");
  const [country_id, setCountry_id] = useState();
  const [load, setload] = useState(false);
  const [error, setError] = useState("");
  const [succ, setSucc] = useState("");
  const [coutry, setcountry] = useState([]);
  const navigate = useNavigate();
  const [showconfirm, setconfirm] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectrole, setSelectrole] = useState("");
  const nutel = useRef("");
  const [messs, setmess] = useState("");
  const [messMDP, setMessMDP] = useState("");
  const [vall, setvall] = useState(false);
  const [countries, setCountries] = useState([]);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get("/countries");
        setCountries(response.data);
      } catch (error) {
        console.error("Erreur de récupération des pays", error);
        setError("Erreur de récupération des pays.");
      }
    };
    fetchCountries();
    const fechrole = async () => {
      try {
        const response = await role();
        setlistRole(response);
        // console.log(response.roles[0].name)
      } catch (error) {
        console.error("Erreur de récupération des pays", error);
        setError("Erreur de récupération des pays.");
      }
    };
    fechrole();
    if (listrole_id) {
      console.log(listrole_id);
    }
    const login = document.querySelector(".login");
    if (login) {
      login.scroll({ top: 0 }); // Faire défiler vers le bas
    }
  }, []);

  const handleSubmit = async (e) => {
    let inp = document.querySelector(".reddd");
    setvall(false);
    e.preventDefault();
    setPhoneVerificationCode("");
    setconfirm("");
    setError("");
    setSucc("");
    setload(true);
    let age = 0;

    // Reformater la date de naissance en format DD-MM-YYYY
    const dob = new Date(date_de_naissance);
    const formattedDate = `${dob.getDate()}-${
      dob.getMonth() + 1
    }-${dob.getFullYear()}`;

    let datee = new Date();
    let years = datee.getFullYear();
    age = years - dob.getFullYear();
    console.log(age);

    if (age >= 18) {
      console.log("date_naissance:", formattedDate); // Utiliser la date formatée ici
      if (showconfirm === password) {
        try {
          const userData = await axios.post("/register", {
            name:name,
            email:email,
            tel:tel,
            address:address,
            date_de_naissance:formattedDate,
            password:password,
            password_confirmation:showconfirm,
            role_id:role_id,
            country_id:country_id,
            phone_verification_code:phone_verification_code,
            city:city,
          });


          sessionStorage.setItem("userId", userData.data.user.id);
          setSucc("Inscription réussie");
          const phone_verification = userData.phone_verification_code;
          console.log(userData)
          if (userData.data.user.role_id == 2) {
            setload(false);
            data(1);
            navigate("/");
            const login = document.querySelector(".login");
            login.style.display = "flex";
          } else {
            setload(false);
            data(3);
          }
        } catch (error) {
          setload(false);
          if (error.response && error.response.status === 422) {
        
          } else {
            console.error(error);
          }
        }
      } else {
        inp.style.border = "1px solid red";
        setvall(true);
        setMessMDP("Confirmation mot de pass incorrecte");
      }
    } else {
      inp.style.border = "1px solid red";
      setvall(true);
      setmess("vous êtes mineur");
    }
    // data(3);
  };

  function hidecode() {
    setconfirm(false);
  }
  function show() {
    data(1);
    const login = document.querySelector(".login");
    if (login) {
      login.scroll({ top: 0 }); // Faire défiler vers le bas
    }
  }
  function fermer() {
    data(1);
    const login = document.querySelector(".login");
    login.style.display = "none";
  }
  return (
    <>
      <div className="inscription-container" style={{width:'500px'}}>
        <div className="boxis-num-1">
          <div className="boxis-num-1-1">Bienvenue sur Macerise</div>
          <div className="boxis-num-1-2">Inscrivez-vous pour commencer.</div>
        </div>
        <div className="boxi-num-2">
          <form onSubmit={handleSubmit}>
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              // required
              type="text"
              className="boxis-num-2-1-S5"
              placeholder="Nom d’utilisateur"
            />

            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              // required

              type="email"
              className="boxis-num-2-1-S5"
              placeholder="Adresse email"
            />
            <input
              id="tel"
              value={tel}
              onChange={(e) => setTel(e.target.value)}
              // required

              type="text"
              className="boxis-num-2-1-S5"
              placeholder="Numero téléphone"
            />
            <input
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              // required

              type="text"
              className="boxis-num-2-1-S5"
              placeholder="address"
            />
            <input
              value={city}
              onChange={(e) => setcity(e.target.value)}
              // required

              type="text"
              className="boxis-num-2-1-S5"
              placeholder="ville"
            />
            <input
              type="date"
              id="date_de_naissance"
              value={date_de_naissance}
              onChange={(e) => setDate_de_naissance(e.target.value)}
              placeholder="Date de naissance jj/mm/aa"
              className="boxis-num-2-22 reddd"
              // required
            />
            {vall ? <p className="redd">{messs}</p> : ""}
            <div className="input-wrapper" style={{position:'relative'}}>
              <input
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type={showPassword ? "text" : "password"}
                placeholder="Mot de passe"
                className="boxis-num-2-2"
              />
              <span
                onClick={() => setShowPassword(!showPassword)}
                style={{
                  position:'absolute',
                  cursor: "pointer",
                  color: "#999",
                  right: "20px",
            
                  top: "30px",
                }}
              >
                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
              </span>
            </div>
            {/* confirmation de mot de pass */}
            {vall ? <p className="redd">{messMDP}</p> : ""}
            <div className="input-wrapper" style={{position:'relative'}}>
              <input
                value={showconfirm}
                onChange={(e) => setconfirm(e.target.value)}
                type={showPassword ? "text" : "password"}
                placeholder="Confirmer le mot de pass"
                className="boxis-num-2-2"
              />
              <p
                onClick={() => setShowPassword(!showPassword)}
                style={{
                  position:'absolute',
                  cursor: "pointer",
                  color: "#999",
                  right: "20px",
               
                  top: "30px",
                }}
              >
                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
              </p>
            </div>

            <select
              // required
              id="role_id"
              value={selectrole}
              className="boxis-num-2-1-S5"
              onChange={(e) => {
                setSelectrole(e.target.value);
                setRole(e.target.value);
              }}
            >
              <option value="">vous êtes:</option>
              {listrole_id
                ? listrole_id.roles.map(
                    (rol) =>
                      rol.name !== "admin" && (
                        <option key={rol.id} value={rol.id}>
                          {rol.name === "client"
                            ? "je cherche un salon de massage"
                            : "je suis un masseur"}
                        </option>
                      )
                  )
                : ""}
            </select>

            <select
              // required
              id="country"
              value={selectedCountry}
              className="boxis-num-2-1-S5"
              onChange={(e) => {
                setSelectedCountry(e.target.value);
                setCountry_id(e.target.value);
              }}
            >
              <option value="">Sélectionnez un pays </option>
              {countries.map((country) => (
                <option key={country.id} value={country.id}>
                  {country.name}
                </option>
              ))}
            </select>

            <button className="boxi-num-3 btnload " type="submit">
              {(role_id == 2) & (load === false) ? " S’inscrire " : "Suivant"}
            </button>
            <p className="error"> {error && <p>{error}</p>}</p>
            <p className="succ"> {succ && <p>{succ}</p>}</p>
          </form>
        </div>

        <div className="boxis-num-4">
          <div className="boxis-num-4-1"></div>
          <div className="boxis-num-4-2">Ou se connecter avec</div>
        </div>
        <div className="boxi-num-5">
          <div className="boxis-num-5-1">
            <div className="boxi-num-5-1-1">
              <img alt="" className="logo-google" src="../sary/logoG.png" />
              <div className="text-google">Google</div>
            </div>
            <div className="boxi-num-5-1-2">
              <img
                alt=""
                className="logo-facebook"
                src="../sary/facebook.jpg"
              />
              <div className="text-facebook">Facebook</div>
            </div>
          </div>
          <div className="boxis-num-5-2">
            <div className="boxi-num-5-2-1">
              <img alt="" className="logo-linkedin" src="../sary/link.png" />
              <div className="text-linkedin">Linkedin</div>{" "}
            </div>
            <div className="boxi-num-5-2-2">
              <img
                alt=""
                className="logo-instagram"
                src="../sary/téléchargement.jpg"
              />
              <div className="text-instagram">Instagram</div>
            </div>
          </div>
        </div>
        <div className="boxis-num-6x">
          Vous avez déjà un compte ?
          <span className="boxis-style" onClick={show}>
            {" "}
            Se connecter
          </span>
        </div>
      </div>
    </>
  );
};
export default Inscription;
