// import React, { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
// import { Box, Rating, Typography } from "@mui/material";
// import axios from "axios";


//   return (
    
//   );
// };

// export default RatingStars;
