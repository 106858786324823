import React from 'react'
import './Contact.css'
import axios from 'axios';
import Footer from '../outil/Footer'
import NavMain from '../Navigation/NavMain'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import NavPro from '../Navigation/NavPro';
export default function Contact() {
   const token = sessionStorage.getItem("token");
   const [loading, setLoading] = useState(false);
   const [error, setError] = useState(null);
   const [name, setname] = useState('');
   const [email, setemail] = useState('');
   const [message, settextea] = useState('');
   const messagexx = document.querySelector(".popx");
   const messagexxf = document.querySelector(".popxf");
   const [truee, settrue] = useState(false);
   const [validation, setvalidation] = useState(false);
   const [falsee, setfalsee] = useState(false);
   const [messagex, setmessagex] = useState("");
   const [messagexa, setmessagexa] = useState("");
   const send = async (e) => {
      setLoading(true)
  e.preventDefault();
      try {
         const res = await axios.post(
            "https://www.api-macerise.masovia-madagascar.com/api/send-email",
            { name, email, message },
            {
               headers: {
                  Authorization: `Bearer ${token}`,
               },
            }
         );
         setLoading(false)
         setmessagex("Message envoyé");
setname('');
setemail('');
settextea('');
         setfalsee(false);
         if (messagexxf) {
            messagexxf.style.display = "none";
         }
         settrue(true);
         if (messagexx) {
            messagexx.style.display = "flex";
         }
         setTimeout(() => {
            if (messagexx) {
               messagexx.style.display = "none";

            }
            settrue(false);
         }, 6000);
      } catch (error) {
         setLoading(false)
      }
   }

   return (
      <>

         <NavPro />
         {loading ? <div className='loading'> <CircularProgress sx={{
        position: 'fixed',
        top: '100px',
        zIndex: '700'
      }} /></div> : ''}
         {truee ? <div className="popx">{messagex}<CheckCircleIcon style={{  marginLeft: 8 }} /></div> : ""}
         {falsee ? <div className="popxf">{messagexa}</div> : ""}
         <div className='contact'>
            <div className='instruction'>
               <div> <h4> Vous avez un question ?</h4>
                  <h4>Laissez-vous un message.</h4>
               </div>
               <p>Nous sommes ici pour vous aider. N'hésitez pas à nous contacter si vous avez besoin d'assistance.</p>
               <div>
                  <h4>Contactez-nous</h4>
                  <h4>Laissez un message  ou appelez-nous.</h4>
               </div>
               <p> <i className='ti-files'></i >25 , avenue de Bouvines 08200 SEDAN</p>
               <p><i className='ti-email'></i> contact@macerise.com</p>
               <p> <i className='ti-files'></i>06 xx xx xx xx</p>

            </div>
            <form className='formulaire' onSubmit={send}>

               <h4>Envoyez votre message.</h4>
               <input onChange={(e) => setname(e.target.value)} value={name} type='text' placeholder='Nom'></input>
               <input onChange={(e) => setemail(e.target.value)} value={email} type='email' placeholder='Adresse email'></input>
               <textarea value={message} onChange={(e) => settextea(e.target.value)} placeholder='Posez une question, ou dites simplement bonjour.'></textarea>
               <button type='submit'>Send</button>
            </form>
         </div>


         <Footer />
      </>
   )
}
