import React, { useState,useEffect,useRef  } from "react";
// import { useNavigate } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Typography, Box, Container } from "@mui/material";
import axios from "axios";

const Failed = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const sessionId = searchParams.get("session_id");
  const hasFetched = useRef(null); // Ref pour surveiller chaque sessionId
  const navigate = useNavigate();

//   const handleGoHome = () => {
//     navigate("/dashboardPro"); // Redirige vers la page d'accueil
//   };

  const fail = async () => {
    const token = sessionStorage.getItem('token');
    try {
      const response = await axios.get(`/payment_tarif/failed/${sessionId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('Réponse de failed:', response.data);
    } catch (error) {
      console.error('Erreur lors du failed:', error);
    }
  };

  useEffect(() => {
    if (sessionId && hasFetched.current !== sessionId) {
      // Marque cette session comme "appelée"
      hasFetched.current = sessionId;
      // fail();
    }
  }, [sessionId]);   
    return (
        <Container maxWidth="sm" style={{ textAlign: "center", marginTop: "50px" }}>
            <Box 
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    alignItems: 'center', 
                    justifyContent: 'center',
                    height: '100vh' 
                }}
            >
                <Typography variant="h4" component="h1" gutterBottom>
                    Paiement Échoué
                </Typography>
                <Typography variant="body1" color="textSecondary" gutterBottom>
                    Votre paiement n'a pas pu être traité. Veuillez vérifier vos informations et réessayer.
                </Typography>
                <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={() => window.history.back()} 
                    sx={{
                        backgroundColor: "#f9194f",
                        '&:hover': { backgroundColor: "#d31644" },
                        padding: "10px 20px",
                        marginTop: "20px"
                    }}
                >
                    Réessayer
                </Button>
            </Box>
        </Container>
    );
};

export default Failed;
