import NewProfil from "./newProfil";
import Sidebar from "./newSideBar";

export default function NewDashboard(){
    return(
        <>
            <Sidebar>
            </Sidebar>
        </>
    )
}