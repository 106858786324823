// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `header{
    position: sticky;
    top:0;
    max-width: 100vw;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
   
    box-shadow: 1px 1px 10px #D4D3D3;
    background-color: #fff;
    z-index: 100;
}
header .simplemenu{
  padding-right: 5px;
    width: 99vw;
    display: flex;
    align-items: center;
    justify-content:space-between;
    height: auto;
    background-color: #fff;
    z-index: 100;
   

}
.menupro {
    opacity: 1;
}
@media screen and (max-width:1000px) {
    .menupro {
        position: absolute;
        display: flex;
        right: 20px;
        top: 70px;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        box-shadow: 1px 1px 10px #D4D3D3;
        padding: 15px;
        border-radius: 15px;

    }
    header .simplemenu{
        width: 97vw;
        display: flex;
        align-items: center;
        
        min-height: 60px;
      
        z-index: 100;
    }
}
`, "",{"version":3,"sources":["webpack://./src/component/Navigation/NavMain.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,KAAK;IACL,gBAAgB;IAChB,aAAa;IACb,uBAAuB;IACvB,8BAA8B;IAC9B,sBAAsB;;IAEtB,gCAAgC;IAChC,sBAAsB;IACtB,YAAY;AAChB;AACA;EACE,kBAAkB;IAChB,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,6BAA6B;IAC7B,YAAY;IACZ,sBAAsB;IACtB,YAAY;;;AAGhB;AACA;IACI,UAAU;AACd;AACA;IACI;QACI,kBAAkB;QAClB,aAAa;QACb,WAAW;QACX,SAAS;QACT,uBAAuB;QACvB,uBAAuB;QACvB,sBAAsB;QACtB,gCAAgC;QAChC,aAAa;QACb,mBAAmB;;IAEvB;IACA;QACI,WAAW;QACX,aAAa;QACb,mBAAmB;;QAEnB,gBAAgB;;QAEhB,YAAY;IAChB;AACJ","sourcesContent":["header{\n    position: sticky;\n    top:0;\n    max-width: 100vw;\n    display: flex;\n    align-items: flex-start;\n    justify-content: space-between;\n    flex-direction: column;\n   \n    box-shadow: 1px 1px 10px #D4D3D3;\n    background-color: #fff;\n    z-index: 100;\n}\nheader .simplemenu{\n  padding-right: 5px;\n    width: 99vw;\n    display: flex;\n    align-items: center;\n    justify-content:space-between;\n    height: auto;\n    background-color: #fff;\n    z-index: 100;\n   \n\n}\n.menupro {\n    opacity: 1;\n}\n@media screen and (max-width:1000px) {\n    .menupro {\n        position: absolute;\n        display: flex;\n        right: 20px;\n        top: 70px;\n        align-items: flex-start;\n        justify-content: center;\n        flex-direction: column;\n        box-shadow: 1px 1px 10px #D4D3D3;\n        padding: 15px;\n        border-radius: 15px;\n\n    }\n    header .simplemenu{\n        width: 97vw;\n        display: flex;\n        align-items: center;\n        \n        min-height: 60px;\n      \n        z-index: 100;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
