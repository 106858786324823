import React from 'react'
import './NavSalon.css'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import GridViewIcon from '@mui/icons-material/GridView';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import axios from 'axios';
export default function NavSalon({ changer, active }) {
    const [namesalon, setnamesalon] = useState('');
    const [logo, setlogo] = useState('');
    const Getsalon = async () => {
        const id = sessionStorage.getItem("idsalon");
        let token = sessionStorage.getItem('token');

        // Check if token exists
        if (!token) {
            console.error('No authorization token found.');
            return; // Exit the function if there's no token
        }

        try {
            const response = await axios.get(
                `https://www.api-macerise.masovia-madagascar.com/api/salon/${id}`,

            );

            console.log(response.data.salon.images);

            // Optionally handle state update here
            setlogo(response.data.salon.logo)
            setnamesalon(response.data.salon.name)
            const windowWidth = window.innerWidth;


            if (windowWidth < 900) {
                setnamesalon((response.data.salon.name).charAt(0))
            } else {
                setnamesalon(response.data.salon.name)
            }
       
        } catch (err) {
            console.error('Error occurred:', err);
        }
    };
 
  
    useEffect(() => {

        Getsalon()
    }, [])
    const navig = useNavigate();
    function Dash() {
        navig('/dashboardPro')
    }
    return (
        <nav className='navsalon'>
            {/* <p className='namesalon'>{namesalon}</p> */}
            <img  style={{width:'40px',height:'40px',borderRadius:"100%"}} src={`https://api-macerise.masovia-madagascar.com/storage/salon_logo/${logo}`} alt="" />
            <div className='menusalon'>
                <p className={active === 0 ? 'activesalon' : ''} onClick={() => changer(0)}><span className='phoner'><AddPhotoAlternateIcon/></span> <span className='bur'>Photos</span></p>
                <p className={active === 1 ? 'activesalon' : ''} onClick={() => changer(1)}><span className='phoner'><EventAvailableIcon/></span><span className='bur'>Agenda</span></p>
                <p className={active === 2 ? 'activesalon' : ''} onClick={() => changer(2)}><span className='phoner'><ManageAccountsIcon/></span><span className='bur'> Services</span></p>
                <p className={active === 3 ? 'activesalon' : ''} onClick={() => changer(3)}><span className='phoner'><FavoriteIcon/></span><span className='bur'>Favoris</span></p>
                <p className={active === 4 ? 'activesalon' : ''} onClick={() => changer(4)}><span className='phoner'><FolderSharedIcon/></span><span className='bur'> Followers</span></p>
                <p className={active === 5 ? 'activesalon' : ''} onClick={() => changer(5)}><span className='phoner'><AddShoppingCartIcon/></span><span className='bur'> Reservations</span></p>
                <p className={active === 6 ? 'activesalon' : ''} onClick={() => changer(6)}><span className='phoner'><ManageAccountsIcon/></span><span className='bur'> Employés</span></p>
                <p className={active === 7 ? 'activesalon' : ''} onClick={() => changer(7)}><span className='phoner'><ManageAccountsIcon/></span><span className='bur'> Congés</span></p>
            </div>
            <p onClick={() => Dash()} className='authsalon'><span className='phoner'><GridViewIcon/></span><span className='bur'>Dashboard</span></p>
        </nav>
    )
}
