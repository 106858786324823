import axios from "axios"

export async function ListEvent() {
    try {
    const response = await axios.get('https://api-macerise.masovia-madagascar.com/api/evenments')
    return response
    } catch (error) {
        throw error
    }
}

export async function futureListEvent() {
    try {
    const response = await axios.get('https://api-macerise.masovia-madagascar.com/api/future_event')
    return response
    } catch (error) {
        throw error
    }
}

export async function pastListEvent() {
    try {
    const response = await axios.get('https://api-macerise.masovia-madagascar.com/api/past_event')
    return response
    } catch (error) {
        throw error
    }
}