// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.centermot {
    position: relative;
    width: 500px;
    box-shadow: 1px 1px 1px 2px gray;
    margin: auto;
    border-radius: 5px;
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
}

.centermot .fermerx {
    position: absolute;
    right: 20px;
    color: red;
    top: 0px;
    height: 40px;
    width: 40px;
    border-radius: 100%;
    background-color: #8a757598;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    scale: 0.9;
    font-weight: 900;
    font-size: 20px;
}

.centermot .fermerx:hover {
    scale: 1;
}
.boxis-num-1-1 {
    font-size: 17px;
}

@media screen and (max-width:800px) {
    .boxis-num-1-1 {
        font-size: 15px;
    }

    .centermot {
        position: relative;
        width: 95%;
        box-shadow: none;
        margin: auto;
        border-radius: 5px;
        margin-top: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 10px;
    }
}`, "",{"version":3,"sources":["webpack://./src/component/outil/RsetMot.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;IACZ,gCAAgC;IAChC,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,sBAAsB;IACtB,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,UAAU;IACV,QAAQ;IACR,YAAY;IACZ,WAAW;IACX,mBAAmB;IACnB,2BAA2B;IAC3B,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;IACf,UAAU;IACV,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,QAAQ;AACZ;AACA;IACI,eAAe;AACnB;;AAEA;IACI;QACI,eAAe;IACnB;;IAEA;QACI,kBAAkB;QAClB,UAAU;QACV,gBAAgB;QAChB,YAAY;QACZ,kBAAkB;QAClB,gBAAgB;QAChB,aAAa;QACb,mBAAmB;QACnB,uBAAuB;QACvB,sBAAsB;QACtB,aAAa;IACjB;AACJ","sourcesContent":[".centermot {\n    position: relative;\n    width: 500px;\n    box-shadow: 1px 1px 1px 2px gray;\n    margin: auto;\n    border-radius: 5px;\n    margin-top: 50px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n    padding: 10px;\n}\n\n.centermot .fermerx {\n    position: absolute;\n    right: 20px;\n    color: red;\n    top: 0px;\n    height: 40px;\n    width: 40px;\n    border-radius: 100%;\n    background-color: #8a757598;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    cursor: pointer;\n    scale: 0.9;\n    font-weight: 900;\n    font-size: 20px;\n}\n\n.centermot .fermerx:hover {\n    scale: 1;\n}\n.boxis-num-1-1 {\n    font-size: 17px;\n}\n\n@media screen and (max-width:800px) {\n    .boxis-num-1-1 {\n        font-size: 15px;\n    }\n\n    .centermot {\n        position: relative;\n        width: 95%;\n        box-shadow: none;\n        margin: auto;\n        border-radius: 5px;\n        margin-top: 50px;\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        flex-direction: column;\n        padding: 10px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
