// List employer Sallon
// https://api-macerise.masovia-madagascar/api/salon/{salonId}/employees
//Add
// https://api-macerise.masovia-madagascar/api/add_employee
//del
// https://api-macerise.masovia-madagascar/api/delete/employee/{id}
//put
// https://api-macerise.masovia-madagascar/api/delete/employee/{id}

import axios from "axios";

/**
 *
 * @param {*} id  id du salon
 * @returns
 */
export async function listEmployer(salonId) {
  const token = sessionStorage.getItem("token");
  try {
    const response = await axios.get(
      `https://api-macerise.masovia-madagascar.com/api/salon/${salonId}/employees`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(response)
    return response;
  } catch (error) {
    throw error;
  }
}
export async function listEmployerc(salonId) {
  const token = sessionStorage.getItem("token");
  try {
    const response = await axios.get(
      `https://api-macerise.masovia-madagascar.com/api/employee/conge/${salonId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(response)
    return response;
  } catch (error) {
    throw error;
  }
}
/**
 *
 * @param {*} data name, salon_id, lastaname, CIN, address, ddn
 * @returns
 */
export async function ajoutEmployer(data) {
  const token = sessionStorage.getItem("token");
  try {
    const response = await axios.post(
      `https://api-macerise.masovia-madagascar.com/api/add_employee`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
}
export async function ajoutEmployerc(data) {
  const token = sessionStorage.getItem("token");
  try {
    const response = await axios.post(
      `https://api-macerise.masovia-madagascar.com/api/add_conge`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
}
/**
 *@param {1} id id emplyer
 * @param {*} data name, salon_id, lastaname, CIN, address, ddn
 * @returns
 */
export async function editEmployer(id, data) {
  const token = sessionStorage.getItem("token");
  try {
    const response = await axios.put(
      `https://api-macerise.masovia-madagascar.com/api/update/employee/${id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
}
/**
 *
 * @param {*} id id employer
 * @returns
 */
export async function deleteEmployer(id) {
  const token = sessionStorage.getItem("token");
  try {
    const response = await axios.delete(
      `https://api-macerise.masovia-madagascar.com/api/delete/employee/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
}
export async function deleteEmployerc(id) {
  const token = sessionStorage.getItem("token");
  try {
    const response = await axios.delete(
      `https://api-macerise.masovia-madagascar.com/api/delete/conge/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
}
// https://api-macerise.masovia-madagascar/api/uploadPhoto_employee/{employeeId}
/**
 *
 * @param {*} id id employer
 * @returns
 */
export async function setProfil(id, formData) {
  const token = sessionStorage.getItem("token");
  try {
    const response = await axios.post(
      `https://api-macerise.masovia-madagascar.com/api/uploadPhoto_employee/${id}`,
      
        formData
      ,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
}
