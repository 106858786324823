// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width:600px) {
    .evenemetg table,
    .evenemetg thead,
    .evenemetg tbody,
    .evenemetg th,
    .evenemetg td,
    .evenemetg tr {
        display: block;
    }

    .evenemetg th {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    .evenemetg tr {
        margin-bottom: 10px;
    }

    .evenemetg td {
        text-align: right;
        position: relative;
        padding-left: 50%;
    }

    .evenemetg td::before {
        position: absolute;
        left: 0;
        width: 50%;
        padding-left: 10px;
        white-space: nowrap;
        text-align: left;
        font-weight: bold;

    }

    .evenemetg td:nth-of-type(1)::before {
        content: 'Nom de L evenement :';
    }

    .evenemetg td:nth-of-type(2)::before {
        content: 'Date :';
    }

    .evenemetg td:nth-of-type(3)::before {
        content: 'Organisateur :';
    }

    .evenemetg td:nth-of-type(4)::before {
        content: 'Actions :';
    }

    
}`, "",{"version":3,"sources":["webpack://./src/component/Dashbord/Event.css"],"names":[],"mappings":"AAAA;IACI;;;;;;QAMI,cAAc;IAClB;;IAEA;QACI,kBAAkB;QAClB,YAAY;QACZ,aAAa;IACjB;;IAEA;QACI,mBAAmB;IACvB;;IAEA;QACI,iBAAiB;QACjB,kBAAkB;QAClB,iBAAiB;IACrB;;IAEA;QACI,kBAAkB;QAClB,OAAO;QACP,UAAU;QACV,kBAAkB;QAClB,mBAAmB;QACnB,gBAAgB;QAChB,iBAAiB;;IAErB;;IAEA;QACI,+BAA+B;IACnC;;IAEA;QACI,iBAAiB;IACrB;;IAEA;QACI,yBAAyB;IAC7B;;IAEA;QACI,oBAAoB;IACxB;;;AAGJ","sourcesContent":["@media screen and (max-width:600px) {\n    .evenemetg table,\n    .evenemetg thead,\n    .evenemetg tbody,\n    .evenemetg th,\n    .evenemetg td,\n    .evenemetg tr {\n        display: block;\n    }\n\n    .evenemetg th {\n        position: absolute;\n        top: -9999px;\n        left: -9999px;\n    }\n\n    .evenemetg tr {\n        margin-bottom: 10px;\n    }\n\n    .evenemetg td {\n        text-align: right;\n        position: relative;\n        padding-left: 50%;\n    }\n\n    .evenemetg td::before {\n        position: absolute;\n        left: 0;\n        width: 50%;\n        padding-left: 10px;\n        white-space: nowrap;\n        text-align: left;\n        font-weight: bold;\n\n    }\n\n    .evenemetg td:nth-of-type(1)::before {\n        content: 'Nom de L evenement :';\n    }\n\n    .evenemetg td:nth-of-type(2)::before {\n        content: 'Date :';\n    }\n\n    .evenemetg td:nth-of-type(3)::before {\n        content: 'Organisateur :';\n    }\n\n    .evenemetg td:nth-of-type(4)::before {\n        content: 'Actions :';\n    }\n\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
