import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { toast } from "sonner";
import {
  Box,
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
  Stack,
  TextField,
} from "@mui/material";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { useParams } from "react-router-dom";
import { typographyTheme } from "../../../event/utils/responsiveTypo";
import { deleteEmployerc } from "../../api/employer";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function DialogDeletec({
  open,
  setOpen,
  setData,
  itemDel,
  setSubmitChange,
}) {
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    // setData((prev) => {
    //   return prev.filter((row) => row.id !== itemDel.id);
    // });
    deleteEmployerc(itemDel["id"])
      .then((res) => {
        console.log("Deleted", res.data);
        setSubmitChange((v) => !v);
        handleClose()
        toast.success("suppression Congé avec succes", {
            position: "top-center",
        });
        handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
    setOpen(false);
  };

  useEffect(() => {
    console.log("ItemDel", itemDel);
  }, []);
  return (
    <>
      <Dialog
        onClose={handleClose}
        fullWidth={true}
        // aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="sm"
      >
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id="customized-dialog-title"
          color="warning"
        >
          Suppression
        </DialogTitle>
        <DialogContent dividers>
          <Typography sx={typographyTheme.body1}>
            Confirmez-vous de supprimer le congé ?{" "}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            sx={{ color: "#F9194F", bgcolor: "#fff" }}
          >
            Annuler
          </Button>
          <Button
            onClick={handleConfirm}
            sx={{ color: "#F9194F", bgcolor: "#fff" }}
          >
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
