import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { NavLink ,Navigate} from "react-router-dom";
import { TableContainer, Button,Box,Container,Typography,CircularProgress } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { styled } from "@mui/material/styles";
import { Scale } from "@mui/icons-material";
import Abonnement from "../abonnement/Abonnement";

const Masse = () => {
  const useridc=sessionStorage.getItem('userId')
  const [userids, setiduserr] = useState("");
  const [name, setname] = useState("");
  const [liste, setliste] = useState("");
  const [events, setEvents] = useState([]);
  const [fav, setfav] = useState([]);
  const [content, setmessage] = useState();
  const [receiver_id, setreceiver_id] = useState();
  const [isLoading, setIsLoading] = useState(true); // État pour le chargement
 
  const fetmessage = async () => {
    let token = sessionStorage.getItem("token");
    try {
      const response = await axios.get(
        "https://www.api-macerise.masovia-madagascar.com/api/messages/receiver",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setfav(response.data);
      console.log(response.data)
    } catch (err) {
      console.log(err);
    }
  };

  /* envouyer message*/

  const Envoyermessage = async (e) => {
    e.preventDefault();

    let token = sessionStorage.getItem("token");
    try {
      const response = await axios.post(
        "https://www.api-macerise.masovia-madagascar.com/api/messages/send/" +
          userids,
        {
          content,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // setfav(response.data.favorites_client);
      //  console.log(response)
      await lister(userids); // Récupérer la liste des messages après l'envoi
      setmessage(""); // Réinitialiser le champ de message
    } catch (err) {
      console.log(err);
    }
  };

  
  const CustomButton = styled(Button)(({ theme }) => ({
    "&:hover": {
      backgroundColor: "white", // Changez cela si vous voulez un effet différent
      boxShadow: "none", // Supprimez l'ombre
    },
  }));
  function convertir(dateString) {
    // Convertir la chaîne en objet Date
    const date = new Date(dateString);

    // Extraire l'heure et les minutes en temps local
    const y = date.getFullYear(); // Utiliser getHours pour le temps local
    const d = date.getDate(); // Utiliser getMinutes pour le temps local
    const m = date.getMonth() + 1; // Utiliser getMinutes pour le temps local
    // Ajouter un zéro devant les minutes si elles sont inférieures à 10
    //const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

    // Retourner l'heure et les minutes au format hh:mm
    return d + ":" + m + ":" + y;
  }
  const lister = async (iduser) => {
    let token = sessionStorage.getItem("token");
    try {
      const response = await axios.get(
        "https://www.api-macerise.masovia-madagascar.com/api/conversations/" +
          iduser,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setliste(response.data);
       console.log(response)
    } catch (err) {
      console.log(err);
    }
  };
  const [image, setimg] = useState('')
  function goo(id, name, img) {
    setimg(img)
    setiduserr(id);
    setname(name);
    lister(id);
  }

  useEffect(() => {
    const goom = document.querySelector('.goom');
    if (goom) {
      goom.scrollTop = goom.scrollHeight; // Faire défiler vers le bas
    }
    const messp = document.querySelectorAll('.cardmm');
    messp.forEach(m => {
      if (m.getAttribute('id')!==useridc) { 
    
        m.style.justifyContent = 'flex-end';
        m.children[0].style.backgroundColor = '#fb174e';
      }else{
        m.style.justifyContent = 'flex-start';
        m.children[0].style.backgroundColor = 'gray';
      }
    });
}, [liste]);  // Ajoutez `liste` comme dépendance pour le défilement
const [active,setActive] = useState(false)
const abonne = async () => {
  const token = sessionStorage.getItem('token');
  try {
    const response = await axios.get('/LastSubscription_Masseur', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    
    const lastSubStatus = response.data.lastsub.isActive;
    console.log('franck:',lastSubStatus)

    // Vérifiez si la valeur est 1 ou 0 pour activer/désactiver
    if (lastSubStatus == 1) {
      setActive(true);  // L'abonnement est activé
      console.log('Abonnement activé');
    } else if (lastSubStatus == 0) {
      setActive(false); // L'abonnement est non activé
      console.log('Abonnement non activé');
    }
    
    console.log('Réponse abonnement:', response.data.lastsub); // Traiter la réponse selon vos besoins
  } catch (error) {
    console.error('Erreur:', error); // Gérer les erreurs
  }
};
useEffect(() => {
  const fetchData = async () => {
    await fetmessage();
    await abonne();
    setIsLoading(false); // Fin du chargement
  };

  fetchData();
}, []);

  return (
    <>
   <div
      >
            {isLoading ? (
          // Afficher le chargement
          <Box marginTop='30vh' display="flex" flexDirection="column" alignItems="center" justifyContent='center'width='90vw'>
            <CircularProgress />
            <Typography variant="body1" gutterBottom style={{ marginTop: "20px" }}>
              Chargement en cours...
            </Typography>
          </Box>
        ) : (
         <>
         {
      active === false ? (
        <Box
          sx={{
            backgroundColor: '#fff',
            padding: '40px',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
            borderRadius: '8px',
            marginTop:'30vh',
            display:'flex',
            alignItems:'center',
            justifyContent:'center',
            flexDirection:'column',
            width:'80%',
            margin:'30vh auto'
          }}
        >
          <Typography variant="h4" sx={{ marginBottom: '20px', color: '#F9194F' }}>
            Abonnement requis
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: '30px' }}>
            Pour accéder à cette page, veuillez renouveler votre abonnement.
          </Typography>
      
        </Box>
     ):( <>

      <div className="agenda">
        <h1>Messages</h1>

        <div className="messt">
          <div className="messagec">
            <div className="fixem">
              <p>Discussions</p>
            </div>
            <div className="messcrl">
              {fav.length > 0
                ? fav.map((list, key) => {
                  return (
                    <div
                      key={key}
                      className="cardmess"
                      onClick={() =>
                        goo(list.sender_id, list.sender_name, list.sender_image)
                      }
                    >
                      <img src={`https://api-macerise.masovia-madagascar.com/storage/images/${list.sender_image}`} alt=""></img>
                      <div>
                        <p>{list.sender_name}</p>

                      </div>
                    </div>
                  );
                })
                : <div style={{ width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}><p className="ml10px">0 message</p></div>}
            </div>
          </div>
          <div className="affichem">
            <form className="sendm" onSubmit={Envoyermessage}>
              <input
                style={{ borderRadius: "50px" }}
                type="texte"
                value={content}
                onChange={(e) => setmessage(e.target.value)}
              ></input>
              <button
                style={{ border: 'none', marginLeft: '15px' }}
                disabled={fav.length > 0 ? '' : 'disabled'}
                type="submit"



              >
                {<SendIcon sx={{
                  color: "#fb174e",

                  boxShadow: "none",
                  "&:hover": {
                    backgroundColor: "white", // Changez cela si vous voulez un effet différent
                    // Supprimez l'ombre
                  },
                }} />}
              </button>
            </form>

            <div className="cardmessx">
              <img src={`https://api-macerise.masovia-madagascar.com/storage/images/${image}`} alt=""></img>
              <div>
                <p>{name}</p>
                <p></p>
              </div>
            </div>
            <hr></hr>

            <div className="goom">
              {liste
                ? liste.map((list, key) => {
                  return (
                    <div className="cardemess" key={key}>
                      <p className="tempsm">{convertir(list.updated_at)}</p>
                      <div className="cardmm" id={list.receiver_id}>

                        <p className="messlist">{list.content} </p>
                      </div>
                    </div>
                  );
                })
                : "....."}
            </div>
          </div>
        </div>

      </div></>
)
    }
         </>
        )}
    
    
    
    </div>
    </>
    
  );
};

export default Masse;
