import React, { useState } from 'react'
import NavSalon from './NavSalon'
import Calendrier from '../Calendrier/Calendrier';
import ServiceMass from '../../outil/serviMass/ServiceMass';

import Imagesalon from '../salon/Imagesalon';
import Salonday from '../Calendrier/Salonday';
import Servicesalon from '../../outil/serviMass/Servicesalon';
import Favorisalon from '../salon/Favorisalon';
import Followers from '../salon/Followers';
import Reservationsalon from '../salon/Reservationsalon';
import Employer from '../employer/employer'
import CongeSal from '../conge/CongeSal';
export default function Gerer() {
    const [affichage, setaffiche] = useState(0);
    function changer(val) {
        setaffiche(val)
    }
    return (
        <div style={{maxWidth:'100vw',overflow:'hidden'}}>
            <NavSalon changer={changer} active={affichage}></NavSalon>
            {affichage === 0 ?
                <>
                    <Imagesalon/>
                </>

                : ''}
            {affichage === 1 ?
                <>
                    <Salonday/>
                </>

                : ''}
            {affichage === 2 ?
                <>
                    <Servicesalon/>
                </>

                : ''}
                   {affichage === 3 ?
                <>
                    <Favorisalon/>
                </>

                : ''}
                  {affichage === 4 ?
                <>
                    <Followers/>
                </>

                : ''}
                    {affichage === 5 ?
                <>
                    <Reservationsalon/>
                </>

                : ''}
                     {affichage === 6 ?
                <>
                    <Employer/>
                </>

                : ''}
                       {affichage === 7 ?
                <>
                    <CongeSal/>
                </>

                : ''}
        </div>
    )
}
