import React from "react";
import Test from "./HeadTarif/Test";
import './Tatif.css'
import Offres from "./suitTarif/Offres";
import Footer from "../../outil/Footer";
    import NavPro from '../../Navigation/NavPro'
import { Box } from "@mui/material";

const Tarif =()=>{
    return(
        <>
        <NavPro/>
        <Box className="container-tarif" >
            <Test/>
            <Offres/> 
        </Box>
        <Box >
        <Footer/>
        </Box>
        
        </>
    )
}
export default Tarif