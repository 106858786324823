import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Myprofil.css';
import './Reservation.css';

export default function Reservation() {
  const [events, setEvents] = useState([]);
  const [employes, setEmployes] = useState({});
  const token = sessionStorage.getItem('token');

  const gooo = async () => {
    try {
      const res = await axios.get("https://www.api-macerise.masovia-madagascar.com/api/appointments_client", {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      setEvents(res.data.reservations);
    } catch (error) {
      console.error('Erreur lors de la récupération des réservations:', error);
    }
  };

  const employer = async (employeId) => {
    try {
      const response = await axios.get(`https://www.api-macerise.masovia-madagascar.com/api/salon/${employeId}/employees`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      setEmployes(prevEmployes => ({
        ...prevEmployes,
        [employeId]: response.data.employees
      }));
    } catch (error) {
      console.error('Erreur lors de la récupération des employés:', error);
    }
  };

  useEffect(() => {
    gooo();
  }, []);

  return (
    <div className="right">
      <p className='p'>Vos réservations</p>
      <table>
        <thead>
          <tr>
            <th>Dates</th>
            <th>Heures</th>
            <th>Status</th>
            <th>Services</th>
            <th>Durée</th>
            {/* <th>Employé(e)</th> */}
            <th>Prix</th>
          </tr>
        </thead>
        <tbody>
          {events && events.map(event => {
            if (!employes[event.employe_id]) {
              employer(event.employe_id); // Appelez la fonction pour récupérer les employés
            }

            const employeName = employes[event.employe_id]?.find(ser => ser.id === event.employe_id)?.name || '';
            
            return (
              <tr key={event.id}>
                <td style={{ backgroundColor: 'rgba(128, 128, 128, 0.384)' }}>{event.date}</td>
                <td>{event.start_time}</td>
                <td className='status' style={{
                  background: event.status === 'accepted' ? 'green' : event.status === 'canceled' ? 'red' : '',
                  color: event.status === 'accepted' ? 'white' : event.status === 'canceled' ? 'white' : 'black'
                }}>
                  {event.status === 'accepted' ? 'Accepter' : event.status === 'canceled' ? 'Refuser' : 'Attente'}
                </td>
                <td>{event.service.name}</td>
                <td>{event.service.duration}</td>
                {/* <td>{employeName}</td> */}
                <td>{event.service.price}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
