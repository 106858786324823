import React, { useEffect, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    Button,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    Input,
    Box,
    // CircularProgress,
    Container,
} from "@mui/material";
import { Navigate } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import BuildIcon from '@mui/icons-material/Build';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import FavoriteIcon from '@mui/icons-material/Favorite';
import AlarmOffIcon from '@mui/icons-material/AlarmOff';
import { styled } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';
import { useNavigate } from "react-router-dom";
// import './Salon.css'
import { Delete, Edit, CheckCircle, Visibility } from "@mui/icons-material";
import axios from "axios";
import { Margin } from "@mui/icons-material";
import "../Calendrier/cal.css";
import "./Salon.css";
axios.defaults.baseURL = "https://www.api-macerise.masovia-madagascar.com/api";
const Salon = () => {
    const [truee, settrue] = useState(false);
    const [validation, setvalidation] = useState(false);
    const [voire, setvoire] = useState(false);
    const [falsee, setfalsee] = useState(false);
    const [load, setload] = useState(false);
    const [fermer, setferm] = useState(false);
    const [messagex, setmessagex] = useState("");
    const [messagexa, setmessagexa] = useState("");
    const [events, setEvents] = useState([]);
    const userId = sessionStorage.getItem("userId");
    const token = sessionStorage.getItem("token");
    const messagexx = document.querySelector(".popx");
    const messagexxf = document.querySelector(".popxf");
    const [jour, setjour] = useState("");
    const [selectrolef, setSelectrolef] = useState("");
    const [names, setnames] = useState("");
    const [salonid, setsalonid] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    //const [midi, setmidi] = useState(false);
    const [valid, setvalid] = useState(false);
    const [countries, setCountries] = useState([]);
    // activation apres abonnement
    const [active, setActive] = useState(false)
    const fetchEventssalon = async () => {
        let id_users = sessionStorage.getItem("userId");

        // console.log(id_salon)
        try {
            const response = await axios.get(
                "https://www.api-macerise.masovia-madagascar.com/api/get/salon/user/" +
                id_users
            );
            setsalonid(response.data.salons);
            // console.log(response.data.salons[0].id)
        } catch {
            console.log("erreur");
        }

        //console.log(response.data.salons[0].id)
    };

    const fetchEvents = async () => {
        // console.log(salonid);
        // let idsalon=salonid[0].id ;
        try {
            const response = await axios.get(
                "https://www.api-macerise.masovia-madagascar.com/api/salons_masseur",
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            // console.log(response.data);

            if (response.data) {
                // Décommentez la ligne ci-dessous si vous souhaitez définir l'état des événements
                setEvents(response.data.salons);
                console.log(response.data.salons);
            } else {
                //     console.error('Aucune donnée disponible dans la réponse.');
            }
        } catch (error) {
            // Gérer les erreurs ici
            console.error("Erreur lors de la récupération des événements:", error);
        }
    };

    useEffect(() => {
        fetchEvents();
    }, []);
    const [contryf, setCountriesf] = useState('');
    const [form, setForm] = useState({

        name: "",
        address: "",
        apropos: "",
        description: "",
        city: "",

    });
    const [contryf10, setCountriesf10] = useState('');
    const [form10, setForm10] = useState({

        name: "",
        address: "",
        apropos: "",
        description: "",
        city: "",
        id: ''

    });
    const [form5, setForm5] = useState({

        name: "",
        address: "",
        apropos: "",
        description: "",
        city: "",
        country: '',
        image: ''
    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm((prevForm) => ({
            ...prevForm,
            [name]: value,
        }));
    };
    const handleChange10 = (e) => {
        const { name, value } = e.target;
        setForm10((prevForm) => ({
            ...prevForm,
            [name]: value,
        }));
    };
    const handleChangeday_id = (e) => {
        setForm((prevForm) => ({
            ...prevForm,
            day_id: parseInt(e.target.value),
        }));
    };
    const handleChangestart_time = (e) => {
        const formatTime = (time) => {
            // Añadir ":00" al final de la cadena de tiempo.
            return `${time}:00`;
        };
        //console.log(endf)
        setForm((prevForm) => ({
            ...prevForm,

            start_time: formatTime(e.target.value),
        }));
    };
    const handleChangeend_time = (e) => {
        const formatTime = (time) => {
            // Añadir ":00" al final de la cadena de tiempo.
            return `${time}:00`;
        };
        setForm((prevForm) => ({
            ...prevForm,

            end_time: formatTime(e.target.value),
        }));
    };
    const handleChangesalon_id = (e) => {
        //console.log(e.target.value)
        setForm((prevForm) => ({
            ...prevForm,

            salon_id: parseInt(e.target.value),
        }));
    };
    const handleChangestatus = (e) => {
        setForm((prevForm) => ({
            ...prevForm,

            status: e.target.value,
        }));
    };
    const handelOpen = (name, city, country, address, apropos, description, image) => {
        setvoire(true)
        setForm5((prevForm) => ({
            ...prevForm,
            name: name,
            address: address,
            apropos: apropos,
            description: description,
            city: city,
            country: country,
            image: image
        }));


    };
    const [form2, setForm2] = useState({
        day_id: "",
        start_time: "",
        end_time: "",
        salon_id: '',
        status: "",
    });
    const [form90, setForm90] = useState({
        salon_id: '',

    });
    const handleChangeday_id2 = (e) => {
        setForm2((prevForm) => ({
            ...prevForm,
            day_id: parseInt(e.target.value),
        }));
    };
    const handleChangestart_time2 = (e) => {
        const formatTime = (time) => {
            // Añadir ":00" al final de la cadena de tiempo.
            return `${time}:00`;
        };
        //console.log(endf)
        setForm2((prevForm) => ({
            ...prevForm,

            start_time: formatTime(e.target.value),
        }));
    };
    const handleChangeend_time2 = (e) => {
        const formatTime = (time) => {
            // Añadir ":00" al final de la cadena de tiempo.
            return `${time}:00`;
        };
        setForm2((prevForm) => ({
            ...prevForm,

            end_time: formatTime(e.target.value),
        }));
    };
    const handleChangesalon_id2 = (e) => {
        setForm2((prevForm) => ({
            ...prevForm,

            salon_id: parseInt(e.target.value),
        }));
    };
    const handleChangestatus2 = (e) => {
        setForm2((prevForm) => ({
            ...prevForm,

            status: e.target.value,
        }));
    };
    const [form3, setForm3] = useState({
        timeid: "",
    });

    const handleSubmit = async (e) => {
        e.preventDefault();

        console.log(form)

        try {
            const res = await axios.post(
                "https://www.api-macerise.masovia-madagascar.com/api/add_salon",
                {
                    name: form.name,
                    address: form.address,
                    description: form.description,
                    apropos: form.apropos,
                    city: form.city,
                    country_id: contryf
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            setmessagex("ajout avec success day ");
            setForm(() => ({

                name: "",
                address: "",
                description: "",
                apropos: "",
                city: "",

            }));
            setCountriesf('')
            setfalsee(false);
            if (messagexxf) {
                messagexxf.style.display = "none";
            }
            settrue(true);
            if (messagexx) {
                messagexx.style.display = "flex";
            }
            setTimeout(() => {
                if (messagexx) {
                    messagexx.style.display = "none";
                }
            }, 6000);
            //  console.log(res)
        } catch (error) {
            console.error(
                "Erreur lors de l'envoi des données à l'API:",

            );

            settrue(false);
            if (messagexx) {
                messagexx.style.display = "none";
            }

            setfalsee(true);
            if (messagexxf) {
                messagexxf.style.display = "flex";
            }
            setTimeout(() => {
                if (messagexxf) {
                    messagexxf.style.display = "none";
                }
            }, 6000);
        }
        fetchEvents();
        setferm(false)

    };
    const forma = (time) => {
        // Vérifier si le format d'entrée est valide
        const timeParts = time.split(":");
        if (timeParts.length === 3) {
            return `${timeParts[0]}:${timeParts[1]}`; // Retourner les heures et les minutes
        }
        return time; // Retourner la chaîne d'origine si le format est incorrect
    };

    const handleEdit = async (id, name, city, country_id, address, apropos, description) => {
        setmidi(true);

        setCountriesf10(country_id)
        setForm10((prevForm) => ({
            ...prevForm,
            id: id,
            name: name,
            address: address,
            apropos: apropos,
            description: description,
            city: city,

        }));
    };
    const formatTime = (time) => {
        // Añadir ":00" al final de la cadena de tiempo.
        return `${time}:00`;
    };
    const shandleEdit = async (e) => {
        e.preventDefault();
        setmidi(false);
        let eventidd = form10.id;
        console.log(eventidd);
        try {
            const res = await axios.put(
                "https://www.api-macerise.masovia-madagascar.com/api/update_salon/" +
                eventidd,
                {
                    name: form10.name,
                    address: form10.address,
                    description: form10.description,
                    apropos: form10.apropos,
                    city: form10.city,
                    country_id: contryf10
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setmessagex("modification avec success");
            settrue(true);
            if (messagexx) {
                messagexx.style.display = "flex";
            }
            setTimeout(() => {
                if (messagexx) {
                    messagexx.style.display = "none";
                }
            }, 6000);
            console.log(res);
        } catch (error) {
            console.error("Erreur lors de l'envoi des données à l'API:", error);
        }
        fetchEvents();
    };
    const [idsup, setidsup] = useState();
    function handleDeletex(ideventx, name) {
        setvalid(true);
        setidsup(ideventx);
        setnames(name);
    }
    const handleDelete = async () => {
        //console.log(ideventx)
        setvalid(false);
        let ideventx = idsup;

        try {
            const res = await axios.delete(
                "https://www.api-macerise.masovia-madagascar.com/api/delete/salon/" +
                ideventx,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            console.log(res);
            setmessagex("supprimé avec succès ");
            settrue(true);
            if (messagexx) {
                messagexx.style.display = "flex";
            }

            setTimeout(() => {
                if (messagexx) {
                    messagexx.style.display = "none";
                }
            }, 6000);
        } catch (error) {
            console.error("Erreur lors de l'envoi des données à l'API:", error);
        }

        // Rafraîchir les événements après la suppression
        fetchEvents();
    };

    const [midi, setmidi] = useState(false);
    const [photoc, setphoto] = useState(false);
    const [more, setmore] = useState(false);
    const [photoplus, setphotoplus] = useState(false);
    const [selectedSalon, setSelectedSalon] = useState(false);
    const [selectedSalonplus, setSelectedSalonplus] = useState(false);
    const [selectedmore, setSelectedmore] = useState(false);
    const [gerer, setgerer] = useState(false);
    const [selectedSalonid, setSelectedSalonid] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);

    const [idsalon, setidsalon] = useState(null);


    const handleChangesalonid = (e) => {
        e.preventDefault()
        setidsalon(e.target.value);
        setSelectedSalon(true)
    };
    const handleChangesalonidplus = (e) => {

        setidsalonplus(e.target.value);
        setSelectedSalonplus(true)
    };

    const [imagePreview, setImagePreview] = useState(null);

    const handleFileChange = async (event) => {
        event.preventDefault()
        setload(true)
        const file = event.target.files[0];

        if (file) {
            setSelectedFile(file);
            const fileURL = URL.createObjectURL(file);
            setImagePreview(fileURL); // Set the preview URL for the image

            // Préparez les données pour l'upload
            const images = new FormData();
            images.append("images", file); // 'image' doit correspondre à ce que l'API attend

            let token = sessionStorage.getItem("token");

            try {
                const response = await axios.post(`uploadImage_salon/` + idsalon, images);

                console.log("Upload success:", response.data);
                // Peut-être mettre à jour l'état ici si nécessaire pour afficher la nouvelle image
                setSelectedSalon(false)
                setidsalon('')
                setmessagex("ajout avec succès ");
                settrue(true);
                if (messagexx) {
                    messagexx.style.display = "flex";
                }

                setTimeout(() => {
                    if (messagexx) {
                        messagexx.style.display = "none";
                    }
                    settrue(false)
                }, 4000);
                setload(false)
            } catch (error) {
                console.error("Error uploading file:", error);
                // Gérer les erreurs de manière approprié
            }
        }
    };
    const [idsalonplus, setidsalonplus] = useState(null);
    const [idmore, setidmore] = useState(null);
    const [selectedFilesplus, setSelectedFilesplus] = useState([]);
    const [imagePreviewsplus, setImagePreviewsplus] = useState([]);

    const handleFileChangeplus = async (event) => {
        event.preventDefault()
        setload(true)
        const files = Array.from(event.target.files); // Récupérer tous les fichiers sélectionnés

        if (files.length > 0) {
            // Mettre à jour l'état avec les fichiers sélectionnés
            setSelectedFilesplus(files);

            // Créer une URL pour chaque image pour l'aperçu
            const fileURLs = files.map(file => URL.createObjectURL(file));
            setImagePreviewsplus(fileURLs); // Mettre à jour l'aperçu des images

            // Préparer les données pour l'upload
            const images = new FormData();
            files.forEach(file => {
                images.append("image[]", file); // Assurez-vous que le backend attend un tableau d'images
            });


            try {
                const response = await axios.post(`salons/${idsalonplus}/add_images`, images

                );

                console.log("Upload success:", response.data);
                setSelectedSalonplus(false);
                setidsalonplus('');
                setmessagex("ajout avec succès");
                settrue(true);
                setload(false)
                if (messagexx) {
                    messagexx.style.display = "flex";
                }

                setTimeout(() => {
                    if (messagexx) {
                        messagexx.style.display = "none";
                    }
                    settrue(false);
                }, 4000);

            } catch (error) {
                console.error("Error uploading file:", error);
                // Gérer les erreurs de manière appropriée
                setmessagex("Erreur lors de l'ajout des images");
                settrue(true);
            }
        }
    };

    const Input = styled('input')({
        display: 'none',
    });
    const handleChangemore = (e) => {
        e.preventDefault()
        console.log(e.target.value)
        setload(true)
        setidmore(e.target.value);
        setSelectedmore(true)
        gallery(e.target.value)
    };
    const handleChangegerer = (e) => {
        e.preventDefault()
        const linkedInShareLink = `dashboardPro/salon/sitting`;
        sessionStorage.setItem('idsalon', e.target.value)

        setidsalonplus('')
        window.open(linkedInShareLink, '_blank');
        // navigate('/dashboardPro/salon/sitting')
    };
    const [tabimage, settab] = useState([]);
    const [firstimg, setfirst] = useState('');
    const gallery = async (id) => {
        let token = sessionStorage.getItem('token');
        settab('')
        // Check if token exists
        if (!token) {
            console.error('No authorization token found.');
            return; // Exit the function if there's no token
        }

        try {
            const response = await axios.get(
                `https://www.api-macerise.masovia-madagascar.com/api/salons/${id}/pictures`,

            );
            setload(false)
            console.log(response.data);
            // Optionally handle state update here
            settab(response.data)
            setfirst(response.data[0].image)
        } catch (err) {
            console.error('Error occurred:', err);
        }
    };
    const [idimage, setidimage] = useState('');
    const supprimer = async (idm) => {
        //console.log(ideventx)



        try {
            const res = await axios.delete(
                "https://www.api-macerise.masovia-madagascar.com/api/delete_images/" +
                idm,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            console.log(res);
            setmessagex("supprimé avec succès ");
            settrue(true);
            if (messagexx) {
                messagexx.style.display = "flex";
            }

            setTimeout(() => {
                if (messagexx) {
                    messagexx.style.display = "none";
                }
                settrue(false)
            }, 4000);
        } catch (error) {
            console.error("Erreur lors de l'envoi des données à l'API:", error);
        }

        // Rafraîchir les événements après la suppression

    };
    const abonne = async () => {
        const token = sessionStorage.getItem('token');
        try {
            const response = await axios.get('/LastSubscription_Masseur', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const lastSubStatus = response.data.lastsub.isActive;
            console.log('franck:', lastSubStatus)

            // Vérifiez si la valeur est 1 ou 0 pour activer/désactiver
            if (lastSubStatus == 1) {
                setActive(true);  // L'abonnement est activé
                console.log('Abonnement activé');
            } else if (lastSubStatus == 0) {
                setActive(false); // L'abonnement est non activé
                console.log('Abonnement non activé');
            }

            console.log('Réponse abonnement:', response.data.lastsub); // Traiter la réponse selon vos besoins
        } catch (error) {
            console.error('Erreur:', error); // Gérer les erreurs
        }
    };
    const fetchCountries = async () => {
        try {
            const response = await axios.get("/countries");
            setCountries(response.data);

        } catch (error) {
            console.error("Erreur de récupération des pays", error);
            // setError("Erreur de récupération des pays.");
        }
    };
    //   useEffect(() => {
    //     const fetchCountries = async () => {
    //         try {
    //             const response = await axios.get("/countries");
    //             setCountries(response.data);

    //         } catch (error) {
    //             console.error("Erreur de récupération des pays", error);
    //             // setError("Erreur de récupération des pays.");
    //         }
    //     };
    //     fetchCountries();
    //     fetchEventssalon()
    //     abonne()
    // }, []);

    useEffect(() => {
        const fetchData = async () => {
            await Promise.all([fetchCountries(), fetchEventssalon(), abonne()]);
            setIsLoading(false); // Fin du chargement
        };

        fetchData();
    }, []);
    return (
        <>
            {isLoading ? (
                // Affichage de l'indicateur de chargement
                <Box marginTop='30vh' display="flex" flexDirection="column" alignItems="center" justifyContent='center'width='90vw'>
                    <CircularProgress />
                    <Typography variant="body1" gutterBottom style={{ marginTop: "20px" }}>
                        Chargement  en cours...
                    </Typography>
                </Box>
            ) : (
                <>

                    {

                        active === false ? (
                            <Container
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '100vh',
                                    textAlign: 'center',
                                    backgroundColor: '#f9f9f9',
                                }}
                            >
                                <Box
                                    sx={{
                                        backgroundColor: '#fff',
                                        padding: '40px',
                                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                        borderRadius: '8px',
                                    }}
                                >
                                    <Typography variant="h4" sx={{ marginBottom: '20px', color: '#F9194F' }}>
                                        Abonnement requis
                                    </Typography>
                                    <Typography variant="body1" sx={{ marginBottom: '30px' }}>
                                        Pour accéder à cette page, veuillez renouveler votre abonnement.
                                    </Typography>

                                </Box>
                            </Container>
                        ) :
                            (
                                <>
                                    {load ? <div className='loading'> <CircularProgress sx={{
                                        position: 'fixed',
                                        top: '100px',
                                        zIndex: '100'
                                    }} /></div> : ''
                                    }

                                    {valid ? (
                                        <div className="validation">
                                            <span className="spann">
                                                <p>Voulez-vous vraiment le supprimer? </p>
                                                <span>
                                                    <button onClick={handleDelete}>OUI</button>
                                                    <button onClick={() => setvalid(false)}>NON</button>
                                                </span>{" "}
                                            </span>{" "}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    {truee ? <div className="popx">{messagex}</div> : ""}
                                    {falsee ? <div className="popxf">{messagexa}</div> : ""}

                                    {fermer ? <div className="transalonx"></div> : ''}
                                    {midi ? <div className="transalonx"></div> : ''}
                                    {voire ? <div className="transalonx"></div> : ''}
                                    <div className="agenda">

                                        {fermer ?

                                            <form onSubmit={handleSubmit} className="form4ms">

                                                <div>
                                                    <h1>Ajouter salon</h1>
                                                    <p className="fermerx" onClick={() => setferm(false)}>
                                                        {" "}
                                                        x
                                                    </p>
                                                    <hr />
                                                    <p> Nom salon</p>
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        value={form.name}
                                                        onChange={handleChange}
                                                        required
                                                    />

                                                </div>
                                                <div>
                                                    <p> Address</p>
                                                    <input
                                                        type="text"
                                                        name="address"
                                                        value={form.address}
                                                        onChange={handleChange}
                                                        required
                                                    />

                                                </div>
                                                <div>
                                                    <p> Description</p>
                                                    <textarea
                                                        type="text"
                                                        name="description"
                                                        value={form.description}
                                                        onChange={handleChange}
                                                        required
                                                    />

                                                </div>
                                                <div>
                                                    <p> Apropos</p>
                                                    <textarea

                                                        name="apropos"
                                                        value={form.apropos}
                                                        onChange={handleChange}
                                                        required
                                                    />

                                                </div>

                                                <div>
                                                    <p> City</p>
                                                    <input
                                                        type="text"
                                                        name="city"
                                                        value={form.city}
                                                        onChange={handleChange}
                                                        required
                                                    />

                                                </div>
                                                <div>

                                                    <p> Coutry</p>
                                                    <select
                                                        id="country"
                                                        value={contryf}
                                                        onChange={(e) => {
                                                            setCountriesf(e.target.value);

                                                        }}
                                                        className="boxis-num-2-1-S"
                                                        required
                                                    >
                                                        <option value="">Sélectionnez un pays</option>
                                                        {countries.map((country) => (
                                                            <option key={country.id} value={country.id}>
                                                                {country.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <button className="modif" type="submit">
                                                    Ajouter
                                                </button>
                                            </form> : ''
                                        }
                                        {photoc ?
                                            <div className="form10">

                                                <p>Photo de couverture</p>
                                                <p className="fermerx" onClick={() => setphoto(false)}>
                                                    {" "}
                                                    x
                                                </p>
                                                <div>
                                                    <div className="custom-select">
                                                        <select
                                                            required
                                                            value={idsalon}
                                                            onChange={handleChangesalonid}
                                                        >
                                                            <option value="">salon</option>
                                                            {salonid && salonid.map((rol) => (
                                                                <option key={rol.id} value={rol.id}>
                                                                    {rol.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>

                                                    {selectedSalon ? (
                                                        <div style={{ marginTop: '10px' }}>
                                                            <label>
                                                                <Input accept="image/*" id="file-upload" type="file" onChange={handleFileChange} />
                                                                <Button variant="contained" component="span" sx={{
                                                                    backgroundColor: '#F9194F', color: 'white', '&:hover': {
                                                                        backgroundColor: '#F9194F', // Couleur de fond lors du hover
                                                                        color: 'white' // Couleur du texte lors du hover
                                                                    },
                                                                }}>
                                                                    <Typography variant="button" color="inherit">
                                                                        Modifier photo de couverture
                                                                    </Typography>
                                                                </Button>
                                                            </label>

                                                        </div>
                                                    ) : ''}
                                                </div>
                                            </div> : ''

                                        }
                                        {photoplus ?
                                            <div className="form10">

                                                <p>Plus de photo</p>
                                                <p className="fermerx" onClick={() => setphotoplus(false)}>
                                                    {" "}
                                                    x
                                                </p>
                                                <div>
                                                    <div className="custom-select">
                                                        <select
                                                            required
                                                            value={idsalonplus}
                                                            onChange={handleChangesalonidplus}
                                                        >
                                                            <option value="">salon</option>
                                                            {salonid && salonid.map((rol) => (
                                                                <option key={rol.id} value={rol.id}>
                                                                    {rol.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>

                                                    {selectedSalonplus ? (
                                                        <div style={{ marginTop: '10px' }}>
                                                            <label>
                                                                <Input accept="image/*" id="file-upload" multiple type="file" onChange={handleFileChangeplus} />
                                                                <Button variant="contained" component="span" sx={{
                                                                    backgroundColor: '#F9194F', color: 'white', '&:hover': {
                                                                        backgroundColor: '#F9194F', // Couleur de fond lors du hover
                                                                        color: 'white' // Couleur du texte lors du hover
                                                                    },
                                                                }}>
                                                                    <Typography variant="button" color="inherit">
                                                                        Ajouter des images
                                                                    </Typography>
                                                                </Button>
                                                            </label>

                                                        </div>
                                                    ) : ''}
                                                </div>
                                            </div> : ''

                                        }
                                        {gerer ?
                                            <div className="form10">

                                                <p>Gerer un salon</p>
                                                <p className="fermerx" onClick={() => setgerer(false)}>
                                                    {" "}
                                                    x
                                                </p>
                                                <div>
                                                    <div className="custom-select">
                                                        <select
                                                            required
                                                            value={idsalonplus}
                                                            onChange={handleChangegerer}
                                                        >
                                                            <option value="">salon</option>
                                                            {salonid && salonid.map((rol) => (
                                                                <option key={rol.id} value={rol.id}>
                                                                    {rol.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>


                                                </div>
                                            </div> : ''

                                        }
                                        {more ?
                                            <div className="form10">

                                                <p>Supprimer photo</p>
                                                <p className="fermerx" onClick={() => setmore(false)}>
                                                    {" "}
                                                    x
                                                </p>
                                                <div>
                                                    <div className="custom-select">
                                                        <select
                                                            required
                                                            value={idmore}
                                                            onChange={handleChangemore}
                                                        >
                                                            <option value="">salon</option>
                                                            {salonid && salonid.map((rol) => (
                                                                <option key={rol.id} value={rol.id}>
                                                                    {rol.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>

                                                    {selectedmore ? (
                                                        <div style={{ overflow: 'auto', marginTop: '10px', minHeight: '50vh', minWidth: '50vw', display: 'flex', gap: '10px', flexWrap: 'wrap' }} className="imagegg">
                                                            {tabimage && tabimage.map((rol, key) => (
                                                                <div id={'A' + rol.id} style={{ position: 'relative', width: '150px', minHeight: '150px', maxHeight: '150px', marginRight: '10px', marginBottom: '10px' }} >
                                                                    <p style={{ zIndex: '100' }} className="fermm" onClick={() => {
                                                                        supprimer(rol.id); // Appelle la fonction pour supprimer le rol avec l'ID correspondant
                                                                        // Supprime l'élément parent du paragraphe
                                                                        const parentElement = document.querySelector(`#A${rol.id}`);
                                                                        if (parentElement) {
                                                                            parentElement.remove();
                                                                        }
                                                                    }}>
                                                                        x
                                                                    </p>

                                                                    <img key={key} className='imagegg' src={`https://api-macerise.masovia-madagascar.com/storage/${rol.image}`} alt="Main"
                                                                        style={{ width: '150px', borderRadius: '8px ', minHeight: '150px', maxHeight: '150px', cursor: 'pointer' }} />
                                                                </div>
                                                            ))}

                                                        </div>
                                                    ) : 'loading...'}
                                                </div>
                                            </div> : ''

                                        }

                                        {voire ?

                                            <form className="form4m6">

                                                <div>
                                                    <h1>Informations:<span className="ssalon">{form5.name}</span> </h1>
                                                    <p className="fermerx" onClick={() => setvoire(false)}>
                                                        {" "}
                                                        x
                                                    </p>
                                                    <hr />
                                                </div>
                                                <div className="catsalon">
                                                    <img src={`https://api-macerise.masovia-madagascar.com/storage/salon_images/${form5.image}`} alt='image' ></img>


                                                </div>
                                                <div className="catsalon">


                                                    <p>Nom salon : <span className="ssalon">{form5.name}</span></p>
                                                </div>
                                                <div className="catsalon">

                                                    <p> Address :<span className="garys"> {form5.address}</span></p>

                                                </div>
                                                <div className="catsalon">

                                                    <p>City :<span className="garys"> {form5.city}</span></p>
                                                </div>
                                                <div className="catsalon">


                                                    <p> Coutry : <span className="garys">
                                                        {countries.map((country) => (
                                                            country.id == form5.country ? country.name : null
                                                        ))}

                                                    </span></p>
                                                </div>
                                                <div className="catsalon">
                                                    <p> Description:</p>
                                                    <p> <span className="garys">{form5.description}</span></p>
                                                </div>
                                                <div className="catsalon">
                                                    <p> Apropos:</p>
                                                    <p><span className="garys">{form5.apropos}</span></p>

                                                </div>



                                            </form> : ''
                                        }


                                        <h1>Site</h1>
                                        <div className="flexb">
                                            <button style={{ marginRight: '5px',width:'48%' }} className="Ajouts" onClick={() => setferm(true)} >
                                                <AddIcon /> Site
                                            </button>
                                            <button style={{ marginRight: '5px',width:'48%' }} className="Ajouts" onClick={() => setgerer(true)} >

                                                <BuildIcon /> Site
                                            </button>
                                        </div>


                                        {midi ?


                                            <form onSubmit={shandleEdit} className="form4ms">

                                                <div>
                                                    <h1>Modifier salon</h1>
                                                    <p className="fermerx" onClick={() => setmidi(false)}>
                                                        {" "}
                                                        x
                                                    </p>
                                                    <hr />
                                                    <p> Nom salon</p>
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        value={form10.name}
                                                        onChange={handleChange10}
                                                        required
                                                    />

                                                </div>
                                                <div>
                                                    <p> Address</p>
                                                    <input
                                                        type="text"
                                                        name="address"
                                                        value={form10.address}
                                                        onChange={handleChange10}
                                                        required
                                                    />

                                                </div>
                                                <div>
                                                    <p> Description</p>
                                                    <textarea
                                                        type="text"
                                                        name="description"
                                                        value={form10.description}
                                                        onChange={handleChange10}
                                                        required
                                                    />

                                                </div>
                                                <div>
                                                    <p> Apropos</p>
                                                    <textarea

                                                        name="apropos"
                                                        value={form10.apropos}
                                                        onChange={handleChange10}
                                                        required
                                                    />

                                                </div>

                                                <div>
                                                    <p> City</p>
                                                    <input
                                                        type="text"
                                                        name="city"
                                                        value={form10.city}
                                                        onChange={handleChange10}
                                                        required
                                                    />

                                                </div>
                                                <div>

                                                    <p> Coutry</p>
                                                    <select
                                                        id="country"
                                                        value={contryf10}
                                                        onChange={(e) => {
                                                            setCountriesf10(e.target.value);

                                                        }}
                                                        className="boxis-num-2-1-S"
                                                        required
                                                    >
                                                        <option value="">Sélectionnez un pays</option>
                                                        {countries.map((country) => (
                                                            <option key={country.id} value={country.id}>
                                                                {country.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <button className="modif" type="submit">
                                                    Modifier
                                                </button>
                                            </form> : ''

                                        }

                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>name</th>
                                                    <th>address</th>
                                                    <th>city</th>
                                                    <th>action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {events ? events.map((event) => (
                                                    <tr key={event.id}>
                                                        <td >{event.name}</td> <td >{event.address}</td>
                                                        <td >{event.city}</td>


                                                        <td>
                                                            <IconButton onClick={() => handelOpen(event.name, event.city, event.country_id, event.address, event.apropos, event.description, event.images)}>
                                                                <Visibility /> {/* Bouton en forme d'œil */}
                                                            </IconButton>
                                                            <IconButton onClick={() => handleEdit(event.id, event.name, event.city, event.country_id, event.address, event.apropos, event.description)}>
                                                                <Edit />
                                                            </IconButton>
                                                            <IconButton onClick={() => handleDeletex(event.id, event.name)}>
                                                                <Delete />
                                                            </IconButton>

                                                        </td>
                                                    </tr>
                                                )) : ''}
                                            </tbody>

                                        </table>
                                    </div>

                                </>)
                    }
                </>
            )}


        </>
    );
};

export default Salon;
