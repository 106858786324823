import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import AffichageDash from "./affichageDash";
import { useEffect, useState } from "react";
import { routesUrlAdmin, routesUrlMasseur } from "./routeUrl";
import { useNavigate } from "react-router-dom";
import { Button, Dialog, DialogContent, DialogTitle, useMediaQuery, useTheme } from "@mui/material";
import { logout } from "../../authentification/Authentificationl";
const drawerWidth = 240;

function ResponsiveDrawer(props) {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [affichage, setAffichage] = useState();
  const [routesUrl, setRouteUrl] = useState([]);
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const role = sessionStorage.getItem("Role");
    console.log(role);
    if (role === "admin") setRouteUrl(routesUrlAdmin);
    else setRouteUrl(routesUrlMasseur);
  }, []);
  const [modal, setModal] = useState(false);
  const navigate = useNavigate();

  function deconecter() {
    setModal(true);
  }
  const handledeconnection = () => {
    logout();
    navigate("/tarif");
  };
  const handleCloseDel = () => {
    setModal(false);
  };

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };
  const handleChangeAffichage = (name) => {
    setAffichage(name);
    setRouteUrl((prev) => {
      const maj = prev.reduce((acc, value) => {
        acc.push({ ...value, active: value.name === name });
        return acc;
      }, []);
      return maj;
    });
  };
  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };
  const drawer = (
    <div style={{ padding: 0 }}>
      {/* <Toolbar sx={{pl:0, pr:0}}> */}
      <Box
        pl={0}
        component={"img"}
        width="100%"
        height="100px"
        src="logomacerise.png"
        alt="Logo"
        bgcolor={"#fff"}
      ></Box>
      {/* </Toolbar> */}
      <Divider />
      <List>
        {routesUrl.map((obj, index) => (
          <ListItem
            key={index} 
            sx={{
              zIndex: 99,
              bgcolor: obj.active ? "rgba(255, 255, 255, 0.2)" : "",
            }}
            onClick={() =>
              obj.name === "deconnexion"
                ? deconecter()
                : handleChangeAffichage(obj.name)
            }
          >
            <ListItemButton>
              <ListItemIcon sx={{ color: "#fff" }}>{obj.icon}</ListItemIcon>
              <ListItemText
                primary={obj.name}
                sx={{
                  color: "#fff",
                  fontWeight: obj.active ? "800" : "500",
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  )

  // Ensemble de vue Side Bar et RightAffichage 
  return (
    <Box sx={{ display: "flex" }}>
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
        }}
        aria-label="mailbox folders"
      >
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
            bgcolor: "#ff4d55",
            display: { xs: "block", sm: "none" },
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              
              onClick={handleDrawerToggle}
              sx={{outline:'none', mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon        sx={{outline:'none' }} />
            </IconButton>
            {/* <Typography variant="h6" noWrap component="div">
              Responsive drawer
            </Typography> */}
          </Toolbar>
        </AppBar>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        {/* Responsive Drawerr */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          onClick={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              bgcolor: "#ff4d55",
            },
          }}
        >
          {drawer}
        </Drawer>

        {/* Base Drawer */}
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              zIndex: 0,
              bgcolor: "rgb(249, 25, 79)",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          px: smDown?2:3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          padding:'0px',
     
        }}
      >
        <Box ml={smDown?0:2}  >
          <Box sx={{mt : {xs : "60px", sm:"0"}}}>

          <AffichageDash affichage={affichage} setAffichage={setAffichage} />
          </Box>
        </Box>
      </Box>
      <Dialog open={modal} onClose={handleCloseDel}>
        <DialogTitle>Deconnection</DialogTitle>
        <DialogContent>
          <Typography >
            Voulez-vous vraiment vous déconnecter ?
          </Typography>
       <div style={{display:'flex',alignItems:'center',justifyContent:'space-around',marginTop:'5px'}}>
       <Button
              variant="contained"
              className="buton"
              sx={{ background: "#F9194F" }}
              onClick={handledeconnection}
            >
              Oui
            </Button>
            <Button
              onClick={handleCloseDel}
              variant="contained"
              className="buton"
              sx={{ background: "#F9194F"}}
            >
              Non
            </Button>
       </div>
           


        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default ResponsiveDrawer;
