import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Blog from "./component/outil//Blog";
import Myprofil from "./component/outil/Myprofil";
import TempleteFiltre from "./component/page_filtre/TempleteFiltre";
import Contact from "./component/contact/Contact";
import Tarif from "./component/Compo/Tarif/Tarif";
import Login from "./component/Compo/Login/Login";
import ShopDetail from "./component/ShopDetail/ShopDetail";
import Accueil from "./component/accueilA/Accueil";
import RouteProteger from "./authentification/RouteProteger";
import Dashbord from "./component/Dashbord/Dashbord";
import Cardi from "./component/card/Cardi";
import Calle from "./component/Calle";
import Gerer from "./component/Dashbord/gererSalon/Gerer";
import { useState } from "react";
import TableRdv from "./component/Dashbord/TableRdv/TableRdv";
import Calendrier from "./component/Dashbord/Calendrier/Calendrier";
import Inscription4 from "./component/Compo/inscription/Inscription4";
import Evenement from "./component/event/event";
import SingleEvent from "./component/singleEvent/singleEvent";
import Success from "./component/Dashbord/abonnement/Success";
import Failed from "./component/Dashbord/abonnement/Failed";
import Categoriefiltre from "./component/page_filtre/Categoriefiltre";
import NewDashboard from "./component/Dashbord/newDash";
import SuccessEvent from "./component/event/statusPayement/success";
import FailedEvent from "./component/event/statusPayement/failed";
import Employer from "./component/Dashbord/employer/employer";
import ServiceCat from "./component/card/ServiceCat";
import ResetMot from './component/outil/ResetMot'
import Metier from "./component/outil/Metier";
import Ressource from "./component/outil/Ressource";
import Accueillogin from './component/accueilA/Accueillogin'
import Transaction from "./component/Dashbord/Transaction/Transaction";
import CongeSal from "./component/Dashbord/conge/CongeSal";
// import ServiceCat from "./component/card/ServiceCat";
// import ResetMot from './component/outil/ResetMot'
// import Metier from "./component/outil/Metier";
// import Ressource from "./component/outil/Ressource";
export default function App() {
  useEffect(() => {
    if (localStorage.getItem('token')) {
      sessionStorage.setItem('token', localStorage.getItem('token'));   // Stocker le token

      sessionStorage.setItem('userId', localStorage.getItem('userId'));

      sessionStorage.setItem('Role', localStorage.getItem('Role'));  // Stocker rol
    }

  }, [])
  const [salonId, setload] = useState();
  function fsalonId(val) {
    setload(val);
  }

  const [categoId, setcatego] = useState();
  function fcategoId(val) {
    setcatego(val);
  }
  // simple commit
  // simple commit
  const isAuthenticated = localStorage.getItem("Role");
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route>

            <Route path="/blog" element={<Blog />}></Route>
            <Route path="/log" element={<Accueillogin />}></Route>
            <Route path="/ressource" element={<Ressource />}></Route>
            <Route path="/metier" element={<Metier />}></Route>
            <Route path="/profil" element={<Myprofil />}></Route>
            <Route path="/filtre/:name" element={<TempleteFiltre />}></Route>
            <Route
              path="/filtre/:name/:country"
              element={<TempleteFiltre />}
            ></Route>
            <Route
              path="/filtre/:name/:date"
              element={<TempleteFiltre />}
            ></Route>
            <Route
              path="/filtre/:date/:country"
              element={<TempleteFiltre />}
            ></Route>
            <Route
              path="/filtre/:categories"
              element={<TempleteFiltre />}
            ></Route>
            <Route
              path="/filtr/:id/:name"
              element={<Categoriefiltre />}
            ></Route>
            <Route path="/filtre/:date" element={<TempleteFiltre />}></Route>
            <Route path="/filtre/:country" element={<TempleteFiltre />}></Route>
            <Route
              path="/filtre/:name/:date/:country"
              element={<TempleteFiltre />}
            ></Route>
            <Route path="/filtre" element={<TempleteFiltre />}></Route>
            <Route path="/contact" element={<Contact />}></Route>
            {/* <Route path='/' element={<Accueil fcategoId={fcategoId} fsalonId={fsalonId} />} ></Route> */}
            <Route path="/tarif" element={<Tarif />}></Route>
            {/* <Route path='/login' element={<Login />} ></Route> */}
            <Route
              path="/detail"
              element={<ShopDetail salonId={salonId} />}
            ></Route>
            <Route path="/dashbardPro" element={<NewDashboard />}></Route>
            <Route path="/newDashboardPro" element={<NewDashboard />}></Route>
            <Route path="/" element={<Accueil val={true} />}></Route>
            <Route path="/det" element={<Cardi categoId={categoId} />}></Route>
            <Route
              path="/loginn"
              element={
                isAuthenticated == "admin" || isAuthenticated == "masseur" ? (
                  /*<Navigate to="/dashboardPro" />*/ <Tarif />
                ) : (
                  <Accueil fcategoId={fcategoId} fsalonId={fsalonId} />
                )
              }
            ></Route>
            <Route path="/tarif" element={<Tarif />}></Route>
            <Route path="/transaction" element={<Transaction />}></Route>
            <Route path="/dashboardPro/salon/sitting" element={<Gerer />}></Route>
            <Route path="/success" element={<Success />}></Route> 
            <Route path="/failed" element={<Failed />}></Route>
            <Route path="/login" element={<Login />}></Route>
            <Route path="/calendr" element={<Calle />}></Route>
            <Route path="/dashboardPro" element={<NewDashboard />}></Route>
            <Route path="/tableRdv" element={<TableRdv />}></Route>
            <Route path="/cali" element={<Calendrier />}></Route>
            <Route path="/inscription4" element={<Inscription4 />}></Route>
            <Route path="/evenement" element={<Evenement />}></Route>
            <Route path="/evenement/:id" element={<SingleEvent />}></Route>
            <Route path="/evenement/status_payement/success" element={<SuccessEvent />}></Route>
            <Route path="/evenement/status_payement/failed" element={<FailedEvent />}></Route>
            <Route path="/reset-password" element={<ResetMot />}></Route>
            {/* <Route path='/det' element={<Cardi/>} ></Route>  */}
            <Route path="/details/:id" element={<ServiceCat />} />{" "}
            {/*detait categorie*/}
            <Route path="/detailsalon/:id" element={<ShopDetail />} />{" "}
            {/*detait salon*/}
            {/* Route protégée */}
            <Route path="/dashboard" element={<RouteProteger />} />
            <Route path="/employer" element={<Employer />} />
            <Route path="/congeSalon" element={<CongeSal />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}
