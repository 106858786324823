import {
  Box,
  Button,
  IconButton,
  Input,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { AddCircle } from "@mui/icons-material";
import { useEffect, useState } from "react";
import ModalPaye from "./modalPayement";
import { useParams, useSearchParams } from "react-router-dom";

import { LoadingButton } from "@mui/lab";
import { typographyTheme } from "../../event/utils/responsiveTypo";
import DialogPaye from "./dialogPayeEvent";
import { ReserverPlace } from "../api/singleEvent";

export default function InputPlace({ nbPlace, price, date, heure }) {
  const [open, setOpen] = useState(false);
  const [compteur, setCompteur] = useState(1);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [paymentId, setPayementId] = useState(null);
  const [isPastEvent, setPasteEvent] = useState(true);
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const { id } = useParams();
  const increment = () => {
    setCompteur((prevCompteur) => prevCompteur + 1);
  };
  const payment = () => {
    setLoadingBtn(true);
    ReserverPlace(id, compteur)
      .then((res) => {
        console.log("The Url", res.data["appointment"].id);
        setOpen(true);
        setPayementId(res.data["appointment"].id);
      })
      .catch((err) => {
        setLoadingBtn(false);
        console.log("Error paiementUrl", err);
      });
  };
  const decrement = () => {
    setCompteur((prevCompteur) => (prevCompteur > 1 ? prevCompteur - 1 : 1));
  };
  useEffect(() => {
    const nowDate = new Date();
    if (date && heure) {
      const futureDate = new Date(date);
      futureDate.setHours(heure.split(":")[0]);
      futureDate.setMinutes(heure.split(":")[1]);
      futureDate.setSeconds(heure.split(":")[2]);
      const diff = futureDate - nowDate;
      console.log("Now", futureDate - nowDate);
      if (diff > 0) {
        setPasteEvent(false);
      }
    }
  }, [date, heure]);
  return (
    <Stack gap={2} mt={5}>
      {!isPastEvent && date && heure ? (
        <>
          <Stack
            direction={"row"}
            sx={{
              width: "fit-content",
              border: "1px solid rgba(0, 0, 0, 0.35)",
              alignItems: "center",
              borderRadius: "5px",
              justifyContent: "space-between",
              minWidth: smDown ? "150px" : "200px",
            }}
          >
            <Typography
              sx={{
                ...typographyTheme.h5,
                // width: "50%",
                color: "gray",
                ml: "10px",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              {price * compteur} €
            </Typography>
            <Box
              sx={{
                width: "50%",
                bgcolor: "#F4F2FF",
                height: "48px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                borderRadius: "5px",
                gap: smDown ? "" : "5px",
              }}
            >
              <IconButton>
                <RemoveCircleIcon
                  onClick={decrement}
                  fontSize={smDown ? "small" : "medium"}
                  sx={{ color: compteur === 1 ? "#AAA" : "#000" }}
                  cursor="pointer"
                />
              </IconButton>
              <Input sx={{ display: "none" }} />
              <Typography> {compteur}</Typography>
              <IconButton cursor="pointer" disabled={compteur == nbPlace}>
                <AddCircle
                  fontSize={smDown ? "small" : "medium"}
                  onClick={increment}
                  sx={{ color: compteur == nbPlace ? "#AAA" : "#000" }}
                />
              </IconButton>
            </Box>
          </Stack>
          <LoadingButton
            variant="contained"
            loading={loadingBtn}
            sx={{
              bgcolor: "#ff4dd8",
              "&:hover": { bgcolor: "#000" },
              width: "fit-content",
            }}
            fullWidth={false}
            onClick={payment}
          >
            Reservez
          </LoadingButton>
        </>
      ) : (
        ""
      )}

      <DialogPaye
        open={open}
        setOpen={setOpen}
        place={compteur}
        price={price}
        id={paymentId}
        StopLoading={() => setLoadingBtn(false)}
      />

      {/* <ModalPaye
        setOpen={setOpen}
        unitPrice={price}
        places={compteur}
        open={open}
      /> */}
    </Stack>
  );
}
