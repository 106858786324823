import React, { useEffec, useEffect, useState } from "react";
import "./Dashb.css";
import {
  ListCountClient,
  ListCountClientYear,
  ListCountMasseur,
  ListCountSallon,
} from "./api/count";
import TickPlacementBars from "./component/barChart";
import SimpleCharts from "./component/barChart";
import DemoChart from "./component/barChart";
import { PieChart } from "@mui/icons-material";
import PieDemoChart from "./component/PieChart";
import { Box, Divider, Icon, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import CardNumber from "./component/profil/cardStat";
import Person4Icon from "@mui/icons-material/Person4";
const NewProfil = () => {
  const [clientCount, setClient] = useState(0);
  const [masseurCount, setMasseur] = useState(0);
  const [salonCount, setSallon] = useState(0);
  const [statYear, setStateYear] = useState([])
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  // const [clientCount, setClient] = useState(0);
  useEffect(() => {
    ListCountClient().then((res) => {
      console.log("List Cli", res.data["clients"]);
      setClient(res.data["clients"]);
    });
    ListCountMasseur().then((res) => {
      console.log("Sallons", res.data);
      setMasseur(res.data["masseurs"]);
    });
    ListCountSallon().then((res) => {
      console.log("Sallons", res.data);
      setSallon(res.data["salons"]);
    });
    ListCountClientYear().then((res) => {
      console.log("Yar", res.data);
      setStateYear(res.data)
    });
  }, []);

  return (
    <>
      {/* <div className="main-content-wrap d-flex flex-column" id="main"> */}
        <Box py={smDown?2:5} px={smDown?1:2} >
          <Typography
            component={"h1"}
            fontSize={"24px"}
            mb={2}
            fontWeight={500}
          >
            Statistique
          </Typography>
          <Divider></Divider>
          <Stack direction={"row"} justifyContent={smDown?"center":""} columnGap={smDown?5:5} rowGap={3} mt={smDown?2:5} flexWrap={"wrap"} >
            <CardNumber name={"clients"} number={clientCount || 0}>
              <i
                className="i-Add-User"
                style={{ color: "#F9194F", fontSize: smDown?"30px":"60px" }}
              ></i>
            </CardNumber>
            <CardNumber name={"Masseurs"} number={masseurCount || 0}>
              {/* <i className="i-Add-User" style={{ color: "#F9194F", fontSize:'60px' }}></i> */}
              <i
                className="i-Add-User"
                style={{ color: "#F9194F", fontSize: smDown?"30px":"60px" }}
              ></i>
            </CardNumber>
            <CardNumber name={"Salons"} number={salonCount || 0}>
              <i
                className="i-Checkout-Basket"
                style={{ color: "#F9194F", fontSize: smDown?"30px":"60px" }}
              ></i>
            </CardNumber>
            <CardNumber name={"Sales"} number={clientCount || 0}>
              <i
                className="i-Financial"
                style={{ color: "#F9194F", fontSize: smDown?"30px":"60px" }}
              ></i>
            </CardNumber>
          </Stack>
          {/* Statistique */}
          <Stack
            direction={"row"}
            justifyContent={smDown?"":"space-between"}
            flexWrap={"wrap"}
            gap={smDown?1:2}
          >
            <Box
              mt={5}
              boxShadow="0 0 10px rgba(0, 0, 0, 0.2)"
              bgcolor={"white"}
              p={3}
              width={"fit-content"}
              borderRadius={2}
            >
              <DemoChart monthly={statYear}></DemoChart>
            </Box>
            <Box
              mt={5}
              boxShadow="0 0 10px rgba(0, 0, 0, 0.2)"
              bgcolor={"white"}
              height={"fit-content"}
              p={5}
              // width={"fit-content"}
              borderRadius={2}
            >
              <Typography>Ventes</Typography>
              <PieDemoChart />
            </Box>
          </Stack>
        </Box>
      {/* </div> */}
    </>
  );
};
export default NewProfil;
