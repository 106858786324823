import { React, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  Button,
} from "@mui/material";
import "../HeadTarif/Test.css";
import axios from "axios";
import { major } from "@mui/material";
import Login from "../../Login/Login";
const Test = () => {
  const [animate, setAnimate] = useState(1);
  const handleButtonClick = (buttonId) => {
    setAnimate(buttonId);
  };
  const [data, setData] = useState([]);
  const [vall, setVall] = useState(false);
  const [des, setDes] = useState(false);
  const [modal, setModal] = useState(false);
  const [idd, setIdd] = useState("");
  const [datass, setDatas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [idTarif, setIdTarif] = useState("");
  const login = document.querySelector(".login");
  function showLogin() {
    // Setlogin(true)
    if (login) {
      login.style.display = "flex";
    }
  }
  const handelClose = () => {
    setModal(false);
  };
  const handelModal = (id) => {
    setModal(true);
    // console.log('lolo:',id)
    setIdd(id);
  };
  const handelCountAnnuel = async (e) => {
    // e.preventDefault();
    try {
      setVall(true);
    } catch {
      console.log("non");
    }
  };
  const handelCountMen = async () => {
    // e.preventDefault();
    try {
      setVall(false);
    } catch {
      console.log("non");
    }
  };
  const fetchText = async (e) => {
    try {
      const response = await axios.get(`/tarifs`);
      setData(response.data.tarif);
      console.log("tarif:", response.data.tarif);
    } catch {
      console.log("erreur");
    }
  };

  const fetchTextos = async () => {
    if (!idd) {
      console.log("ID is not defined");
      return;
    }

    try {
      const response = await axios.get(`/edit/tarif/${idd}`);
      setDatas(response.data.tarif); // Assuming `response.data.tarif` is an object
      console.log("Tarif data:", response.data.tarif);
    } catch (error) {
      console.error("Error fetching tarif data:", error);
    }
  };

  const trues = () => {
    setDes(true);
  };
  useEffect(() => {
    trues();
    // fetchTextos();
    fetchText();
  }, []);
  useEffect(() => {
    if (idd) {
      fetchTextos();
    }
  }, [idd]);
  const handelLogin = async () => {
    setModal(false);
    showLogin();
  };
  return (
    <>
      <Box
        className="Containe"
        sx={{ marginTop: { xs: "-150px", sm: "-100px", md: "0px" } }}
      >
        {/* box a droit */}
        <Box className="box-1">
          <Box
            className="box-1-text-1"
            sx={{
              fontSize: {
                xs: "10px",
                sm: "20",
                md: "40px",
              },
            }}
          >
            <p>
              UN PRIX UNIQUE, UNE <br /> SOLUTION COMPLETE
            </p>
          </Box>
          <Box className="box-1-design-1">
            <Box className="box-1-design-1-1"></Box>
            <Box className="box-1-design-1-2"></Box>
          </Box>
          <Box
            className="box-1-text-2"
            sx={{
              fontSize: {
                xs: "10px",
                sm: "20",
                md: "20px",
              },
              width: { xs: "100px", sm: "200px", md: "637px" },
            }}
          >
            <p>
              Optez pour la simplicité et gagnez du temps pour ce qui compte
              vraiment : votre passion.
            </p>
          </Box>
          <Box
            component="a" // Ajoutez cette ligne pour que le Box se comporte comme un lien
            href="#offre"
            className="box-1-button-1"
            sx={{
              width: { xs: "355px", sm: "150px", md: "190px" },
              fontSize: { xs: "10px", sm: "15px", md: "18px" },
              height: { xs: "40px", sm: "50px", md: "56px" },
              marginTop: { xs: "150px", sm: "40px", md: "20px" },
              color: "white", // applique la couleur de texte directement sur le Box
              textDecoration: "none", // retire la décoration du texte
              display: "flex", // centre le texte horizontalement
              alignItems: "center", // centre le texte verticalement
              justifyContent: "center", // centre le texte horizontalement
            }}
          >
            <Typography sx={{marginTop:'-10px'}}>Voir nos offres</Typography>
          </Box>
        </Box>
        {/* box a gauche */}
        <Box className="box-2"></Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: { xs: "1190px", sm: "1150px", md: "800px" },
          gap: "32px",
          background: "#EEF0F2",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        id="offre"
      >
        <Box
          className="text-offre"
          sx={{
            height: "54px",
            gap: "10px",
            marginTop: { xs: "40px", md: "90px" },
            fontSize: { xs: "28px", md: "40px" },
            lineHeight: "54px",
            letterSpacing: "2%",
            fontWeight: "500",
            display: "flex",
            justifyContent: "center",
          }}
        >
          NOS OFFRES
        </Box>

        <Box
          sx={{
            width: { xs: "200px", md: "276px" },
            height: "67px",
            gap: "10px",
            background: "white",
            borderRadius: "99999999px",
            padding: "8px",
            marginInline: "auto",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {/* Bouton Mensuel */}
          <Box
            onClick={() => {
              handleButtonClick(1);
              handelCountMen();
            }}
            sx={{
              width: "119px",
              height: "51px",
              borderRadius: "999999999px",
              background: animate === 1 ? "#F9194F" : "white",
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            <Box sx={{ marginTop: "16px", textAlign: "center" }}>
              <p
                style={{
                  color: animate === 1 ? "white" : "black",
                  fontSize: "16px",
                  lineHeight: "19.36px",
                  letterSpacing: "2%",
                }}
              >
                Mensuel
              </p>
            </Box>
          </Box>

          {/* Bouton Annuel */}
          <Box
            onClick={() => {
              handleButtonClick(2);
              handelCountAnnuel();
            }}
            sx={{
              width: "119px",
              height: "51px",
              borderRadius: "999999999px",
              background: animate === 2 ? "#F9194F" : "white",
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            <Box sx={{ marginTop: "16px", textAlign: "center" }}>
              <p
                style={{
                  color: animate === 2 ? "white" : "black",
                  fontSize: "16px",
                  lineHeight: "19.36px",
                  letterSpacing: "2%",
                }}
              >
                Annuel
              </p>
            </Box>
          </Box>
        </Box>

        {/* Offres */}
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "center",
            gap: "24px",
            width: "100%",
            height: "auto",
            flexWrap: "wrap",
          }}
        >
          {data.map((item) => (
            <Box
              sx={{
                width: { xs: "90%", md: "364px" },
                background: "white",
                marginTop: "8px",
                padding: "16px",
                marginLeft: { xs: "20px", sm: "0px", md: "0px" },
              }}
              key={item.id}
            >
              <Box
                sx={{
                  fontWeight: "500",
                  fontSize: { xs: "20px", md: "24px" },
                  lineHeight: "29.05px",
                }}
              >
                {item.type}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  gap: "4px",
                  marginTop: "24px",
                }}
              >
                <Box
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                >
                  €
                </Box>
                <Box
                  sx={{
                    fontSize: { xs: "28px", md: "36px" },
                    fontWeight: "500",
                  }}
                >
                  {vall ? item.price * 12 : item.price}
                </Box>
              </Box>

              {des && (
                <Box
                  sx={{
                    marginTop: "10px",
                    fontSize: { xs: "14px", md: "16px" },
                    fontWeight: "400",
                  }}
                >
                  {item.description.split(" ").slice(0, 10).join(" ")}...
                </Box>
              )}

              <Box
                sx={{
                  background: "#F9194F",
                  borderRadius: "5px",
                  marginTop: "24px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  padding: "10px 20px",
                }}
                onClick={() => handelModal(item.id)}
              >
                <Box sx={{ color: "white" }}>Découvrir l'offre</Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                  marginTop: "24px",
                }}
              >
                {/* Agenda et rendez-vous */}
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    className="koche1"
                    sx={{ width: "14px", height: "14px" }}
                  ></Box>
                  <Box sx={{ marginLeft: "8px" }}>Agenda et rendez-vous</Box>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    className="koche2"
                    sx={{ width: "14px", height: "14px" }}
                  ></Box>
                  <Box sx={{ marginLeft: "8px" }}>SMS / Email de rappel</Box>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box className="koche3"></Box>
                  <Box sx={{ marginLeft: "8px" }}>
                    Gestion de votre établissement
                  </Box>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box className="koche4-x"></Box>
                  <Box
                    sx={{
                      fontSize: "16px",
                      lineHeight: "24px",
                      textDecoration: "line-through",
                      color: "#4A4A4A",
                    }}
                  >
                    Outils Marketing
                  </Box>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box className="koche5-x"></Box>
                  <Box
                    sx={{
                      fontSize: "16px",
                      lineHeight: "24px",
                      textDecoration: "line-through",
                      color: "#4A4A4A",
                    }}
                  >
                    Logiciel de caisse
                  </Box>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box className="koche6-x"></Box>
                  <Box
                    sx={{
                      fontSize: "16px",
                      lineHeight: "24px",
                      textDecoration: "line-through",
                      color: "#4A4A4A",
                    }}
                  >
                    Indicateurs de performances avancés
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>

      <Dialog open={modal} onClose={handelClose} fullWidth maxWidth="sm">
        <DialogTitle>
          <Typography variant="h6">Détail du tarif</Typography>
        </DialogTitle>
        <DialogContent dividers>
          {datass ? ( // Assuming `datass` is now an object, not an array
            <>
              <Box>
                <Typography variant="body1">
                  <strong>Type:</strong> {datass.type}
                </Typography>
                <Typography variant="body1">
                  <strong>Prix:</strong>{" "}
                  {vall === true ? datass.price * 12 : datass.price} €
                </Typography>

                <Typography variant="body1">
                  <strong>Description:</strong> {datass.description}
                </Typography>
              </Box>

              <Typography sx={{ marginTop: "20px" }}>
                {" "}
                Connectez-vous avec Macerise pour un abonnement.{" "}
                <a
                  onClick={handelLogin}
                  style={{ color: "blue", cursor: "pointer" }}
                >
                  Cliquez ici.
                </a>
              </Typography>
            </>
          ) : (
            <Typography>Aucun tarif trouvé.</Typography>
          )}
        </DialogContent>
      </Dialog>
      <Box
        className="login"
        sx={{ marginTop: { xs: "100px", sm: "100px", md: "100px" } }}
      >
        <Login dat={1} />
      </Box>
    </>
  );
};
export default Test;
