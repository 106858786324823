import {
  Typography,
  Stack,
  Box,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import NavMain from "../Navigation/NavMain";
import NavPro from "../Navigation/NavPro";
import { daytoString, MonthToString } from "./utils/converDate";
import CardEvent from "./component/cardEvent";
import Slider from "react-slick";
import SliderEvent from "./component/sliderEvent";
import { useEffect, useState } from "react";
import {
  futureListEvent,
  ListEvent,
  pastListEvent,
} from "./utils/EventListapi";
import Footer from "../outil/Footer";
import { typographyTheme } from "./utils/responsiveTypo";
import SkeletonCardEvent from "./component/skeletonCardEvent";

export default function Evenement() {
  const [dataEvent, setDataEvent] = useState([]);
  const [dataPastEvent, setDataPastEvent] = useState([]);
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    futureListEvent()
      .then((res) => {
        setDataEvent(res.data["evenment"]);
      })
      .catch((error) => {
        console.log("Erreur Get List Event", error);
      });
    pastListEvent()
      .then((res) => {
        setDataPastEvent(res.data["evenment"]);
      })
      .catch((error) => {
        console.log("Erreur Get List Event", error);
      });
  }, []);
  // useEffect(() => {
  //   // console.log("in Ev", dataEvent);
  // }, [dataEvent]);
  return (
    <>
      <NavMain />
      {/* <Stack direction={"row"}> */}
      <SliderEvent dataEvent={dataEvent} />
      {/* </Stack> */}
      <Stack
        p={smDown ? "40px 10px" : "40px 40px"}
        direction={"column"}
        gap={2}
        // border={"1px solid black"}
      >
        <Typography
          fontWeight={600}
          sx={typographyTheme.h4}
          width={"fit-content"}
        >
          Événements Actuels
        </Typography>
        <Stack
          direction={"row"}
          flexWrap={"wrap"}
          mt={smDown ? 1 : 2}
          gap={smDown ? 2 : 5}
          justifyContent={"space-between"}
        >
          {dataEvent && dataEvent.length > 0
            ? dataEvent.map((event, i) => {
                return <CardEvent event={event} key={i} />;
              })
            : Array(4)
                .fill(0)
                .map((_, i) => {
                  return <SkeletonCardEvent key={i} />;
                })}
        </Stack>
      </Stack>
      
      <Stack
        p={smDown ? "40px 10px" : "40px 40px"}
        direction={"column"}
        gap={2}
        // border={"1px solid black"}
      >
        <Typography
          fontWeight={600}
          sx={typographyTheme.h4}
          width={"fit-content"}
        >
          Événements Passés
        </Typography>
        <Stack
          direction={"row"}
          flexWrap={"wrap"}
          mt={smDown ? 1 : 2}
          gap={smDown ? 2 : 5}
          justifyContent={"space-between"}
        >
          {dataPastEvent && dataPastEvent.length > 0
            ? dataPastEvent.map((event, i) => {
                return <CardEvent isPast={true} event={event} key={i} />;
              })
            : Array(4)
                .fill(0)
                .map((_, i) => {
                  return <SkeletonCardEvent key={i} />;
                })}
        </Stack>
      </Stack>
      <Footer />
    </>
  );
}
