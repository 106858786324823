import React from 'react'
import axios from 'axios';
import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import './Favoris.css'
export default function Favoris() {
  const [events, setEvents] = useState([]);
  let [fav, setfav] = useState([])
  const fetchquestion = async () => {
    let token = sessionStorage.getItem('token')
    try {
      const response = await axios.get("https://www.api-macerise.masovia-madagascar.com/api/favoris_client"
        ,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        }
      );
      setfav(response.data.favorites_client);
     console.log(response.data)
    } catch (err) {
      console.log(err)
    }

  };


  useEffect(() => {
    fetchquestion();
  }, []);
  return (
    <div>
      <div className='ppp'>Listes Favoris</div>
      <div className='EtablissementImgx'>
        {

          fav ?
            fav.map((salon, key) => {
              return <div className='cardd' key={salon.id}>
                <img src={`https://api-macerise.masovia-madagascar.com/storage/salon_images/${salon.images}`} alt='' ></img>

                <div className='description'>
                  <p className='titre'>{salon.name}</p>
                  <p className='avis'><i className='ti-star'></i><i className='ti-star'></i><i className='ti-star'></i><i className='ti-star'></i><i className='ti-star'></i> {salon.notes_count} avis</p>
                  <p className='payse'>{salon.city}</p>
                  <p className='expert'>Chez votre expert</p>
                  <hr></hr>

                  <NavLink
                    className='plusdetaille'
                    to={`/detailsalon/${salon.id}`}

                  >


                    <p>

                      Je veux en savoir plus

                    </p>
                    <i className='ti-angle-down'></i>


                  </NavLink>
                </div>

              </div>

            })
            :'0 Favoris'
        }
         
      {
        fav.length <=0 ?< div className='loading'></div>:''
      }
   
      </div>
    
    </div>

  )
}
