import React from 'react'
import "./Menu.css"
import { useEffect } from 'react'
import { NavLink } from 'react-router-dom'

export default function MenuConnecter({ onClick }) {

  return (
    <>
      <div className="menu connecter">
        <ul >
          <li >

            <NavLink

              to="/metier"
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "active" : ""
              }
            >
              Votre métiers
            </NavLink>
          </li>
          <li>
            <NavLink

              to="/blog"
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "active" : ""
              }
            >Solutions et Fonctionnalités
            </NavLink>
          </li>
          <li >
            <NavLink
              onClick={onClick}
              to="/tarif"
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "active" : ""
              }
            >Tarifs
            </NavLink>
          </li>
          <li>
            <NavLink

              to="/ressource"
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "active" : ""
              }
            >Ressources
            </NavLink>
          </li>
          <li >
            <NavLink
              onClick={onClick}
              to="/contact"
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "active" : ""
              }

            >   Contact
            </NavLink>
          </li>

        </ul>
      </div>
    </>
  )
}
